import { useContext, useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { UserCtx } from "../../context/userContext"
import ForceVerifyEmail from "../../pages/ForceVerifyEmail"

const PrivateRouteEmailVerified = () => {
  const { user } = useContext(UserCtx)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (user.emailVerified) {
      const redirectPath = localStorage.getItem("redirectAfterVerification")
      if (redirectPath) {
        localStorage.removeItem("redirectAfterVerification")
        navigate(redirectPath)
      }
    } else {
      localStorage.setItem("redirectAfterVerification", location.pathname)
    }
  }, [user.emailVerified, location, navigate])

  if (!user.emailVerified) {
    return <ForceVerifyEmail />
  }

  return <Outlet />
}

export default PrivateRouteEmailVerified
