import React from "react";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { UserCtx } from "../../context/userContext";
import { ClientPaymentRequests } from "../../api/app.service";
import TakeMeToStripeModal from "./TakeMeToStripeModal";
import { AnimatePresence } from "framer-motion";
import useLoading from "../../hooks/useLoading";
import { Button } from "../ui/button";
import {
  SvgApplication,
  SvgBank2,
  SvgBusiness,
  SvgCheckCircle,
  SvgPoweredByStripe,
  SvgVerifyPhone,
} from "../icons";

type Props = {
  connectAccountData: any;
};

const syncAccountSteps: {
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  title: string;
  content: string;
}[] = [
  {
    icon: SvgVerifyPhone,
    title: "Verify your mobile number",
    content: "Stripe will text this number to verify your account.",
  },
  {
    icon: SvgBusiness,
    title: "Explain your business",
    content:
      "Stripe needs to know your country and type of business you operate.",
  },
  {
    icon: SvgApplication,
    title: "Enter your website link",
    content:
      "Stripe collects this information to verify your identity and keep your account safe.",
  },
  {
    icon: SvgBank2,
    title: "Select an account for payouts",
    content:
      "Earnings that you receive on Stripe will be sent to this account.",
  },
  {
    icon: SvgCheckCircle,
    title: "Review your details & submit",
    content: "Review information and submit.",
  },
];

export default function SyncBankInstructions({ connectAccountData }: Props) {
  const { user } = React.useContext(UserCtx);
  const { renderError } = React.useContext(CommonFunctionCtx);
  const [showTakeMeToStripeModal, setShowTakeMeToStripeModal] =
    React.useState<boolean>(false);
  const { startLoading, stopLoading } = useLoading();

  const getOnboardingLink = () => {
    if (user) {
      startLoading();
      ClientPaymentRequests.getStripeConnectOnboardingLink({
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          const link = data.link;
          window.location.href = link;
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  return (
    <>
      {connectAccountData?.setup_status === "incomplete" && (
        <div className="box-shadow-1 flex min-h-[234px] flex-col justify-between rounded-xl bg-white px-[16px] py-[32px] md:p-[40px]">
          <div className="mb-[40px] flex w-full flex-col justify-between md:flex-row md:items-center">
            <div className="mb-[18px] flex flex-col gap-[8px] md:mb-0">
              <h6 className="text-2xl font-bold">
                Sync your bank account to get paid
              </h6>
              <p className="text-graySlate">
                <span>
                  Learn more about how{" "}
                  <a
                    href="https://intercom.help/zoee/en/articles/8623117-how-does-zoee-protect-your-information-on-stripe"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primaryBlue font-bold"
                  >
                    Zoee protects your payment information
                  </a>
                </span>{" "}
                and{" "}
                <span>
                  <a
                    href="https://intercom.help/zoee/en/articles/8187172-how-do-i-get-paid-in-zoee#h_112090f95a"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primaryBlue font-bold"
                  >
                    fees
                  </a>
                </span>
                .
              </p>
            </div>
            <div className="flex gap-[24px]">
              {connectAccountData.identity_status !== "verified" && (
                <p className="text-grayCharcoal max-w-xs text-base">
                  You must complete identity verification before connecting your
                  bank account.
                </p>
              )}
              <Button
                onClick={() => setShowTakeMeToStripeModal(true)}
                disabled={connectAccountData.identity_status !== "verified"}
              >
                Get Started
              </Button>
            </div>
          </div>
          <div className="bg-grayCloud flex w-full flex-col gap-[24px] rounded-[16px] px-[8px] py-[24px] md:gap-[32px] md:px-[29px] md:py-[40px]">
            <div className="md:flew-row flex w-full flex-col items-center justify-between">
              <div className="mb-[12px] flex flex-col gap-[8px] md:mb-0">
                <h2 className="flex items-center text-[22px] font-bold text-black md:text-[32px]">
                  Only &nbsp;
                  <span className="flex h-[32px] w-[32px] items-center justify-center rounded-full bg-white text-[22px] text-black">
                    5
                  </span>
                  &nbsp; steps to connect
                </h2>
              </div>
              <SvgPoweredByStripe />
            </div>
            <div className="flex flex-col items-center justify-between gap-[8px] md:gap-[4px] lg:flex-row">
              {syncAccountSteps.map((step, idx: number) => (
                <>
                  <div
                    key={idx}
                    className="card-hover-shadow flex w-full items-center gap-[16px] rounded-xl bg-white px-[32px] py-[20px] lg:h-[244px] lg:w-[181px] lg:flex-col lg:gap-[12px] lg:px-[14px]"
                  >
                    <span className="text-graySlate text-base font-bold">
                      {idx + 1}
                    </span>
                    <div className="flex flex-col items-center">
                      <step.icon className="h-8 w-8" />
                    </div>
                    <div className="flex flex-col gap-1 lg:items-center">
                      <h3 className="text-balance font-bold lg:text-center">
                        {step.title}
                      </h3>
                      <p className="text-graySlate text-base lg:text-center">
                        {step.content}
                      </p>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      )}

      <AnimatePresence>
        {showTakeMeToStripeModal && (
          <TakeMeToStripeModal
            setShowTakeMeToStripeModal={setShowTakeMeToStripeModal}
            onClickCallback={getOnboardingLink}
          />
        )}
      </AnimatePresence>
    </>
  );
};
