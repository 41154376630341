import React from "react"
import ConfirmReferralInvite from "../../components/referrals/ConfirmReferralInvite"
import ContactInvite from "../../components/contacts/ContactInvite"
import Loader from "../../components/ui/loader"
import PersonalizeYourPagePreview from "../../components/referrals/PersonalizeYourPagePreview"
import successKit from "../../assets/images/affiliate-program-success-kit.webp"
import useLoading from "../../hooks/useLoading"
import useScrollToTop from "../../hooks/useScrollToTop"
import { AnimatePresence } from "framer-motion"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ClientPaymentRequests } from "../../api/app.service"
import { Button } from "../../components/ui/button"
import { NotificationCtx } from "../../context/notificationContext"
import { ReferralRequests } from "../../api/app.service"
import { Switch } from "../../components/ui/switch"
import { Label } from "../../components/ui/label"
import Papa from "papaparse"
import {
  SvgCopy,
  SvgEdit,
  SvgFacebook,
  SvgLinkedin,
  SvgPlus,
  SvgTwitter,
  SvgWarning,
} from "../../components/icons/"
import { UserCtx } from "../../context/userContext"

interface Props {}

const ReferACoach: React.FC<Props> = () => {
  const { user } = React.useContext(UserCtx)
  const {
    renderError,
    getUser,
    renderSuccess,
    setPopupNotification,
  } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [promocode, setPromocode] = React.useState<string>("")
  const [showInviteModal, setShowInviteModal] = React.useState<boolean>(false)
  const [showConfirmInviteModal, setShowConfirmInviteModal] =
    React.useState<boolean>(false)
  const [showPypPreview, setShowPypPreview] = React.useState<boolean>(false)
  const [invites, setInvites] = React.useState<any>([{ email: "" }])
  const [referrals, setReferrals] = React.useState<any[]>([])
  const [isPypPublished, setIsPypPublished] = React.useState<boolean>(false)
  const [personalizeYourPageValues, setPersonalizeYourPageValues] =
    React.useState<any>({
      name: "",
      title: "",
      description: "",
    })
  const [, setHasUnsavedDetails] = React.useState<boolean>(false)
  const [profileImgFile, setProfileImgFile] = React.useState<File>()
  const [awaitNewImage, setAwaitNewImage] = React.useState<boolean>(false)
  const [hasPypImage, setHasPypImage] = React.useState<boolean>(false)
  const [imageId, setImageId] = React.useState<string | null>(null)
  const [previewImgUrl, setPreviewImgUrl] = React.useState<string>("")
  const [loadingReferrals, setLoadingReferrals] = React.useState<boolean>(false)
  const [loadingPypDetails, setLoadingPypDetails] =
    React.useState<boolean>(false)
  const [connectAccountData, setConnectAccountData] = React.useState<any>(null)
  const { lastWebsocketMessage } = React.useContext(NotificationCtx)

  const [numSubscribedReferrals, setNumSubscribedReferrals] = React.useState(0)
  const [earnings, setEarnings] = React.useState<any[]>([])
  const [payouts, setPayouts] = React.useState<any[]>([])
  const [lifetimeEarnings, setLifetimeEarnings] = React.useState<number>(0)
  const [customPromoCode, setCustomPromoCode] = React.useState<string>("")
  const [isEditing, setIsEditing] = React.useState<boolean>(false)
  const [inputError, setInputError] = React.useState<string>("")

  const calculatePayouts = async () => {
    if (user) {
      ReferralRequests.calculatePayouts({
        sender_user_id: parseInt(user.user_id),
      })
        .then((data) => {
          setEarnings(data.earnings)})
        .then(() => {
          getUser()
        })
        .catch((err) => {
          console.log(err)
          renderError(err.response.data.message)
        })
    }
  }

  const calculatePayoutNumber = () => {

      const today = new Date()
      const currentDateObj = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      )
      console.log(earnings)
      const payouts = earnings.filter((r) => {
        const entryDate = new Date(r.date_processed)        
        // Add 14 days to the future date
        entryDate.setDate(entryDate.getDate() + 14)
        return entryDate > currentDateObj
      })
      setPayouts(payouts)

  }

  React.useEffect(() => {
    calculatePayouts()
  }, [])

  React.useEffect(() => {
    calculatePayoutNumber()
  }, [earnings])

    

  const handleDownload = (referals: any): any => {
    const csvData = Papa.unparse(referals)
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = "data.csv"
    a.click()
    URL.revokeObjectURL(url)
  }

  const createUserPromoCode = () => {
    if (user) {
      ReferralRequests.createUserPromoCode({
        user_id: parseInt(user.user_id),
        first_name: user.firstName,
      })
        .then((data) => {
          setPromocode(data.promo_code)
        })
        .then(() => {
          getUser()
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
    }
  }

  const handleCustomPromoCodeSubmit = () => {
    if (customPromoCode === promocode) {
      setIsEditing(false)
      setInputError("")
      return
    }

    if (customPromoCode.match(/^[a-zA-Z0-9]{3,20}$/)) {
      ReferralRequests.createUserPromoCode({
        user_id: parseInt(user.user_id),
        first_name: user.firstName,
        custom_promo_code: customPromoCode,
      })
        .then((data) => {
          setPromocode(data.promo_code)
          setInputError("")
          renderSuccess("Custom promo code updated successfully.")
        })
        .catch((ex) => {
          if (ex.response && ex.response.status === 400) {
            setInputError("This promo code is already in use.")
            setTimeout(() => setInputError(""), 5000)
          } else {
            renderError(ex.response.data.message)
          }
        })
    } else {
      setInputError("Sorry! Special characters are not allowed.")
      setTimeout(() => setInputError(""), 5000)
    }
  }

  const getReferrals = () => {
    if (user) {
      setLoadingReferrals(true)
      ReferralRequests.getReferrals({
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          setReferrals(data.referrals)
          const referrals = data.referrals
          setNumSubscribedReferrals(
            referrals.filter((r) => r.status === "active").length
          )
          setLifetimeEarnings(referrals.reduce((earnings,acc) =>  earnings + acc.earnings_amount, 0))
          console.log(referrals.reduce((earnings,acc) =>  earnings + acc.earnings_amount, 0))
          setLoadingReferrals(false)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          setLoadingReferrals(false)
        })
    }
  }

  const handlePromoCodeCopyClick = () => {
    navigator.clipboard.writeText(promocode)
    renderSuccess("Success! Referral code copied.")
  }

  const handlePromoCodeUniqueLinkCopyClick = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}/${promocode}`
    )
    renderSuccess("Success! Referral link copied.")
  }

  const downloadSuccessKit = () => {
    const environment = process.env.REACT_APP_USER_ENVIRONMENT
    const url = `https://zoee-downloads-${environment}.s3.amazonaws.com/Zoee+Affiliate+Program+Success+Kit.pdf`
    window.open(url, "_blank")
  }

  const shareToFacebook = () => {
    const promoLink = `http%3A%2F%2Fzoee.com%2F${promocode}`
    const url = `https://www.facebook.com/sharer.php?u=${promoLink}`
    window.open(url, "_blank")
  }

  const shareToTwitter = () => {
    const promoLink = `http%3A%2F%2Fzoee.com%2F${promocode}`
    const url = `https://twitter.com/intent/tweet?url=${promoLink}`
    window.open(url, "_blank")
  }

  const shareToLinkedin = () => {
    const promoLink = `http%3A%2F%2Fzoee.com%2F${promocode}`
    const url = `https://www.linkedin.com/sharing/share-offsite/?url=${promoLink}`
    window.open(url, "_blank")
  }

  React.useEffect(() => {
    if (user) {
      if (!user.promoCode) {
        try {
          createUserPromoCode()
        } catch (error) {
          console.error(error)
        }
      } else {
        setPromocode(user.promoCode)
        getReferrals()
      }
    }
  }, [user])

  const getConnectAccount = () => {
    startLoading()
    if (user) {
      ClientPaymentRequests.getConnectAccount({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          setConnectAccountData(data.connect_account_data)
        })
        .catch((err) => {
          console.error(err)
          renderError(err.response.data.message)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }

  React.useEffect(() => {
    if (
      lastWebsocketMessage &&
      lastWebsocketMessage.type === "stripe_identity"
    ) {
      getConnectAccount()
    }
  }, [lastWebsocketMessage])

  React.useEffect(() => {
    getConnectAccount()
  }, [])

  useScrollToTop()

  const boxes = [
    {
      id: 1,
      value: `${referrals.length}`,
      label: "Total Invites Sent",
    },
    {
      id: 2,
      value: `$${payouts.reduce((earnings,acc) =>  earnings + acc.earnings_amount, 0)}`,
      label: "Upcoming Payout",
    },
    {
      id: 3,
      value: `$${referrals.reduce((earnings,acc) =>  earnings + acc.earnings_amount, 0)}`,
      label: "Lifetime Earnings",
    },
  ]

  const shareOptions = [
    {
      id: 1,
      onClick: shareToTwitter,
      icon: <SvgTwitter />,
      label: "Twitter",
    },
    {
      id: 2,
      onClick: shareToFacebook,
      icon: <SvgFacebook />,
      label: "Facebook",
    },
    {
      id: 3,
      onClick: shareToLinkedin,
      icon: <SvgLinkedin />,
      label: "LinkedIn",
    },
  ]

  return (
    <>
      <div className="md:pt-0 mx-auto flex flex-col items-center w-full gap-[40px] xsm:px-4 sm:px-0">
        <div className="sm:w-[704px] text-black text-4xl font-semibold font-['Beausite Classic']">
          Affiliate dashboard
        </div>
        <div className="sm:w-[704px] w-full bg-white main-shadow rounded-[16px] h-full mb-6 px-[24px] pt-[24px] pb-[32px] md:pb-[42px]">
          <h2 className="md:text-[32px] text-[28px] font-semibold">
            <span className="block sm:inline">💰</span> Give 50% off,
            <span className="text-blurple"> Get $50 per referral</span>
          </h2>
          <div className="mb-[32px]">
            <span className="text-[#3a434a] text-lg font-normal font-['Beausite Classic'] leading-7">
              Give your coach friends 50% off their first year or month on Zoee
              and earn up to $50 for each one who subscribes and stays for a
              month.{" "}
            </span>
            <span className="text-[#3a434a] text-lg font-normal font-['Beausite Classic'] underline leading-7">
              <a href="/affiliate-program-terms" className="underline">
                Terms apply
              </a>
            </span>
          </div>
          {promocode === "" ? (
            <div className="flex flex-col items-center gap-[12px] mt-[160px] mb-[160px]">
              <Loader />
              <h3 className="font-bold text-[16px] mb-[4px] text-center">
                Getting your promo code...
              </h3>
            </div>
          ) : (
            <div className="sm:flex sm:flex-col gap-[30px] mb-[36px] md:mb-[40px] relative">
              <div className="sm:flex sm:flex-col gap-[2px]">
                <h5 className="text-base font-bold">
                  Copy and share your affiliate link
                </h5>
                <div className="relative">
                  <div className="sm:flex items-center w-full">
                    <div className="pl-[20px] pr-[3px] border border-grayCloud rounded-[10px] flex-grow flex items-center h-[53px]">
                      {/* Static part of the URL */}
                      <p className="text-black">zoee.com/</p>
                      {/* Editable part of the promo code */}
                      {isEditing ? (
                        <input
                          type="text"
                          value={customPromoCode}
                          onChange={(e) => setCustomPromoCode(e.target.value)}
                          className="pl-[3px] pr-[3px] border-blurple border-2 flex-grow h-full text-blurple focus:outline-none"
                          autoFocus
                        />
                      ) : (
                        <p className="text-black">{promocode}</p>
                      )}
                    </div>
                    <div className="sm:hidden mt-2"></div>
                    <div className="flex items-center h-[53px]">
                      {/* Button changes between Copy and Save based on edit mode */}
                      {isEditing ? (
                        <>
                          <Button
                            onClick={() => {
                              handleCustomPromoCodeSubmit()
                              setIsEditing(false)
                            }}
                            className="text-white text-base font-bold font-['Beausite Classic'] leading-normal w-[120px] h-full sm:ml-2"
                          >
                            Save
                          </Button>
                          <div className="ml-[10px] w-[24px]"></div>
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={handlePromoCodeUniqueLinkCopyClick}
                            className="text-white text-base font-bold font-['Beausite Classic'] leading-normal w-[120px] h-full sm:ml-2"
                          >
                            Copy
                          </Button>
                          <div className="ml-[10px]">
                            <SvgEdit
                              className="cursor-pointer"
                              onClick={() => {
                                setCustomPromoCode(promocode)
                                setIsEditing(true)
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="sm:hidden mt-6"></div>
                  </div>{" "}
                  {inputError && (
                    <p className="flex items-center text-[red] text-sm sm:mt-1 -mt-6 absolute">
                      <SvgWarning className="w-4 h-4 mr-1" /> {inputError}
                    </p>
                  )}
                </div>
              </div>
              <div className="sm:flex sm:flex-col gap-[2px] w-full">
                <h5 className="text-base font-bold">Invite via email</h5>
                <div className="sm:flex items-center w-full h-[53px]">
                  <div
                    onClick={() => setShowInviteModal(true)}
                    className="pl-[20px] pr-[3px] border border-grayCloud rounded-[10px] flex-grow flex items-center cursor-pointer h-[53px]"
                  >
                    <p className="text-graySlate">
                      Enter coach friend’s email address
                    </p>
                  </div>
                  <div className="sm:hidden mt-2"></div>
                  <div className="flex items-center h-full">
                    <Button
                      onClick={() => setShowInviteModal(true)}
                      className="text-white text-base font-bold font-['Beausite Classic'] leading-normal w-[120px] h-full sm:ml-2"
                    >
                      Send Invite
                    </Button>
                    <div className="ml-[10px] w-[24px]"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="sm:hidden mt-24"></div>
          <div className="sm:flex items-center gap-[28px]">
            <h5 className="text-base font-bold">or share via...</h5>
            <div className="flex items-center gap-8">
              {shareOptions.map((option) => (
                <Button
                  key={option.id}
                  variant="ghost"
                  size="icon"
                  onClick={option.onClick}
                  aria-label={`Share on ${option.label}`}
                  className="bg-white rounded-full p-2 shadow-md"
                >
                  {option.icon}
                </Button>
              ))}
            </div>
          </div>
        </div>
        <div className="sm:w-[704px] w-full main-shadow rounded-[16px] bg-white px-[24px] pt-[24px] pb-[32px] md:pb-[42px] mb-10">
          <div className="sm:w-full">
            <p className="text-black text-[28px] font-semibold font-['Beausite Classic']">
              Payouts
            </p>
            {connectAccountData?.setup_status === "complete" ? (
              <p className="text-black text-[16px] font-['Beausite Classic'] leading-relaxed">
                Payouts will securely be made through Stripe. Your{" "}
                <a
                  href="/coach/client-payments"
                  className="text-[#635bff] underline"
                >
                  Stripe account
                </a>{" "}
                is already connected and will automatically deposit your
                earnings in 2 weeks after a commission is earned.
              </p>
            ) : (
              <>
                <p>Payouts will securely be made through Stripe.</p>
                <Button
                  variant="outline"
                  onClick={() =>
                    (window.location.href = "/coach/client-payments")
                  }
                  className="text-blurple text-base font-bold font-['Beausite Classic'] leading-normal mt-2"
                >
                  <SvgPlus /> Connect to Stripe
                </Button>
              </>
            )}
          </div>
          <div className="border border-[#d1d9e0] w-full mb-10 mt-10" />
          <div className="mb-6">
            <p className="text-black text-[28px] font-semibold font-['Beausite Classic']">
              Metrics
            </p>
          </div>
          <div className="w-full h-px" />
          {loadingReferrals ? (
            <p className="flex flex-col items-center gap-[12px] mt-[160px]">
              <Loader />
              <h3 className="font-bold text-[16px] mb-[4px] text-center">
                Checking for referrals...
              </h3>
            </p>
          ) : (
            <>
              <div className="w-full h-full justify-start items-start gap-8 sm:flex mb-[40px]">
                <div className="grow shrink basis-0 self-stretch bg-white rounded-lg border border-[#d1d9e0] flex flex-col justify-center items-center sm:w-1/3 xsm:mb-4">
                  <div className="self-stretch h-[26px] flex flex-col justify-start items-center mb-8">
                    <div className="self-stretch text-center text-black text-[22px] font-bold font-['Beausite Classic']">
                      Conversions
                    </div>
                  </div>
                  <div className="self-stretch h-[148px] flex flex-col justify-center items-center gap-4">
                    <div className="self-stretch text-center">
                      <span className="text-black/90 text-[36px] font-semibold leading-9">
                        {numSubscribedReferrals}
                      </span>
                      <div className="text-[#62696e] text-base font-bold leading-normal">
                        Subscribed
                      </div>
                    </div>
                    <div className="flex items-center justify-center text-center text-base font-normal leading-normal w-56">
                      Share your affiliate link above to your coach friends and
                      start earning!
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-between gap-2 sm:w-1/3 xsm:mb-2">
                  {boxes.map((box) => (
                    <div
                      key={box.id}
                      className="h-[152px] bg-white rounded-lg border border-[#d1d9e0] flex flex-col items-center justify-center pl-14 pr-14 pt-8 pb-8"
                    >
                      <div className="text-center text-black text-[32px] font-semibold leading-9">
                        {box.value}
                      </div>
                      <div className="text-center text-black whitespace-nowrap">
                        {box.label}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="sm:hidden border border-[#d1d9e0] w-full mt-10" />
              </div>
            </>
          )}
        </div>
        <div className="sm:max-w-[704px] px-[12px] flex flex-col w-full">
          <div className="flex flex-col gap-[12px] items-center"></div>
        </div>
        <div className="sm:w-[704px] w-full bg-white main-shadow rounded-[16px] pt-[24px] pb-[32px] md:pb-[42px] px-[24px] mb-10">
          <h3 className="font-semibold md:text-[32px] text-[28px] mr-auto mb-[24px]">
            Success Kit
          </h3>
          <div className="flex sm:flex-row flex-col md:gap-[56px] gap-[24px] items-start">
            <div className="flex flex-col items-start">
              <img
                src={successKit}
                alt="referral program success kit"
                className="md:ml-[9px] w-[216px] h-[293px] object-fill card-hover-shadow"
              />
            </div>

            <div className="flex flex-col gap-[20px]">
              <h3 className="font-bold text-[22px]">Let us help you</h3>
              <p className="text-graySlate text-[17px] max-w-[410px]">
                The Ultimate Guide to Marketing Zoee Referrals! This kit will
                give you everything you need to create successful referral
                marketing campaigns and make passive income.
              </p>
              <Button onClick={downloadSuccessKit} className="h-14 sm:w-1/2">
                Download Success Kit
              </Button>
            </div>
          </div>
        </div>
      </div>

      {showInviteModal && (
        <ContactInvite
          setShowInviteModal={setShowInviteModal}
          invites={invites}
          setInvites={setInvites}
          setShowConfirmInviteModal={setShowConfirmInviteModal}
          isReferralInviteVariant={true}
        />
      )}

      {showConfirmInviteModal && (
        <ConfirmReferralInvite
          setShowInviteModal={setShowInviteModal}
          setShowConfirmInviteModal={setShowConfirmInviteModal}
          invites={invites}
          setInvites={setInvites}
          getReferrals={getReferrals}
        />
      )}

      {showPypPreview && (
        <AnimatePresence>
          <PersonalizeYourPagePreview
            personalizeYourPageValues={personalizeYourPageValues}
            hasPypImage={hasPypImage}
            imageId={imageId}
            setShowPypPreview={setShowPypPreview}
          />
        </AnimatePresence>
      )}
    </>
  )
}

export default ReferACoach
