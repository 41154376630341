import React, { FC } from "react";
import Reports from "../../components/client-payments/Reports";
import Invoices from "../../components/client-payments/Invoices";
import Banking from "../../components/client-payments/Banking";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { UserCtx } from "../../context/userContext";
import { ClientPaymentRequests } from "../../api/app.service";
import { ClientPaymentsItem } from "../../types/clientPaymentTypes";
import { NotificationCtx } from "../../context/notificationContext";
import moment from "moment";
import useScrollToTop from "../../hooks/useScrollToTop";
import Loader from "../../components/ui/loader";
import { Button } from "../../components/ui/button";
import { Link } from "react-router-dom";
import { SvgNewWindow } from "../../components/icons/";

interface Props {}

const ClientPayments: FC<Props> = () => {
    const { user } = React.useContext(UserCtx);
    const [showReports, setShowReports] = React.useState<boolean>(true);
    const [showInvoices, setShowInvoices] = React.useState<boolean>(false);
    const [showBanking, setShowBanking] = React.useState<boolean>(false);
    const [connectAccountData, setConnectAccountData] = React.useState<any>(null);
    const [clientPaymentsData, setClientPaymentsData] =
        React.useState<Array<ClientPaymentsItem> | null>(null);
    const [activeDates, setActiveDates] = React.useState<Array<Date> | null>(null);
    const { renderError, setPopupNotification, updateNextStepsData } =
        React.useContext(CommonFunctionCtx);
    const { lastWebsocketMessage } = React.useContext(NotificationCtx);
    const [loadingBankingInfo, setLoadingBankingInfo] = React.useState<boolean>(true);
    const [expressLoginLink, setExpressLoginLink] = React.useState<string | null>(null);

    const showComponent = (callback: any) => {
        setShowReports(false);
        setShowInvoices(false);
        setShowBanking(false);
        callback(true);
    };

    const getConnectAccount = () => {
        if (user) {
            setLoadingBankingInfo(true);
            ClientPaymentRequests.getConnectAccount({
                user_id: parseInt(user.user_id),
            })
                .then((data) => {
                    setExpressLoginLink(data.express_login_link);
                    setConnectAccountData(data.connect_account_data);
                    setLoadingBankingInfo(false);
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                    setLoadingBankingInfo(false);
                });
        }
    };

    const checkUrlParams = () => {
        const params = new URLSearchParams(window.location.search);
        const invalidOnboardingLink = params.get("invalid-link");
        const connectComplete = params.get("connect-complete");
        if (invalidOnboardingLink && invalidOnboardingLink === "true") {
            showComponent(setShowBanking);
            setPopupNotification({
                show: true,
                title: "Invalid Onboarding Link",
                message: "Try starting your onboarding again in a few minutes!",
                callback: null,
            });
            getConnectAccount();
        } else if (connectComplete && connectComplete === "true") {
            setLoadingBankingInfo(true);
            setTimeout(() => {
                updateNextStepsData(false).then(() => {
                    showComponent(setShowBanking);
                    getConnectAccount();
                });
            }, 4000);
        } else {
            getConnectAccount();
        }
        window.history.replaceState(null, "", window.location.pathname);
    };

    const sortPayments = (payments: any) => {
        const sorted = payments.sort((a: any, b: any) => {
            const bDate: any = new Date(b.transaction_date);
            const aDate: any = new Date(a.transaction_date);
            return bDate - aDate;
        });
        return sorted;
    };

    const getClientPayments = () => {
        if (user) {
            let startDate = moment(Date.now()).subtract(30, "days").format("yyyy-MM-DD");
            let endDate = moment(Date.now()).add(30, "days").format("yyyy-MM-DD");

            if (activeDates) {
                startDate = moment(activeDates[0]).format("yyyy-MM-DD");
                endDate = moment(activeDates[1]).format("yyyy-MM-DD");
            }

            const reqData = {
                user_id: parseInt(user.user_id),
                from_date: startDate,
                to_date: endDate,
            };

            ClientPaymentRequests.getClientPayments(reqData)
                .then((data) => {
                    setClientPaymentsData(sortPayments(data?.client_payments_list).reverse());
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                });
        }
    };

    useScrollToTop();

    React.useEffect(() => {
        checkUrlParams();
    }, []);

    React.useEffect(() => {
        if (connectAccountData && activeDates) {
            getClientPayments();
        }
    }, [connectAccountData, activeDates]);

    React.useEffect(() => {
        if (lastWebsocketMessage && lastWebsocketMessage.type === "stripe_identity") {
            getConnectAccount();
        }
    }, [lastWebsocketMessage]);

    return (
        <div className="pt-[12px] md:pt-0 mx-auto md:max-w-[1112px] w-auto px-[16px] flex flex-col -mt-0 md:-mt-[33px]">
            <h1 className="hidden md:block mt-[24px] font-bold text-[36px]">Client Payments</h1>
            {!loadingBankingInfo ? (
                <>
                    <div className="flex flex-col md:flex-row justify-between items-center w-[100%] mt-[12px] md:mt-[50px] md:mb-[54px]">
                        <div
                            className="flex items-center justify-center md:justify-start gap-[8px] text-graySlate 
                        text-base font-bold overflow-x-auto w-full md:w-auto
                        mb-[16px] md:mb-0 mr-auto md:mr-0"
                        >
                            <button
                                className={`${
                                    showReports
                                        ? "btn-primary btn-secondary-nav h-[40px] md:h-[48px]"
                                        : "text-graySlate hover:bg-grayFlash duration-150 rounded-[10px]"
                                } px-[16px] py-[8px] md:py-[12px] h-[48px]`}
                                onClick={() => showComponent(setShowReports)}
                            >
                                Reports
                            </button>
                            <button
                                className={`${
                                    showInvoices
                                        ? "btn-primary btn-secondary-nav h-[40px] md:h-[48px]"
                                        : "text-graySlate hover:bg-grayFlash duration-150 rounded-[10px]"
                                } px-[16px] py-[8px] md:py-[12px] h-[48px]`}
                                onClick={() => showComponent(setShowInvoices)}
                            >
                                Invoices
                            </button>
                            <button
                                className={`${
                                    showBanking
                                        ? "btn-primary btn-secondary-nav h-[40px] md:h-[48px]"
                                        : "text-graySlate hover:bg-grayFlash duration-150 rounded-[10px]"
                                } px-[16px] py-[8px] md:py-[12px] h-[48px]`}
                                onClick={() => showComponent(setShowBanking)}
                            >
                                Banking
                            </button>
                        </div>
                        
                        {Boolean(connectAccountData?.identity_status === 'verified' && connectAccountData.is_bank_connected) && expressLoginLink && (
                          <Button variant="outline" className="text-black border-black" asChild>
                            <Link to={expressLoginLink} target="_blank">
                              Stripe Dashboard
                              <SvgNewWindow />
                            </Link>
                          </Button>
                        )}
                    </div>

                    {connectAccountData && (
                        <div>
                            {showReports && (
                                <Reports
                                    connectAccountData={connectAccountData}
                                    clientPaymentsData={clientPaymentsData}
                                    setActiveDates={setActiveDates}
                                />
                            )}
                            {showInvoices && (
                                <Invoices
                                    setActiveDates={setActiveDates}
                                    clientPaymentsData={clientPaymentsData}
                                />
                            )}
                            {showBanking && <Banking connectAccountData={connectAccountData} />}
                        </div>
                    )}
                </>
            ) : (
                <div className="flex flex-col items-center gap-[12px] mt-[24px]">
                    <Loader />
                    <h3 className="font-bold text-[16px] mb-[4px] text-center">
                        Loading your banking information...
                    </h3>
                </div>
            )}
        </div>
    );
};

export default ClientPayments;
