import React from "react";
import { ClientPaymentRequests } from "../../api/app.service";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { UserCtx } from "../../context/userContext";
import { loadStripe } from "@stripe/stripe-js";
import caution from "../../assets/images/identity-caution.png";
import failed from "../../assets/images/identity-failed.png";
import useLoading from "../../hooks/useLoading";
import { SvgCheckCircleSolid, SvgInProgress } from "../icons";

type Props = {
  connectAccountData: any;
};

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
);

export default function StripeVerifyIdentityButton({ connectAccountData }: Props) {
  const { user } = React.useContext(UserCtx);
  const { renderError } =
    React.useContext(CommonFunctionCtx);
  const [isStripeRequestProcessing, setIsStripeRequestProcessing] =
    React.useState<boolean>(false);

  const { startLoading, stopLoading } = useLoading();

  const getStripeVerificationSession = async () => {
    const stripe = await stripePromise;
    if (user) {
      startLoading();
      setIsStripeRequestProcessing(true);
      ClientPaymentRequests.getStripeVerificationSession({
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          if (stripe) {
            stripe.verifyIdentity(data.stripe_client_secret);
          }
          setIsStripeRequestProcessing(false);
        })
        .catch((err) => {
          console.error(err);
          renderError(err.response.data.message);
          setIsStripeRequestProcessing(false);
        }).finally(() => {
          stopLoading();
        });
    }
  };

  const retrieveStripeVerificationSession = async () => {
    const stripe = await stripePromise;
    if (user) {
      startLoading();
      setIsStripeRequestProcessing(true);
      ClientPaymentRequests.retrieveStripeVerificationSession({
        verification_session_id: connectAccountData.verification_id,
      })
        .then((data) => {
          if (stripe) {
            stripe.verifyIdentity(data.stripe_client_secret);
          }
          setIsStripeRequestProcessing(false);
        })
        .catch((err) => {
          console.error(err);
          renderError(err.response.data.message);
          setIsStripeRequestProcessing(false);
        }).finally(() => {
          stopLoading();
        });
    }
  };

  return (
    <>
      {/* POSSIBLE identity_status STRINGS:  */}
      {/* requires_input / incomplete / processing / verified / canceled */}

      {(!connectAccountData.identity_status ||
        connectAccountData.identity_status === "incomplete") && (
          <button
            onClick={getStripeVerificationSession}
            role="link"
            disabled={isStripeRequestProcessing}
            className="text-blurple font-bold text-base"
          >
            Get Started
          </button>
        )}

      {connectAccountData.identity_status === "requires_input" && (
        <>
          {/* Stripe Identity application failed state */}
          {!connectAccountData.identity_last_error ? (
            <>
              <div className="flex gap-[12px]">
                <img
                  src={caution}
                  alt="caution"
                  className="w-[40px] h-[40px]"
                />
                <div className="flex flex-col font-bold">
                  <p className="text-graySlate text-base">Status:</p>
                  <p className="">Incomplete</p>
                </div>
              </div>
              <button
                onClick={retrieveStripeVerificationSession}
                role="link"
                disabled={isStripeRequestProcessing}
                className="text-blueVibrant font-bold text-base"
              >
                Resume
              </button>
            </>
          ) : (
            <>
              <p className="max-w-[80%] text-base text-grayCharcoal font-semibold text-center"></p>
              <div className="flex gap-[12px]">
                <img src={failed} alt="failed" className="w-[40px] h-[40px]" />
                <div className="flex flex-col font-bold">
                  <p className="text-graySlate text-base">Status:</p>
                  <p className="">Failed</p>
                </div>
              </div>
              <div className="text-center">
                <p className="text-vividRed text-base mb-[4px]">
                  {/* <span className="text-grayCharcoal">Reason:</span>{" "} */}
                  {connectAccountData.identity_last_error.reason}
                </p>
                <p className="text-base text-grayCharcoal">
                  Please try again or contact Zoee support at support@zoee.com.
                </p>
              </div>
              <button
                onClick={retrieveStripeVerificationSession}
                role="link"
                disabled={isStripeRequestProcessing}
                className="text-blueVibrant font-bold text-base"
              >
                Retry
              </button>
            </>
          )}
        </>
      )}

      {connectAccountData.identity_status === "processing" && (
        <>
          <div className="flex gap-[12px]">
            <SvgInProgress />
            <div className="flex flex-col font-bold">
              <p className="text-graySlate text-base">Status:</p>
              <p className="">Under review</p>
            </div>
          </div>
          <p className="max-w-[80%] text-base text-grayCharcoal text-center my-[8px]">
            Your application is being reviewed by Stripe. This process may take
            a few minutes.
          </p>
        </>
      )}

      {connectAccountData.identity_status === "verified" && (
        <div className="flex gap-[12px]">
          <SvgCheckCircleSolid />
          <div className="flex flex-col font-bold">
            <p className="text-graySlate text-base">Status:</p>
            <p className="">Verified</p>
          </div>
        </div>
      )}
    </>
  );
};
