import React from "react";
import SubscriptionTag from "../../components/subscription/SubscriptionTag";
import moment from "moment";
import { UserCtx } from "../../context/userContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { SubscriptionContext } from "../../pages/subscription/context/subscriptionContext";
import { SubscriptionRequests, ReferralRequests } from "../../api/app.service";
import DeleteDialogueModal from "../DialogueModal";
import useDate from "../../hooks/useDate";
import useLoading from "../../hooks/useLoading";
import { Button } from "../ui/button";
import { SvgChatEllipses, SvgCheck, SvgLightning } from "../icons/";
import { useIntercom } from "react-use-intercom";

const CurrentSubscription: React.FC = () => {
  const { user } = React.useContext(UserCtx);
  const {
    subscription,
    setSelectedSubscription,
    showSubscriptionComponents,
    setIsSubscriptionUpdate,
    setShowSubscriptionCheckout,
    setShowSubscriptionPlans,
    plans,
    setHideSecondaryNav,
    selectedPaymentMethod,
    getSubscription,
  } = React.useContext(SubscriptionContext);
  const { renderError, renderSuccess } =
    React.useContext(CommonFunctionCtx);
  const { startLoading, stopLoading } = useLoading();
  const [addPromocode, setAddPromocode] = React.useState<boolean>(false);
  const [promoCode, setPromoCode] = React.useState<string>("");
  const { feature1, feature2, feature3, feature4 } =
    subscription?.stripe_product_metadata || {};
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    React.useState<boolean>(false);
  const { mdDate } = useDate();
  const { show } = useIntercom();

  const handleCancelSubscriptionClick = () => {
    if (showCancelSubscriptionModal) {
      console.log("Cancel Subscription");
    } else {
      setShowCancelSubscriptionModal(true);
    }
  };

  const updateSubscription = (promoData: any) => {
    startLoading();
    SubscriptionRequests.updateSubscription({
      user_id: parseInt(user.user_id),
      stripe_payment_method_id: selectedPaymentMethod.stripe_payment_method_id,
      current_stripe_price_id: subscription?.stripe_price_id,
      new_stripe_price_id: subscription?.stripe_price_id,
      promotion_code: promoCode,
    })
      .then((data) => {
        if (data.subscribed_with_referral) {
          ReferralRequests.updateReferralOnSubscribe({
            user_id: parseInt(user.user_id),
            promo_code: promoCode,
            email: user.email,
          })
            .then((data) => {})
            .catch((ex) => {
              console.error("updateReferralOnSubscribe err", ex);
            });
        }
      })
      .then(() => {
        const details = promoData.promo_code;
        const discountType = details.promo_value_type;
        const discountUnits = details.promo_value;
        if (discountType === "percent") {
          const discountDollars = (discountUnits / 100) * subscription?.amount;
          renderSuccess(
            `Congrats! Your promotion code is saving you $${discountDollars.toFixed(
              2,
            )} on future payments`,
          );
        } else if (discountType === "amount") {
          renderSuccess(
            `Congrats! Your promotion code is saving you $${discountUnits.toFixed(
              2,
            )} on future payments`,
          );
        }
        setAddPromocode(false);
        setShowSubscriptionCheckout(false);
        setTimeout(() => {
          getSubscription();
        }, 2500);
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
      })
      .finally(() => {
        stopLoading();
      });
  };

  const cancelSubscription = () => {
    startLoading();
    SubscriptionRequests.cancelSubscription({
      subscription_id: subscription?.subscription_id,
    })
      .then((data) => {
        console.log(data);
        getSubscription();
        renderSuccess(data.message);
        setShowCancelSubscriptionModal(false);
        // setUserActiveSubscription()
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
        stopLoading();
      });
  };

  const validatePromoCode = () => {
    if (promoCode !== "") {
      startLoading();
      SubscriptionRequests.validatePromoCode({
        promo_code: promoCode,
        promo_duration_interval: subscription?.recurring_interval,
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          if (!data.promo_valid) {
            renderError(data.promo_invalid_reason);
            setAddPromocode(false);
            stopLoading();
          } else {
            updateSubscription(data);
          }
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          stopLoading();
        });
    }
  };

  const managePlan = () => {
    if (plans) {
      const interval = subscription?.recurring_interval;
      setIsSubscriptionUpdate(true);
      showSubscriptionComponents(setShowSubscriptionCheckout);
      setHideSecondaryNav(true);
      setSelectedSubscription(
        interval === "annual" ? plans?.monthly : plans?.annual,
      );
    } else {
      console.log("No plans found");
    }
  };

  const setTwoYearPlan = () => {
    if (plans) {
      setIsSubscriptionUpdate(true);
      showSubscriptionComponents(setShowSubscriptionCheckout);
      setHideSecondaryNav(true);
      setSelectedSubscription(plans?.twoYears);
    } else {
      console.log("No plans found");
    }
  };

  const getTrialDaysRemaining = () => {
    const expiration = moment(user?.trialExpiration);
    const now = moment();

    return Math.max(0, expiration.diff(now, "days"));
  };

  const getCurrentPlanSection = () => {
    if (subscription) {
      if (
        subscription?.subscription_end_date === "None" &&
        subscription.subscription_promo_code !== "500FOREVER"
      ) {
        return (
          <>
            <button
              className="btn-primary bg-vividRed mx-auto mt-[24px] h-[42px]"
              onClick={handleCancelSubscriptionClick}
            >
              Cancel Subscription
            </button>
            {showCancelSubscriptionModal && (
              <DeleteDialogueModal
                isWarningVariant
                setShowDialogueModal={setShowCancelSubscriptionModal}
                helpText={`Are you sure you want to cancel your subscription? If you cancel, your subscription will be active until ${mdDate(subscription.subscription_next_payment_date)}`}
                header="Cancel Subscription?"
                confirmButtonText="Cancel Subscription"
                confirmCallbackFn={cancelSubscription}
              />
            )}
          </>
        );
      } else if (
        subscription?.subscription_end_date !== "None" &&
        subscription.subscription_promo_code !== "500FOREVER"
      ) {
        return (
          <h2 className="font-bold">
            Subscription end date: {mdDate(subscription.subscription_end_date)}
          </h2>
        );
      } else {
        return <></>;
      }
    }
  };

  return (
    <div className="main-shadow grow rounded-[16px] p-[24px] sm:min-w-[490px] md:h-full md:w-[735px] md:p-[32px]">
      <div className="mb-[24px] flex w-full items-center justify-between">
        <h2 className="text-[22px] font-bold">Current Plan</h2>
        {subscription && (
          <div className="flex flex-col items-center justify-center gap-2 md:flex-row">
            <button onClick={managePlan} className="text-primaryBlue font-bold">
              {subscription?.promotion_details?.lifetime ||
              subscription?.promo_duration === 2 ? (
                <></>
              ) : (
                <>{`Switch to ${subscription.recurring_interval === "annual" ? "Monthly" : "Annual"}`}</>
              )}
            </button>
          </div>
        )}
      </div>

      <div className="border-grayMist flex w-full flex-col rounded-[24px] border p-[24px] md:p-[32px]">
        {!subscription ? (
          <>
            <div className="mb-[96px] flex w-full justify-between font-bold">
              <SubscriptionTag subscriptionName={"Free Trial"} />
              {(getTrialDaysRemaining() || 0) <= 0 ? (
                <h4 className="text-vividRed text-[32px]">Expired</h4>
              ) : (
                <h4 className="text-green text-[32px]">Free</h4>
              )}
            </div>
            <div className="flex items-center justify-between">
              <div className="mb-[18px] flex flex-col gap-[8px] text-base md:mb-0">
                <div className="flex items-center gap-[8px]">
                  <SvgChatEllipses />
                  <p>Need help deciding?</p>
                </div>
                <button
                  onClick={() => {
                    show();
                  }}
                  className="text-blurple font-bold"
                >
                  Chat with our support
                </button>
              </div>
              <Button
                onClick={() =>
                  showSubscriptionComponents(setShowSubscriptionPlans)
                }
                className="flex w-[158px] min-w-fit items-center gap-[10px]"
              >
                Upgrade Plan
                <SvgLightning />
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="mb-[24px] flex items-center justify-between">
              {/* PLAN DETAILS */}
              <div className="flex flex-col gap-[12px]">
                <h2 className="text-[22px] font-bold">
                  {subscription?.promotion_details?.lifetime ? (
                    <>Zoee Lifetime</>
                  ) : (
                    <>{`Zoee ${subscription?.recurring_interval === "annual" ? "Annual" : "Monthly"}`}</>
                  )}
                </h2>
                <p className="text-graySlate text-[16px]">
                  A virtual office for your practice
                </p>
              </div>
              {/* PRICE */}
              <div className="flex items-center leading-none">
                <h4 className="text-grayCharcoal text-[36px] font-semibold">
                  $
                </h4>
                {/* DESKTOP AMOUNT NUM */}
                <h4 className="text-[40px] font-bold md:text-[56px]">
                  {subscription?.amount}
                </h4>
                <h4 className="text-graySlate ml-[4px] text-[18px]">
                  {subscription?.promotion_details?.lifetime && <>/one-time</>}
                  {subscription?.recurring_interval === "annual" &&
                    subscription?.promo_duration === 2 && <>/two years</>}
                  {subscription?.recurring_interval === "annual" &&
                    subscription?.promo_duration !== 2 &&
                    !subscription?.promotion_details?.lifetime && <>/year</>}
                  {subscription?.recurring_interval !== "annual" && <>/month</>}
                </h4>
              </div>
            </div>
            <div className="flex flex-col gap-[12px]">
              {feature1 && (
                <div className="flex items-center gap-[16px]">
                  <SvgCheck />
                  <p className="text-base font-bold">{feature1}</p>
                </div>
              )}
              {feature2 && (
                <div className="flex items-center gap-[16px]">
                  <SvgCheck />
                  <p className="text-base font-bold">{feature2}</p>
                </div>
              )}
              {feature3 && (
                <div className="flex items-center gap-[16px]">
                  <SvgCheck />
                  <p className="text-base font-bold">{feature3}</p>
                </div>
              )}
              {feature4 && (
                <div className="flex items-center gap-[16px]">
                  <SvgCheck />
                  <p className="text-base font-bold">{feature4}</p>
                </div>
              )}
            </div>
          </>
        )}
        {getCurrentPlanSection()}
      </div>

      {/* Promocode */}
      {/*UNUSED CODE 2/26/24 */}
      {/*
            <div className="mt-[32px] w-full">
                {addPromocode ? (
                    <div className="mb-[16px] flex gap-[16px] items-center w-full">
                        <div className="default-input w-[222px]">
                            <input
                                type="text"
                                placeholder="Enter Promocode"
                                onChange={(e:any) => setPromoCode(e.target.value)}
                            />
                            <div className="overlay">Promocode</div>
                        </div>
                        <Button
                            className="h-[56px] grow"
                            onClick={validatePromoCode}
                        >
                            Apply
                        </Button>
                        <button
                            onClick={() => setAddPromocode(false)}
                            className="btn-primary btn-secondary h-[56px] grow"
                        >
                            Cancel
                        </button>
                    </div>
                ) : (
                    <button
                        onClick={() => setAddPromocode(true)}
                        className="flex items-center gap-[8px] text-graySlate text-base text-graySlate"
                    >
                        <PromoCode fill="gray" />
                        Add Promocode
                    </button>
                )}
            </div>
            */}
    </div>
  );
};

export default CurrentSubscription;
