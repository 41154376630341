import React, { useRef, FC } from "react";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { UserCtx } from "../../context/userContext";
import { CalendarRequests } from "../../api/app.service";
import Availability from "../../components/scheduling/availability/Availability";
import Calendar from "../../components/scheduling/calendar/Calendar";
import NewMeeting from "../../components/scheduling/new-meeting/NewMeeting";
import NewMeetingDropdown from "../../components/scheduling/NewMeetingDropdown";
import CalendarLoadingSpinner from "../../components/scheduling/calendar/CalendarLoadingSpinner";
import { defaultAvailability } from "../../objects/availabilityObjects";
import useOutsideClick from "../../hooks/useOutsideClick";
import { Link, useLocation } from "react-router-dom";
import { MeetingPrefillOptions } from "../../types/meetingTypes";
import { Button } from "../../components/ui/button";
import useLoading from "../../hooks/useLoading";
import { SvgPlus } from "../../components/icons/";
import { ConnectCalendarCTA } from "../../components/account-settings/calendars/ConnectCalendarCTA";

interface Props {
  isTodayPage?: boolean;
  isSchedulePage?: boolean;
  setPickerOpen?: any;
  pickerOpen?: any;
}

const CoachScheduling: FC<Props> = ({
  isTodayPage = false,
  isSchedulePage = false,
  setPickerOpen,
  pickerOpen,
}) => {
  const { renderError, renderSuccess } =
    React.useContext(CommonFunctionCtx);
  const { stopLoading } = useLoading();
  const { user } = React.useContext(UserCtx);
  const [isCalendarConnected, setIsCalendarConnected] =
    React.useState<boolean>(false);
  const [showCalendar, setShowCalendar] = React.useState<boolean>(true);
  const [showAvailability, setShowAvailability] =
    React.useState<boolean>(false);
  const [showNewMeetingDropdown, setShowNewMeetingDropdown] =
    React.useState<boolean>(false);
  const [newMeetingType, setNewMeetingType] = React.useState<
    "individual" | "group" | "custom"
  >("custom");
  const [showNewMeeting, setShowNewMeeting] = React.useState<boolean>(false);
  const [showRescheduleMeeting, setShowRescheduleMeeting] =
    React.useState<boolean>(false);
  const [newMeetingPrefillOptions, setNewMeetingPrefillOptions] =
    React.useState<MeetingPrefillOptions | null>(null);
  const [hideSecondaryNav, setHideSecondaryNav] =
    React.useState<boolean>(false);
  const [userCalendars, setUserCalendars] = React.useState<any>({});
  const [userHasCalendar, setUserHasCalendar] = React.useState<boolean>(false);
  const [userCalendarsLoading, setUserCalendarsLoading] =
    React.useState<boolean>(false);
  const [clickReschedule, setClickReschedule] = React.useState<boolean>(false);
  const [clickTodayReschedule, setClickTodayReschedule] =
    React.useState<boolean>(false);
  const [backToContacts, setBackToContacts] = React.useState<boolean>(false);
  const [keepBackToButton, setKeepBackToButton] =
    React.useState<boolean>(false);
  const [contact, setContact] = React.useState<any>(null);
  const ref = useRef(null);
  const location = useLocation();

  useOutsideClick(ref, () => {
    setShowNewMeetingDropdown(false);
  });

  const showComponent = (callback: any) => {
    setShowCalendar(false);
    setShowAvailability(false);
    setShowNewMeeting(false);
    callback(true);
  };

  React.useEffect(() => {
    if (showNewMeeting) {
      showComponent(setShowNewMeeting);
      setHideSecondaryNav(true);
    } else {
      setHideSecondaryNav(false);
    }
    setShowNewMeetingDropdown(false);
  }, [showNewMeeting]);

  const createDefaultAvailability = () => {
    if (user) {
      // setLoading(true);
      const userDefaultAvailability = {
        ...defaultAvailability,
        timezone: user.timezone,
      };
      CalendarRequests.setAvailabilityRules({
        user_id: parseInt(user.user_id),
        availability_rules: [userDefaultAvailability],
      })
        .then(() => {
          // setLoading(false);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          stopLoading();
        });
    }
  };

  const completeRegisterCalendar = (code: string, data: any) => {
    const redirectUrl = `${process.env.REACT_APP_BASE_URL}/account-settings/calendars`;
    if (code && user) {
      CalendarRequests.registerCalendar({
        user_id: parseInt(user.user_id),
        profile_count: data.profile_count,
        calendar_connect_code: code,
        redirect_url: redirectUrl,
      })
        .then(() => {
          renderSuccess("Calendar connected!");
          createDefaultAvailability();
          fetchUserCalendars();
        })
        .catch((ex: any) => {
          console.log(ex);
          renderError(ex.response.data.message);
          stopLoading();
        });
      window.history.replaceState(null, "", window.location.pathname);
    }
  };

  const checkUrlParams = (data: any) => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const createMeeting = params.get("create");
    if (code && user) {
      completeRegisterCalendar(code, data);
      stopLoading();
    } else if (createMeeting) {
      setShowNewMeeting(true);
      setNewMeetingType("individual");
      stopLoading();
      window.history.replaceState(null, "", window.location.pathname);
    } else {
      window.history.replaceState(null, "", window.location.pathname);
      stopLoading();
    }
  };

  const fetchUserCalendars = () => {
    setUserCalendarsLoading(true);
    if (user) {
      CalendarRequests.getCalendarList({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          setUserCalendarsLoading(false);
          setUserCalendars({
            profileCount: data.profile_count,
            calendars: data.calendars,
          });
          if (data.profile_count > 0) {
            setUserHasCalendar(true);
            setIsCalendarConnected(true);
          }
          checkUrlParams(data);
        })
        .catch((ex: any) => {
          setUserCalendarsLoading(false);
          console.log(ex);
          renderError(ex.response.data.message);
          setUserCalendarsLoading(false);
        });
    } else {
      stopLoading();
    }
  };

  // Check location state and populate new meeting if service and contact data was sent
  React.useEffect(() => {
    let state = location.state;
    if (state) {
      if (state.service && state.contact) {
        setNewMeetingPrefillOptions({
          service: state.service,
          contact: state.contact,
        });
        setNewMeetingType("individual");
        setShowNewMeeting(true);
      }
      if (!state.service && state.contact) {
        setNewMeetingPrefillOptions({ contact: state.contact });
        setNewMeetingType("individual");
        setShowNewMeeting(true);
      }
    }
  }, [location]);

  React.useEffect(() => {
    fetchUserCalendars();
  }, [user]);

  // useScrollToTop(user, isSchedulePage)

  return (
    <>
      {isTodayPage && (
        <div
          className="hidden xl:block relative md:px-0 px-[20px] -mt-[62px] ml-[1073px]"
          // ref={ref}
        >
          <Link to="/coach/scheduling">
            <Button size="lg">
              New Meeting
              <SvgPlus />
            </Button>
          </Link>
          {showNewMeetingDropdown && (
            <div className="absolute top-[54px] -left-[60px] z-[600]">
              <NewMeetingDropdown
                setNewMeetingType={setNewMeetingType}
                setShowNewMeeting={setShowNewMeeting}
                setKeepBackToButton={setKeepBackToButton}
                setClickReschedule={setClickReschedule}
              />
            </div>
          )}
        </div>
      )}
      {isTodayPage && (
        <>
          {userCalendarsLoading && <CalendarLoadingSpinner />}
          <div className={`mt-[12px] w-full flex items-center flex-col`}>
            {!userCalendarsLoading && !isCalendarConnected && (
              <ConnectCalendarCTA isCalendarConnected={isCalendarConnected} />
            )}
            {isCalendarConnected && (
              <>
                <div className="w-full h-full flex items-end py-6 px-4">
                  {showCalendar && (
                    <Calendar
                      setPickerOpen={setPickerOpen}
                      pickerOpen={pickerOpen}
                      isTodayPage={isTodayPage}
                      userHasCalendar={userHasCalendar}
                      setHideSecondaryNav={setHideSecondaryNav}
                      setNewMeetingType={setNewMeetingType}
                      setShowNewMeeting={setShowNewMeeting}
                      showRescheduleMeeting={showRescheduleMeeting}
                      setShowRescheduleMeeting={setShowRescheduleMeeting}
                      clickReschedule={clickReschedule}
                      setClickReschedule={setClickReschedule}
                      clickTodayReschedule={clickTodayReschedule}
                      backToContacts={backToContacts}
                      contact={contact}
                      setKeepBackToButton={setKeepBackToButton}
                      keepBackToButton={keepBackToButton}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}

      {isSchedulePage && !isTodayPage && (
        <div className="container">
          {userCalendarsLoading && <CalendarLoadingSpinner />}
          <div className={`mt-[12px] w-full flex items-center flex-col`}>
            {!userCalendarsLoading && !isCalendarConnected && (
              <ConnectCalendarCTA isCalendarConnected={isCalendarConnected} />
            )}
            {isCalendarConnected && (
              <>
                <div className="md:max-w-[1112px] w-full px-[16px] md:flex items-center justify-between">
                  {!hideSecondaryNav && !showRescheduleMeeting && (
                    <>
                      <div className="flex items-center justify-center md:justify-start mr-auto md:ml-[20px] mb-[28px] md:mb-0 md:mx-auto overflow-x-auto md:w-[1080px] text-graySlate text-base font-bold gap-[12px]">
                        <button
                          className={`${
                            showCalendar
                              ? "btn-primary btn-secondary bg-grayMist"
                              : "hover:bg-grayFlash duration-150 rounded-[10px]"
                          } px-[16px] py-[8px] md:py-[12px] h-[40px] md:h-[48px]`}
                          onClick={() => showComponent(setShowCalendar)}
                        >
                          Calendar
                        </button>
                      </div>
                      <div
                        className="hidden md:block relative md:px-0 px-[20px]"
                        ref={ref}
                      >
                        <Button
                          onClick={() =>
                            setShowNewMeetingDropdown(
                              () => !showNewMeetingDropdown
                            )
                          }
                          size="lg"
                        >
                          New Meeting
                          <SvgPlus />
                        </Button>
                        {showNewMeetingDropdown && (
                          <div className="absolute top-[54px] right-0 z-[600]">
                            <NewMeetingDropdown
                              setNewMeetingType={setNewMeetingType}
                              setShowNewMeeting={setShowNewMeeting}
                              setKeepBackToButton={setKeepBackToButton}
                              setClickReschedule={setClickReschedule}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div>
                  {showCalendar && (
                    <Calendar
                      isSchedulePage={isSchedulePage}
                      userHasCalendar={userHasCalendar}
                      setHideSecondaryNav={setHideSecondaryNav}
                      setNewMeetingType={setNewMeetingType}
                      setShowNewMeeting={setShowNewMeeting}
                      showRescheduleMeeting={showRescheduleMeeting}
                      setShowRescheduleMeeting={setShowRescheduleMeeting}
                      clickReschedule={clickReschedule}
                      setClickReschedule={setClickReschedule}
                      clickTodayReschedule={clickTodayReschedule}
                      backToContacts={backToContacts}
                      contact={contact}
                      setKeepBackToButton={setKeepBackToButton}
                      keepBackToButton={keepBackToButton}
                    />
                  )}
                  {showAvailability && <Availability />}
                  {showNewMeeting && (
                    <NewMeeting
                      setShowCalendar={setShowCalendar}
                      setShowNewMeeting={setShowNewMeeting}
                      newMeetingType={newMeetingType}
                      setNewMeetingType={setNewMeetingType}
                      prefillOptions={newMeetingPrefillOptions}
                      showRescheduleMeeting={showRescheduleMeeting}
                      setShowRescheduleMeeting={setShowRescheduleMeeting}
                      clickReschedule={clickReschedule}
                      setClickReschedule={setClickReschedule}
                      clickTodayReschedule={clickTodayReschedule}
                      backToContacts={backToContacts}
                      contact={contact}
                      keepBackToButton={keepBackToButton}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CoachScheduling;
