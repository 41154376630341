import React, { FC } from "react"
import BusinessLink from "./BusinessLink"
import {
  ClientPaymentRequests,
  ProfileRequests,
  UserInfo,
  VerifyPhone,
} from "../../api/app.service"
import { useLocation, useNavigate } from "react-router-dom"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import useScrollToTop from "../../hooks/useScrollToTop"
import Loader from "../../components/ui/loader"
import useLoading from "../../hooks/useLoading"
import CodeOfEthicsModal from "../../components/CodeOfEthicsModal"
import OnboardingCallModal from "../../components/OnboardingCallModal"
import { Button } from "../../components/ui/button"
import { motion } from "framer-motion"
import StripeVerifyIdentityButton from "../../components/client-payments/StripeVerifyIdentityButton"
import CloseModal from "../../components/CloseModal"
import { NotificationCtx } from "../../context/notificationContext"
import { SubscriptionCtx } from "../../context/subscriptionContext"
import { UserCtx } from "../../context/userContext"
import {
  SvgBilling,
  SvgBusiness,
  SvgCalendar,
  SvgChat,
  SvgCredentials,
  SvgPhone,
  SvgUser3,
  SvgUserCheck,
  SvgVerifyIdentity,
  SvgSwap,
} from "../../components/icons/"
import { StreamChat } from "stream-chat"
import { isLocalStorageAvailable } from "../../utils/isLocalStorageAvailable"

interface Props {}

declare global {
  interface Window {
    Calendly: {
      initPopupWidget(config: { url: string }): void
    }
  }
}

const ManageBusiness: FC<Props> = () => {
  const { user } = React.useContext(UserCtx)
  const { setUser } = React.useContext(UserCtx)

  const location = useLocation()
  const [businessLinks, setBusinessLinks] = React.useState<any[]>([])
  const [verifyPhoneForm, setVerifyPhoneForm] = React.useState<boolean>(false)
  const [tryProfileFeature, setTryProfileFeature] =
    React.useState<boolean>(false)
  const [profileAccessStatus, setProfileAccessStatus] =
    React.useState<string>("blocked")
  const [connectAccountData, setConnectAccountData] = React.useState<any>(null)
  const [showVerificationModal, setShowVerificationModal] =
    React.useState<boolean>(false)
  // const [phoneVerified, setPhoneVerified] = React.useState<boolean>(
  //   user.phoneVerified,
  // );
  const [sentCode, setSentCode] = React.useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const { renderError, hideAlert, renderSuccess, updateNextStepsData } =
    React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const { lastWebsocketMessage } = React.useContext(NotificationCtx)
  const { setUserActiveSubscription } = React.useContext(SubscriptionCtx)
  // const [tagModalOpen, setTagModalOpen] = React.useState<boolean>(true);
  const [coachPublicProfileData, setCoachPublicProfileData] =
    React.useState<any>()
  const navigate = useNavigate()
  const coachTags = coachPublicProfileData?.coach_public_profile?.tags


  const [isCoach, setIsCoach] = React.useState(true)

  const toggleRole = () => {
    setIsCoach(!isCoach)
  }

  const goBackToMobileNav = () => {
    navigate("/nav-mobile")
  }

  const { setPopupNotification, endActiveSession, previewImgUrl } =
    React.useContext(CommonFunctionCtx)

  const getCoachPrivateProfile = async () => {
    if (user?.coachProfile) {
      startLoading()
      await ProfileRequests.getCoachPrivateProfile({
        coach_profile_id: parseInt(user.coachProfile.profile_id),
      })
        .then((data) => {
          setCoachPublicProfileData(data)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }

  const sendVerifyPhone = () => {
    hideAlert()
    if (!sentCode) {
      VerifyPhone.sendPhoneCode({ access_token: user.access_token })
        .then((data) => {
          setSentCode(true)
          renderSuccess("Your email address is verified!")
          data.message === "complete" && setVerifyPhoneForm(true)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  if (["wrong-code", "no-code"].includes(location?.state?.message)) {
    renderError(
      "Email verification failed. Please try again or contact support."
    )
    navigate(location.pathname, { replace: true })
  }

  const getConnectAccount = () => {
    startLoading()
    if (user) {
      ClientPaymentRequests.getConnectAccount({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          setConnectAccountData(data.connect_account_data)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }

  React.useEffect(() => {
    if (
      lastWebsocketMessage &&
      lastWebsocketMessage.type === "stripe_identity"
    ) {
      getConnectAccount()
    }
  }, [lastWebsocketMessage])

  const populateBusinessLinks = (nextStepsData: any) => {
    const businessLinksArray = [
      // {
      //   complete: connectAccountData?.identity_status === "verified" ? 1 : 0,
      //   title: "Confirm Details",
      //   text: "Verify your identity for the safety of our coaches and members.",
      //   link: "",
      //   linkId: "verification",
      //   step: 1,
      //   icon: <SvgUserCheck fill={"black"} />,
      // },
      {
        title: "My Calendar",
        text: "Connect your calendar to manage your availability, schedule, and upcoming sessions.",
        link: "/account-settings/calendars",
        linkId: "calendars",
        complete: nextStepsData?.is_calendar_connect,
        step: 1,
        icon: <SvgCalendar />,
      },
      {
        title: "My Services",
        text: "AKA: The heart of your business. This is where you’ll load in the services, programs, and packages you offer clients.",
        link: "/coach/services",
        linkId: "services",
        complete: nextStepsData?.has_created_services,
        step: 2,
        icon: <SvgBusiness />,
      },
      {
        title: "My Profile Page",
        text: "Go ahead, show off a little bit. This is where you’ll create and make updates to your public profile.",
        link: "/coach/profile",
        linkId: "profile",
        complete: profileAccessStatus === "approved" ? 1 : 0,
        step: 3,
        profileAccessStatus: profileAccessStatus,
        icon: <SvgCredentials />,
      },
      {
        title: "Get Paid",
        text: " This is where you’ll sync your bank account and view payments from Zoee to you–the super awesome and successful coach.",
        link: "/coach/client-payments",
        linkId: "payments",
        complete: nextStepsData?.is_bank_connected,
        icon: <SvgBilling />,
      },
      {
        title: "My Contacts",
        text: "Get ready to send some invitations! Once your clients are in Zoee, you'll be off to the races.",
        link: "/coach/contacts",
        linkId: "contacts",
        complete: nextStepsData?.has_sent_invites,
        icon: <SvgUser3 />,
      },
      {
        title: "My Communication Hub",
        text: "This is where the fun starts. Go here to chat with your clients.",
        link: "/coach/inbox",
        linkId: "inbox",
        complete: nextStepsData?.has_sent_chats,
        icon: <SvgChat />,
      },
    ]
    setBusinessLinks(businessLinksArray)
  }

  const getNextStepsValues = async () => {
    await UserInfo.getNextStepsValues({
      user_id: parseInt(user.user_id),
    })
      .then((data) => {
        populateBusinessLinks(data.next_steps_data)
        setProfileAccessStatus(data.next_steps_data.profile_access_status)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const profileLinkClicked = e.currentTarget.id.includes("profile")
    const verifivationLinkClicked = e.currentTarget.id.includes("verification")
    if (profileLinkClicked) {
      if (profileAccessStatus === "blocked") {
        e.preventDefault()
        setTryProfileFeature(true)
      }
    }
    if (verifivationLinkClicked) {
      if (
        connectAccountData &&
        connectAccountData?.identity_status !== "verified"
      ) {
        setShowVerificationModal(!showVerificationModal)
      }
    }
  }

  const acceptCodeOfEthics = () => {
    updateNextStepsData(false, "approved").then(() => {
      const url = `https://zoee-downloads-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/CodeOfEthicsZoee.pdf`
      window.open(url, "_blank")
      navigate("/coach/profile", { state: { code_of_ethics_accepted: true } })
    })
  }

  useScrollToTop()

  const handleClientSwitch = async () => {
    let activeProfile = null
    let activeProfileId
    startLoading()
    if (user.activeProfile === "member") {
      await endActiveSession()
        .then(() => {
          activeProfile = "coach"
          activeProfileId = user.coachProfile.profile_id
        })
        .catch((err: any) => {
          console.log(err)
        })
        .finally(() => {
          stopLoading()
        })
    } else {
      await endActiveSession()
        .then(() => {
          activeProfile = "member"
          activeProfileId = user.memberProfile.profile_id
        })
        .catch((err: any) => {
          console.log(err)
        })
        .finally(() => {
          stopLoading()
        })
    }
    const userObject = {
      ...user,
      activeProfile: activeProfile,
      activeProfileId: activeProfileId,
    }
    if (isLocalStorageAvailable()) {
      localStorage.setItem("user", JSON.stringify(userObject))
    }
    setUser(userObject)
    const streamClientId = process.env.REACT_APP_STREAM_CLIENT_ID
    if (streamClientId) {
      const client: any = StreamChat.getInstance(streamClientId)
      client.disconnectUser()
    }
    setPopupNotification({
      show: true,
      title: `Welcome to your ${
        user.activeProfile === "member" ? "Coach" : "Member"
      } profile!`,
      message: `${
        user.activeProfile === "member"
          ? "Here, you can interact with all of your Zoee clients. Feel free to switch back to your member profile at any time."
          : "Here, you can interact with all of your Zoee coaches. Feel free to switch back to your coach profile at any time."
      }`,
      callback: null,
    })
  }

  React.useEffect(() => {
    getCoachPrivateProfile()
    setUserActiveSubscription()
    getConnectAccount()
  }, [])

  React.useEffect(() => {
    getNextStepsValues()
    if (location?.state?.verifyPhone === true) {
      sendVerifyPhone()
    } else if (location?.state?.violated_profile_access) {
      renderError(
        `Please accept Code of Ethics to get access to this feature. It's currently in beta.`
      )
    }
  }, [profileAccessStatus])

  React.useEffect(() => {
    const link = document.createElement("link")
    link.href = "https://assets.calendly.com/assets/external/widget.css"
    link.rel = "stylesheet"
    document.head.appendChild(link)

    const script = document.createElement("script")
    script.src = "https://assets.calendly.com/assets/external/widget.js"
    script.async = true
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(link)
      document.head.removeChild(script)
    }
  }, [])

  const initializeCalendly = () => {
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/hello-zoee/group-orientation",
    })
  }

  return (
    <>
      {/* Modals */}
        <OnboardingCallModal
        />
      {tryProfileFeature && (
        <CodeOfEthicsModal
          setShowModal={setTryProfileFeature}
          confirmCallback={acceptCodeOfEthics}
        />
      )}

      {/* Removed verification for now  */}
      {/* Verify Identity with Stripe */}
      {/* {showVerificationModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="zoee-backdrop-filter fixed left-0 top-0 z-[801] flex
                      h-screen w-screen items-center justify-center pb-[88px] md:pb-[40px]"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="relative flex h-auto max-w-xl flex-col items-center rounded-[16px] bg-white px-4 py-12 md:px-16"
          >
            <CloseModal
              callback={() => setShowVerificationModal(false)}
              styling="absolute top-[24px] right-[24px]"
            /> */}
      {/* <div className="flex flex-col items-center justify-between gap-[8px] md:flex-row md:gap-[24px]">
              <div
                className="card-hover-shadow relative mr-[10px] mt-4 flex min-h-[180px] w-full
                          flex-col items-center justify-between gap-[16px] rounded-[12px] bg-white px-[32px] py-[30px] md:mr-0 md:h-[265px] md:px-[14px]"
              >
                <div className="mx-auto flex flex-col justify-center">
                  {connectAccountData.identity_status === "requires_input" &&
                  connectAccountData.identity_last_error ? (
                    <></>
                  ) : (
                    <div className="mb-[8px] flex flex-col items-center">
                      <SvgVerifyIdentity className="h-[40px] w-[40px] md:h-auto md:w-auto" />
                    </div>
                  )}
                  <div className="flex max-w-[326px] flex-col items-center gap-[4px]">
                    <h3 className="text-center text-base font-bold lg:text-[16px]">
                      Verify Your Identity
                    </h3>
                    {connectAccountData.identity_status === "incomplete" ? (
                      <p className="text-graySlate text-center text-base md:text-base">
                        Confirm your identity to prevent fraud, streamline risk
                        operations, and increase trust and safety.
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {/* Stripe Identity verify step */}
      {/* {
                  <StripeVerifyIdentityButton
                    connectAccountData={connectAccountData}
                  />
                }
              </div>
            </div>
          </motion.div>
        </motion.div>
      )} */}

      {/* TURNED OFF
      {Boolean(
        coachTags && coachTags.length === 0 && tagModalOpen &&
          profileAccessStatus === "approved",
      ) && (
        <TagsModal
          isBusinessPage
          setTagsUpdated={setTagsUpdated}
          setTagsModalOpen={setTagModalOpen}
        />
      )} */}

      {isModalOpen && (
        <>
          <link
            href="https://assets.calendly.com/assets/external/widget.css"
            rel="stylesheet"
          />
          <script
            src="https://assets.calendly.com/assets/external/widget.js"
            type="text/javascript"
            async
          ></script>
        </>
      )}

      {/* Begin Page */}

      <div className="container max-w-screen-xl">
        {/* <button onClick={goBackToMobileNav} className="py-3 md:hidden mb-10">
          <img src={chevron} alt="back button" className="rotate-180" />
        </button> */}

        {/* <div className="flex flex-col justify-between gap-x-10 md:flex-row">
          <h1 className="mb-2 text-3xl font-bold md:text-5xl">
            Welcome to Zoee, {user?.firstName ? user?.firstName : user?.email}!
          </h1>

          <div className="flex flex-col gap-2 md:items-center md:justify-center">
            <p className="font-bold">Not sure where to start?</p>
            <Button
              variant="outline"
              onClick={() => {
                setIsModalOpen(!isModalOpen)
                initializeCalendly()
              }}
            >
              <SvgPhone fill="#4750F5" />
              Schedule Onboarding Call
            </Button>
          </div>
        </div> */}

        <h2 className="mb-[8px] mt-6 text-lg font-bold text-black md:mt-0 md:text-xl lg:text-2xl">
          Start building your business in these easy steps:
        </h2>

        <ol className="mt-3 list-inside list-decimal font-bold md:mt-0">
          {/* <li>Verify Identity</li> */}
          <li>Sync Calendar</li>
          <li>Create and publish your first service</li>
        </ol>

        <p className="text-muted-foreground">
          When complete, view your service on "My Profile Page"
        </p>

        <div className="mb-[60px] mt-[45px] grid grid-cols-12 gap-4">
          {!businessLinks.length ? (
            <div className="mx-auto">
              <Loader />
            </div>
          ) : (
            <>
              {businessLinks?.map((link, idx: number) => {
                return (
                  <BusinessLink
                    index={idx}
                    title={link.title}
                    text={link.text}
                    link={link.link}
                    key={idx}
                    complete={link.complete}
                    icon={link.icon}
                    linkId={link.linkId}
                    handleLinkClick={handleLinkClick}
                    profileAccessStatus={profileAccessStatus}
                    connectAccountData={connectAccountData}
                    step={link.step}
                  />
                )
              })}
            </>
          )}
        </div>
      </div>
      {user.isCoach && (
        <div className="container mb-24 flex flex-col items-center justify-center gap-4 sm:flex-row">
          <p className="text-graySlate text-center font-bold">
            Want to see the client experience? Switch to client role and give it
            a test drive!
          </p>
          <Button onClick={handleClientSwitch}>
            <SvgSwap />
            Switch to Client
          </Button>
        </div>
      )}

      <div className="mx-auto flex flex-col items-center px-[24px] pt-[12px] md:w-[1080px] md:px-0 md:pt-0">
        <div className="flex w-full flex-col">
          {/* <button
              className="flex text-blurple mr-auto font-bold mb-[36px] items-center gap-[8px]"
              onClick={scrollToScheduler}
            >
              Schedule Demo
              <ArrowIcon stroke="#4750F5" />
            </button> */}
          {/* <div className="justify-center md:max-w-[1200px] w-full px-[10x] mx-auto">
              {!phoneVerified && (
                <div
                  className="w-full main-shadow p-[16px] md:p-[32px] rounded-[16px]
                            flex md:flex-row flex-col items-center justify-between "
                >
                  <div className="flex items-center gap-[20px]">
                    <img
                      className="fill-primaryBlue"
                      src={verifyPhoneBlack}
                      alt="Phone Icon"
                    />
                    <div className="flex flex-col gap-[9px]">
                      <div className="flex flex-col">
                        <h3 className="font-bold text-[18px]">
                          Verify your phone number to stay connected!
                        </h3>
                        <p className="text-graySlate text-16">
                          Please check your text messages and follow the
                          instructions to verify your phone number.
                          <br />
                          SMS is a great way to stay connected and update your
                          clients in real time!
                        </p>
                      </div>
                    </div>
                  </div>
                  <button
                    className="text-blurple font-bold items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      resendCode();
                    }}
                  >
                    Resend SMS Verification
                  </button>
                </div>
              )}
            </div> */}
        </div>
      </div>
    </>
  )
}

export default ManageBusiness
