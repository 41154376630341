import React, { FC } from "react";
import ContactsList from "../../components/contacts/ContactsList";
import ContactFilters from "../../components/contacts/ContactFilters";
import ContactInvite from "../../components/contacts/ContactInvite";
import ConfirmContactInvite from "../../components/contacts/ConfirmContactInvite";
import ContactRequestFull from "../../components/contacts/ContactRequestFull";
import MobileContactsFilters from "../../components/contacts/MobileContactsFilters";
import { UserCtx } from "../../context/userContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { ContactRequests } from "../../api/app.service";
import { NotificationRequests } from "../../api/app.service";
import { NotificationCtx } from "../../context/notificationContext";
import useScrollToTop from "../../hooks/useScrollToTop";
import { Button } from "../../components/ui/button";
import Loader from "../../components/ui/loader";
import useLoading from "../../hooks/useLoading";
import { SvgEditorListBulleted, SvgFilter, SvgGrid, SvgSearch, SvgUserPlus } from "../../components/icons/";
import { Badge } from "../../components/ui/badge";

interface Props {
    setShowContactProfile: React.Dispatch<React.SetStateAction<boolean>>;
}

const MemberContacts: FC<Props> = ({ setShowContactProfile }) => {
    const [contacts, setContacts] = React.useState<any>([]);
    const [, setSelectedContact] = React.useState<any>(null);
    const [showAllContacts, setShowAllContacts] = React.useState<boolean>(true);
    const [showZoeeLeads, setShowZoeeLeads] = React.useState<boolean>(false);
    const [showProspective, setShowProspective] = React.useState<boolean>(false)
    const [showReceivedInvites, setShowReceivedInvites] = React.useState<boolean>(false);
    const [showInvited, setShowInvited] = React.useState<boolean>(false);
    const [searchValue, setSearchValue] = React.useState<string>("");
    const [showInviteModal, setShowInviteModal] = React.useState<boolean>(false);
    const [showConfirmInviteModal, setShowConfirmInviteModal] = React.useState<boolean>(false);
    const [contactRequestFull, setContactRequestFull] = React.useState<any>({
        show: false,
        contact: null,
    });
    const { user } = React.useContext(UserCtx);
    const { renderError, renderSuccess } = React.useContext(CommonFunctionCtx);
    const { startLoading, stopLoading } = useLoading();
    const [invites, setInvites] = React.useState<any>([{ email: "" }]);
    const [selectedSort, setSelectedSort] = React.useState<string>("none");
    const [selectedFilter, setSelectedFilter] = React.useState<string>("active");
    const [showContactsMobileListView, setShowContactsMobileListView] =
        React.useState<boolean>(true);
    const [showMobileFilters, setShowMobileFilters] = React.useState<boolean>(false);
    const { contactsNotifications, setContactsNotifications } = React.useContext(NotificationCtx);
    const [loadingContacts, setLoadingContacts] = React.useState<boolean>(false);

    const showComponent = (callback: any) => {
        setShowAllContacts(false);
        setShowReceivedInvites(false);
        setShowInvited(false);
        callback(true);
    };

    const filterConnected = contacts.filter((contact: any) => {
        return contact.contact_type === "connected";
    });

    const filterReceivedInvites = contacts.filter((contact: any) => {
        return contact.contact_type === "requested";
    });

    const filterInvited = contacts.filter((contact: any) => {
        return contact.contact_type === "invited";
    });

    const updateSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const getContacts = () => {
        if (user?.coachProfile || user?.memberProfile) {
            setLoadingContacts(true);
            ContactRequests.getContactList({
                profile_id: parseInt(user.activeProfileId),
                profile_type: user.activeProfile,
            })
                .then((data) => {
                    setContacts(data.contact_list);
                    setLoadingContacts(false);
                    stopLoading();
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                    setLoadingContacts(false);
                });
        }
    };

    const acceptContactRequest = (contact: any) => {
        if (user?.coachProfile || user?.memberProfile) {
            startLoading();
            ContactRequests.acceptContactRequest({
                profile_id: parseInt(user.activeProfileId),
                profile_type: user.activeProfile,
                request_id: parseInt(contact.request_id),
                request_by_profile_id: parseInt(contact.request_by_profile_id),
                user_environment: process.env.REACT_APP_USER_ENVIRONMENT || "",
            })
                .then(() => {
                    getContacts();
                    renderSuccess("Request accepted!");
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                    stopLoading();
                });
        }
    };

    const denyContactRequest = (contact: any) => {
        if (user?.coachProfile) {
            startLoading();
            ContactRequests.denyContactRequest({
                request_id: parseInt(contact.request_id),
            })
                .then(() => {
                    getContacts();
                    renderSuccess("Request denied!");
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                    stopLoading();
                });
        }
    };

    const revokeContactRequest = (contact: any) => {
        if (user?.coachProfile || user?.memberProfile) {
            startLoading();
            ContactRequests.revokeContactRequest({
                request_id: parseInt(contact.request_id),
                request_email: contact.contact_name,
                request_by_profile_id: parseInt(user.activeProfileId),
            })
                .then(() => {
                    getContacts();
                    renderSuccess("Invite revoked!");
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                    stopLoading();
                });
        }
    };

    const resendContactRequest = (contact: any) => {
        if (user?.coachProfile || user?.memberProfile) {
            startLoading();
            ContactRequests.resendContactRequest({
                request_id: parseInt(contact.request_id),
                request_email: contact.contact_name,
                request_by_profile_id: parseInt(user.activeProfileId),
            })
                .then(() => {
                    getContacts();
                    renderSuccess("Invite resent!");
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                    stopLoading();
                });
        }
    };

    const markContactNotificationsAsRead = () => {
        if (contactsNotifications.length === 0) return;

        const unreadNotificationIds = contactsNotifications
            .filter((n: any) => n.viewed === 0)
            .map((n: any) => n.id);
        NotificationRequests.markRead({
            profile_id: parseInt(user.activeProfileId),
            notification_ids: unreadNotificationIds,
        })
            .then(() => {
                const allReadContactsNotifications = contactsNotifications.map((n: any) => {
                    n.viewed = true;
                    return n;
                });
                setContactsNotifications(allReadContactsNotifications);
            })
            .catch((ex) => {
                console.log(ex);
                renderError(ex.response.data.message);
            });
    };

    React.useEffect(() => {
        getContacts();
    }, []);
    
    useScrollToTop();

    return (
        <>
            {showInviteModal && (
                <ContactInvite
                    setShowInviteModal={setShowInviteModal}
                    invites={invites}
                    setInvites={setInvites}
                    setShowConfirmInviteModal={setShowConfirmInviteModal}
                />
            )}

            {showConfirmInviteModal && (
                <ConfirmContactInvite
                    setShowConfirmInviteModal={setShowConfirmInviteModal}
                    invites={invites}
                    setInvites={setInvites}
                    contacts={contacts}
                    getContacts={getContacts}
                />
            )}

            {contactRequestFull.show ? (
                <ContactRequestFull
                    setContactRequestFull={setContactRequestFull}
                    contactRequestFull={contactRequestFull}
                    acceptContactRequest={acceptContactRequest}
                    denyContactRequest={denyContactRequest}
                    resendContactRequest={resendContactRequest}
                    revokeContactRequest={revokeContactRequest}
                />
            ) : (
                <>
                    {/* MOBILE */}
                    <div className="md:hidden bg-[#fcfcfc]">
                        <div className="max-w-[600px] mx-auto px-[20px] flex items-center gap-[16px] w-full justify-center mt-[8px] mb-[12px]">
                            {showContactsMobileListView ? (
                                <SvgGrid
                                    onClick={() =>
                                        setShowContactsMobileListView(
                                            () => !showContactsMobileListView
                                        )
                                    }
                                    className="cursor-pointer"
                                />
                            ) : (
                                <SvgEditorListBulleted
                                    onClick={() =>
                                        setShowContactsMobileListView(
                                            () => !showContactsMobileListView
                                        )
                                    }
                                    className="cursor-pointer"
                                />
                            )}

                            <div className="search-input h-[44px]">
                                <input type="text" onChange={updateSearchValue} />
                                <SvgSearch className="left-[12px] absolute top-[50%] -translate-y-[50%]" />
                            </div>
                            <SvgFilter onClick={() => setShowMobileFilters(() => !showMobileFilters)} />
                        </div>
                    </div>

                    {showMobileFilters && (
                        <MobileContactsFilters
                            selectedSort={selectedSort}
                            setSelectedSort={setSelectedSort}
                            setShowMobileFilters={setShowMobileFilters}
                            showComponent={showComponent}
                            setShowReceivedInvites={setShowReceivedInvites}
                            setShowInvited={setShowInvited}
                            setShowZoeeLeads={setShowZoeeLeads}
                            setShowProspective={setShowProspective}
                            setShowAllContacts={setShowAllContacts}
                            showReceivedInvites={showReceivedInvites}
                            showInvited={showInvited}
                            showZoeeLeads={showZoeeLeads}
                            showProspective={showProspective}
                            showAllContacts={showAllContacts}
                            contacts={contacts}
                            setContacts={setContacts}
                            filterReceivedInvites={filterReceivedInvites}
                            filterInvited={filterInvited}
                        />
                    )}

                    {/* DESKTOP */}
                    <div
                        className={`hidden mt-0 w-full sm:w-[1080px] md:flex flex-col bg-[#fcfcfc] mx-auto z-[200]`}
                    >
                        <div className="w-full flex flex-col md:flex-row justify-between items-center border-b-[1px] border-[#EBEBEB] pb-[24px] mb-[24px]">
                            <h1 className="font-bold text-[36px]">Contacts</h1>
                            <div className="flex items-center justify-between w-[335px] sm:w-auto sm:gap-[12px]">
                                <div className="search-input w-[189px] sm:w-[280px] sm:w-[260px]">
                                    <input type="text" placeholder="Search Contacts" onChange={updateSearchValue} />
                                    <SvgSearch
                                        className="left-[12px] absolute top-[50%] -translate-y-[50%]"
                                    />
                                </div>
                                <div>
                                    <Button
                                        className="text-base w-[134px] sm:w-auto"
                                        onClick={() => setShowInviteModal(!showInviteModal)}
                                    >
                                        <span className="">New Contact</span>
                                        <SvgUserPlus
                                            className="hidden sm:block sm:ml-[10px]"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex flex-col md:flex-row justify-between items-center">
                            <div className="flex mb-[20px] md:mb-0 items-center gap-[8px] text-graySlate text-base overflow-x-auto w-[351px] sm:w-auto font-bold">
                                <button
                                    className={`${
                                        showAllContacts
                                            ? "btn-primary btn-secondary-nav"
                                            : "text-graySlate hover:bg-grayFlash duration-150 rounded-[10px]"
                                    } px-[16px] py-[8px] md:py-[12px] h-[48px]`}
                                    onClick={() => showComponent(setShowAllContacts)}
                                >
                                    My Contacts
                                </button>
                                <button
                                    className={`${
                                        showReceivedInvites
                                            ? "btn-primary btn-secondary-nav"
                                            : "text-graySlate hover:bg-grayFlash duration-150 rounded-[10px]"
                                    } px-[16px] py-[8px] md:py-[12px] h-[48px]`}
                                    onClick={() => {
                                        markContactNotificationsAsRead();
                                        showComponent(setShowReceivedInvites);
                                    }}
                                >
                                    <div className="flex gap-[6px]">
                                        Received Invites
                                        {filterReceivedInvites.length > 0 && (
                                          <Badge variant="alert">
                                            {filterReceivedInvites.length}
                                          </Badge>
                                        )}
                                    </div>
                                </button>
                                <button
                                    className={`${
                                        showInvited
                                            ? "btn-primary btn-secondary-nav"
                                            : "text-graySlate hover:bg-grayFlash duration-150 rounded-[10px]"
                                    } px-[16px] py-[8px] md:py-[12px] h-[48px]`}
                                    onClick={() => showComponent(setShowInvited)}
                                >
                                    <div className="flex gap-[6px] items-center">
                                        Sent Invites
                                        {filterInvited.length > 0 && (
                                          <Badge variant="alert">
                                            {filterInvited.length}
                                          </Badge>
                                        )}
                                    </div>
                                </button>
                            </div>
                            {!showInvited && (
                                <ContactFilters
                                    contacts={contacts}
                                    setContacts={setContacts}
                                    selectedSort={selectedSort}
                                    selectedFilter={selectedFilter}
                                    setSelectedSort={setSelectedSort}
                                    setSelectedFilter={setSelectedFilter}
                                />
                            )}
                        </div>
                    </div>

                    {loadingContacts ? (
                        <div className="flex flex-col items-center gap-[12px] mt-[24px]">
                            <Loader />
                            <h3 className="font-bold text-[16px] mb-[4px] text-center">
                                Looking for your contacts...
                            </h3>
                        </div>
                    ) : (
                        <div className="pt-[32px] md:w-[1080px] mx-auto">
                            {showAllContacts && (
                                <ContactsList
                                    contacts={filterConnected}
                                    searchValue={searchValue}
                                    setSelectedContact={setSelectedContact}
                                    setShowContactProfile={setShowContactProfile}
                                    setShowInviteModal={setShowInviteModal}
                                    type="all"
                                    setContactRequestFull={setContactRequestFull}
                                    acceptContactRequest={acceptContactRequest}
                                    denyContactRequest={denyContactRequest}
                                    revokeContactRequest={revokeContactRequest}
                                    resendContactRequest={resendContactRequest}
                                    showContactsMobileListView={showContactsMobileListView}
                                />
                            )}
                            {showReceivedInvites && (
                                <ContactsList
                                    contacts={filterReceivedInvites}
                                    searchValue={searchValue}
                                    setSelectedContact={setSelectedContact}
                                    setShowContactProfile={setShowContactProfile}
                                    setShowInviteModal={setShowInviteModal}
                                    type="requested"
                                    setContactRequestFull={setContactRequestFull}
                                    acceptContactRequest={acceptContactRequest}
                                    denyContactRequest={denyContactRequest}
                                    revokeContactRequest={revokeContactRequest}
                                    resendContactRequest={resendContactRequest}
                                    showContactsMobileListView={showContactsMobileListView}
                                />
                            )}
                            {showInvited && (
                                <ContactsList
                                    contacts={filterInvited}
                                    searchValue={searchValue}
                                    setSelectedContact={setSelectedContact}
                                    setShowContactProfile={setShowContactProfile}
                                    setShowInviteModal={setShowInviteModal}
                                    type="invited"
                                    setContactRequestFull={setContactRequestFull}
                                    acceptContactRequest={acceptContactRequest}
                                    denyContactRequest={denyContactRequest}
                                    revokeContactRequest={revokeContactRequest}
                                    resendContactRequest={resendContactRequest}
                                    showContactsMobileListView={showContactsMobileListView}
                                />
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default MemberContacts;
