import React from "react";
import { SubscriptionContext } from "./context/subscriptionContext";
import CurrentSubscription from "../../components/subscription/CurrentSubscription";
import UpcomingPayments from "../../components/subscription/UpcomingPayments";
import CurrentPaymentMethod from "../../components/subscription/CurrentPaymentMethod";
import { UserCtx } from "../../context/userContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { SubscriptionCtx } from "../../context/subscriptionContext";
import { SubscriptionRequests, PaymentRequests } from "../../api/app.service";
import SubscriptionReceipts from "../../components/subscription/receipts/SubscriptionReceipts";
import ExpiredTrialPopup from "../../components/subscription/ExpiredTrialPopup";
import SubscriptionPlans from "../../components/subscription/SubscriptionPlans";
import SubscriptionCheckout from "../../components/subscription/SubscriptionCheckout";
import PaymentMethodModal from "../../components/account-settings/payment-methods/PaymentMethodModal";
import useDate from "../../hooks/useDate";
import useLoading from "../../hooks/useLoading";

const Subscription: React.FC = () => {
    const { user } = React.useContext(UserCtx);
    const { iso, now } = useDate()
    const expiration = iso(user.trialExpiration)
    const trialActive = expiration > now;
    const [subscription, setSubscription] = React.useState<any>(null);
    const [hideSecondaryNav, setHideSecondaryNav] = React.useState<boolean>(false);
    const [showSubscription, setShowSubscription] = React.useState<boolean>(true);
    const [selectedSubscription, setSelectedSubscription] = React.useState<any>(null);
    const [showSubscriptionPlans, setShowSubscriptionPlans] = React.useState<boolean>(false);
    const [showSubscriptionCheckout, setShowSubscriptionCheckout] = React.useState<boolean>(false);
    const [showReceipts, setShowReceipts] = React.useState<boolean>(false);
    const [paymentMethods, setPaymentMethods] = React.useState<any>(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<any>(null);
    const [paymentMethodModal, setPaymentMethodModal] = React.useState<any>(false);
    const [showExpiredTrialPopup, setShowExpiredTrialPopup] = React.useState<boolean>(false);
    const [stripeRedirectParams, setStripeRedirectParams] = React.useState<string | null>(null);
    const [options, setOptions] = React.useState<any>({});
    const [plans, setPlans] = React.useState<any>(null);
    const [isSubscriptionUpdate, setIsSubscriptionUpdate] = React.useState<boolean>(subscription ? true : false);
    const [receipts, setReceipts] = React.useState<any[]>([]);
    const {
        renderError, setPopupNotification 
    } = React.useContext(CommonFunctionCtx);
    const { activeSubscription, setUserActiveSubscription } = React.useContext(SubscriptionCtx);

    const { startLoading, stopLoading } = useLoading();

    const createSetupIntent = async () => {
        startLoading();
        if (user) {
            await PaymentRequests.createSetupIntent({
                user_id: parseInt(user.user_id),
            })
                .then((data: any) => {
                    setOptions({ clientSecret: data.stripe_client_secret });
                    setPaymentMethodModal(true);
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                })
                .finally(() => {
                    stopLoading();
                });
        }
    };

    const showComponent = (callback: any) => {
        setHideSecondaryNav(false);
        setShowSubscription(false);
        setShowReceipts(false);
        callback(true);
    };

    const showSubscriptionComponents = (callback: any) => {
        setShowSubscriptionPlans(false);
        setShowSubscriptionCheckout(false);
        callback(true);
    };

    const getSubscription = async () => {
        startLoading();
        await SubscriptionRequests.getActiveSubscription({
            user_id: parseInt(user.user_id),
        })
            .then((data: any) => {
                if(Object.keys(data.active_subscription).length !== 0){
                    setSubscription(data.active_subscription);
                } else {
                    setSubscription(null)
                }
            })
            .catch((ex) => {
                console.log(ex);
                renderError(ex.response.data.message);
            })
            .finally(() => {
                stopLoading();
            });
        
    }

    const getPaymentMethods = async () => {
        await PaymentRequests.getPaymentMethods({
            user_id: parseInt(user.user_id),
        })
            .then((data: any) => {
                setPaymentMethods(data.payment_methods);
            })
            .catch((ex) => {
                console.log(ex);
                renderError(ex.response.data.message);
            })
            .finally(() => {
                stopLoading();
            });
    };

    const clearHistoryState = () => {
        window.history.replaceState(null, "", window.location.pathname);
    };

    const assignSelectedPaymentMethod = (paymentMethods:any[]) => {
        const selectedArray = paymentMethods?.filter((pm:any) => pm?.stripe_payment_method_id === activeSubscription?.stripe_payment_method_id);
        let selected = null;
        if(selectedArray.length === 0){
            selected = paymentMethods[0];
        } else {
            selected = selectedArray[0];
        }
        setSelectedPaymentMethod(selected);
    }
    
    const redirectToCheckout = (params:any) => {
        const array = Object.values(plans);
        const checkout = params.get("checkout");
        const planPriceId = params.get("plan");
        const filtered = array.filter((plan:any) => plan.stripe_price_id === planPriceId);
        if(checkout){
            setSelectedSubscription(filtered[0]);
            showSubscriptionComponents(setShowSubscriptionCheckout);
            window.history.replaceState(null, "", window.location.pathname);
        } else {
            window.history.replaceState(null, "", window.location.pathname);
        }
    }

    const getSubscriptionPlans = () => {
        SubscriptionRequests.getSubscriptionPlans({
            user_id: parseInt(user.user_id),
        })
            .then((data) => {
                const annual = data?.plans?.find((plan:any) => {
                  return plan.recurring_interval === "annual" && plan.is_promotional === false
                })
                const monthly = data?.plans?.find((plan:any) => {
                  return plan.amount === 49
                })
                const lifetime = data?.plans?.find((plan:any) => {
                    return plan.amount === 500 && plan.is_promotional === true && plan.recurring_interval_count !== 2
                })
                const twoYears = data?.plans?.find((plan:any) => {
                    return plan.amount === 500 && plan.is_promotional === true && plan.recurring_interval_count === 2
                })
                setPlans({annual: annual, monthly: monthly, lifetime: lifetime, twoYears: twoYears});
            })
            .catch((ex) => {
                console.log(ex);
                renderError(ex.response.data.message);
            });
    };

    const delayedGetPaymentMethods = () => {
        startLoading();
        setTimeout(async () => {
            getPaymentMethods();
        }, 1000);
    };

    const checkUrlParams = () => {
        startLoading();
        const params = new URLSearchParams(window.location.search);
        const subscribed = params.get("subscribed");
        const updated = params.get("updated");
        const redirectStatus = params.get("redirect_status");
        if (subscribed) {
            if (subscribed === "false") {
                setShowExpiredTrialPopup(true);
                clearHistoryState();
            }
            getPaymentMethods();
            clearHistoryState();
        } else if (updated) {
            setPopupNotification({
                show: true,
                title: "Congratulations!",
                message: "Your coach subscription was updated.",
                callback: getPaymentMethods,
            });
            stopLoading();
            clearHistoryState();
        } else if (redirectStatus) {
            if (redirectStatus === "succeeded") {
                setPopupNotification({
                    show: true,
                    title: "Added Payment Method!",
                    message:
                        "You can now use this method of payment throughout the Zoee application. 🎉",
                    callback: delayedGetPaymentMethods,
                });
                stopLoading();
            } else {
                renderError(
                    "Adding payment method failed. Please check your details and try again."
                );
                stopLoading();
            }
        } else {
            clearHistoryState();
            getPaymentMethods();
        }
    };
  
    const getSubscriptionReceipts = () => {
        startLoading();
        SubscriptionRequests.getSubscriptionReceipts({
          "user_id": parseInt(user.user_id)
        }).then((data) => {
            setReceipts(data.receipts);
          }).catch(ex => {
            console.log(ex);
            renderError(ex.response.data.message);
          }).finally(() => {
            stopLoading();
          })
    }

    const SubscriptionContextValues = {
        hideSecondaryNav,
        setHideSecondaryNav,
        showSubscription,
        setShowSubscription,
        showReceipts,
        setShowReceipts,
        trialActive,
        paymentMethods,
        setPaymentMethods,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        paymentMethodModal,
        setPaymentMethodModal,
        subscription,
        setSubscription,
        showSubscriptionPlans,
        setShowSubscriptionPlans,
        showSubscriptionCheckout,
        setShowSubscriptionCheckout,
        showSubscriptionComponents,
        createSetupIntent,
        options,
        setOptions,
        selectedSubscription,
        setSelectedSubscription,
        plans,
        setPlans,
        isSubscriptionUpdate,
        setIsSubscriptionUpdate,
        getSubscription,
        delayedGetPaymentMethods,
        assignSelectedPaymentMethod,
        stripeRedirectParams,
        setStripeRedirectParams
    }

    React.useEffect(() => {
        if(paymentMethods){
            assignSelectedPaymentMethod(paymentMethods);
        }
    }, [subscription, paymentMethods])

    React.useEffect(() => {
        setUserActiveSubscription();
        getSubscriptionPlans();
        getSubscription().then(() => {
            checkUrlParams();
            getSubscriptionReceipts();
        })
    }, [])
  
    React.useEffect(() => {
        if(plans){
            const params = new URLSearchParams(window.location.search);
            if(params.get("checkout") && params.get("plan")){
                redirectToCheckout(params);
            } else {
                window.history.replaceState(null, "", window.location.pathname);
            }
        }
    }, [plans])

    const resetNewPaymentMethod = () => {
        setPaymentMethodModal(false);
        setStripeRedirectParams("");
    }

    return (
        <SubscriptionContext.Provider value={SubscriptionContextValues}>
            <>
                {showExpiredTrialPopup && (
                    <ExpiredTrialPopup
                        setShowExpiredTrialPopup={setShowExpiredTrialPopup}
                        showSubscriptionComponents={showSubscriptionComponents}
                        setShowSubscriptionPlans={setShowSubscriptionPlans}
                    />
                )}
                {paymentMethodModal && (
                    <PaymentMethodModal
                        callback={resetNewPaymentMethod}
                        options={options}
                        stripeRedirectParams={stripeRedirectParams}
                    />
                )}
                <div
                    className="flex flex-col pt-[12px] md:pt-0 mx-auto w-[90%] max-w-[1200px]"
                >
                    {/* Subscription Navigation */}
                    {!hideSecondaryNav && (
                        <div className="mb-[24px] md:mb-[32px]">
                            <h1 className="hidden md:block font-bold text-[36px]">
                                My Subscription
                            </h1>
                            <div className="flex flex-col sm:flex-row justify-between items-center w-full mt-[24px]">
                                <div
                                    className="flex justify-center md:justify-start items-center gap-[20px] text-graySlate 
                                    text-base font-bold overflow-x-auto w-full md:w-[351px]
                                    mb-[16px] sm:mb-0 mr-auto sm:mr-0"
                                >
                                    <button
                                        className={`${
                                            showSubscription &&
                                            "btn-primary btn-secondary-nav h-[40px] md:h-[48px]"
                                        }`}
                                        onClick={() => showComponent(setShowSubscription)}
                                    >
                                        Current Plan
                                    </button>
                                    <button
                                        className={`${
                                            showReceipts &&
                                            "btn-primary btn-secondary-nav h-[40px] md:h-[48px]"
                                        }`}
                                        onClick={() => showComponent(setShowReceipts)}
                                    >
                                        Plan Receipts
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Subscription page content */}

                    {showSubscription && (
                        <>
                            {showSubscriptionPlans && (
                                <SubscriptionPlans />
                            )}

                            {showSubscriptionCheckout && (
                                <SubscriptionCheckout />
                                
                            )}

                            {(!showSubscriptionPlans && !showSubscriptionCheckout) && (
                                <>
                                    <div className="flex flex-col md:flex-row w-full md:h-[530px] justify-between gap-[40px] mb-8">
                                        {/* Current Subscription */}
                                        <CurrentSubscription />
                                        <div
                                            className="flex flex-col gap-[40px] md:gap-[10px] h-full justify-between w-full md:w-[471px]"
                                        >
                                            {!Boolean(subscription?.promotion_details?.lifetime) && (
                                                <UpcomingPayments />
                                            )}
                                            {subscription && (
                                                <CurrentPaymentMethod />
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </> 
                    )}

                    {showReceipts && (
                        <SubscriptionReceipts receipts={receipts} />
                    )}
                </div>
            </>
        </SubscriptionContext.Provider>
    )
}

export default Subscription;
