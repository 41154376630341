import React, { FC, useState, useContext } from "react"
import ActiveServices from "../../components/services/ActiveServices"
import CreateService from "../../components/services/CreateService"
import UpdateService from "../../components/services/UpdateService"
import GroupVsIndividualSelector from "../../components/services/GroupVsIndividualSelector"
import SyncPrompt from "../../components/services/SyncPrompt"
import { UserCtx } from "../../context/userContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import {
  ServiceRequests,
  ClientPaymentRequests,
  FormRequests,
} from "../../api/app.service"
import ServiceDrafts from "../../components/services/ServiceDrafts"
import ArchivedServices from "../../components/services/ArchivedServices"
import { useLocation } from "react-router-dom"
import { AvailabilityRulesData } from "../../types/availabilityTypes"
import { CalendarRequests } from "../../api/app.service"
import ConnectCalendarWarningCardModal from "../../components/ConnectCalendarWarningCardModal"
import Loader from "../../components/ui/loader"
import useLoading from "../../hooks/useLoading"
import { Button } from "../../components/ui/button"
import PromoCodes from "../../components/services/PromoCodes"
import PromoCodeModalForm from "../../components/services/manage/PromoCodeModalForm"
import DeleteDialogueModal from "../../components/DialogueModal"
import { SvgPlus } from "../../components/icons/"
import { Badge } from "../../components/ui/badge"
import LinkToFormModalForm from "../../components/services/manage/LinkToFormModalForm"
import LinkedForms from "../../components/services/LinkedForms"
import { FormLink } from "../../models/app.interface"

const Services: FC = () => {
  const location = useLocation()
  const [syncPrompt, setSyncPrompt] = useState<any>({
    show: false,
    service: {},
  })
  const [groupVsIndividualSelector, setGroupVsIndividualSelector] =
    useState<boolean>(false)
  const [isGroupService, setIsGroupService] = useState<boolean>(false)
  const [showActiveServices, setShowActiveServices] = useState<boolean>(true)
  const [showServiceDrafts, setShowServiceDrafts] = useState<boolean>(false)
  const [showArchivedServices, setShowArchivedServices] =
    useState<boolean>(false)
  const [showPromoCodes, setShowPromoCodes] = useState<boolean>(false)
  const [showForms, setShowForms] = useState<boolean>(false)
  const [createService, setCreateService] = useState<boolean>(false)
  const [updateService, setUpdateService] = useState<any>({
    show: false,
    service: {},
  })
  const [showNewMeeting, setShowNewMeeting] = React.useState<boolean>(false)
  const [showCalendar, setShowCalendar] = React.useState<boolean>(true)
  const [services, setServices] = useState<any[]>([])
  const { user } = useContext(UserCtx)
  const [connectAccountData, setConnectAccountData] = useState<any>(null)
  const [loadingServices, setLoadingServices] = useState<boolean>(false)
  const [userHasCalendar, setUserHasCalendar] = useState<boolean>(false)
  const [showConnectCalendar, setShowConnectCalendar] = useState<any>(false)
  const [showCalendarNotice, setShowCalendarNotice] = useState<any>(false)
  const [userCalendars, setUserCalendars] = useState<any>({
    profileCount: 0,
    calendars: {},
  })
  const [promoCodes, setPromoCodes] = useState<any[]>([])
  const [createPromoCode, setCreatePromoCode] = useState<boolean>(false)
  const [createFormModal, setCreateFormModal] = useState<boolean>(false)
  const [showDialogueModal, setShowDialogueModal] = useState<boolean>(false)
  const [currentPromoCode, setCurrentPromoCode] = useState<any>(null)
  const [userAvailabilities, setUserAvailabilities] = useState<
    Array<AvailabilityRulesData>
  >([])
  const [showCreateDropMenu, setShowCreateDropMenu] = useState<boolean>(false)
  const [formList, setFromList] = useState<FormLink[]>([])
  const { renderError, updateNextStepsData, renderSuccess } =
    useContext(CommonFunctionCtx)
  const { stopLoading } = useLoading()

  const showComponent = (callback: (statement: boolean) => void) => {
    setShowActiveServices(false)
    setShowServiceDrafts(false)
    setShowArchivedServices(false)
    setShowPromoCodes(false)
    setShowForms(false)
    callback(true)
  }

  const getServices = () => {
    if (user) {
      ServiceRequests.getClientServiceList({
        profile_id: parseInt(user.activeProfileId),
        profile_type: user.activeProfile,
      })
        .then((data) => {
          setServices(data.service_list)
          setLoadingServices(false)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          setLoadingServices(false)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }

  const getAvailabilityRules = async () => {
    if (user?.coachProfile) {
      CalendarRequests.getAvailabilityRules({
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          setUserAvailabilities(data.availability_rules)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
    }
  }

  const getConnectAccount = () => {
    if (user) {
      ClientPaymentRequests.getConnectAccount({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          setConnectAccountData(data.connect_account_data)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
    }
  }

  const fetchUserCalendars = () => {
    if (user) {
      CalendarRequests.getCalendarList({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          if (data.profile_count > 0) {
            setUserCalendars({
              profileCount: data.profile_count,
              calendars: data.calendars,
            })
            setUserHasCalendar(true)
          }
        })
        .catch((ex: any) => {
          renderError(ex.response.data.message)
        })
    }
  }

  const handleCreatePromoCode = (promoCode: any) => {
    const promoObject = {
      promo_code: promoCode.promoCodeName,
      promo_description: promoCode.promoCodeDescription,
      promo_type: promoCode.promoCodeType,
      promo_value: promoCode.promoCodeAmount,
    }

    ServiceRequests.createServicePromoCode({
      user_id: parseInt(user.user_id),
      service_promo_code: promoObject,
    })
      .then(() => {
        setCreatePromoCode(false)
        getPromoCodesList()
        renderSuccess("Added Promo Code!")
      })
      .catch((ex) => {
        renderError(ex.response.data.message)
      })
  }

  const getPromoCodesList = () => {
    ServiceRequests.getServicePromoCodesList({
      user_id: parseInt(user.user_id),
    })
      .then((data) => {
        setPromoCodes(data.user_promo_codes_list)
      })
      .catch((ex) => {
        renderError(ex.response.data.message)
      })
  }

  const deleteServicePromoCode = () => {
    ServiceRequests.deleteServicePromoCode({
      promo_id: currentPromoCode.promo_id,
    })
      .then(() => {
        getPromoCodesList()
        setShowDialogueModal(false)
        renderSuccess("Your promo code was deleted")
      })
      .catch((ex) => {
        renderError(ex.response.data.message)
      })
  }

  const showGroupVsIndividualSelector = () => {
    if (!userHasCalendar) {
      setShowCalendarNotice(true)
    } else {
      setGroupVsIndividualSelector(true)
    }
  }

  const getFormLinksList = () => {
    FormRequests.getFormLinks({
      coach_profile_id: user.coachProfile.profile_id,
    })
      .then((data) => {
        setFromList(data)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
      })
  }

  const deleteSelectedForm = (formId: number) => {
    const usedServiceFormIds = services
      .filter((s) => s.service_details.status === "active")
      .map((s) => s.service_details.serviceLinkedForms)
      .flat()
      .map((f) => f.form_link_id);
    if (usedServiceFormIds.includes(formId)) {
      renderError("This form is used in the active service");
      return;
  }
    FormRequests.deleteFormLink({ form_link_id: formId })
      .then((data) => {
        getFormLinksList()
        renderSuccess(data.message)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
      })
  }

  React.useEffect(() => {
    getAvailabilityRules()
    getConnectAccount()
    getPromoCodesList()
    fetchUserCalendars()
    getFormLinksList()
    if (location?.state?.service) {
      setUpdateService({ show: true, service: location.state.service })
    } else if (location?.state?.serviceType === "consultation") {
      setCreateService(true)
    } else {
      window.scrollTo(0, 0)
      setLoadingServices(true)
      updateNextStepsData(false).then(() => {
        getServices()
      })
    }
  }, [])

  const createBtnDropList = [
    {
      title: "Add Service",
      onClickFn: () => {
        showGroupVsIndividualSelector()
        setShowCreateDropMenu(!showCreateDropMenu)
      },
    },
    {
      title: "Add Promo Code",
      onClickFn: () => {
        setCreatePromoCode(true)
        setShowCreateDropMenu(!showCreateDropMenu)
      },
    },
    {
      title: "Add Form",
      onClickFn: () => {
        setCreateFormModal(true)
        setShowCreateDropMenu(!showCreateDropMenu)
      },
    },
  ]

  const myServicesBtnsDetils = [
    {
      btnText: "Active",
      classModification: showActiveServices,
      showComponentFunction: setShowActiveServices,
      filterParameter: "active",
    },
    {
      btnText: "Drafts",
      classModification: showServiceDrafts,
      showComponentFunction: setShowServiceDrafts,
      filterParameter: "inactive",
    },
    {
      btnText: "Archived",
      classModification: showArchivedServices,
      showComponentFunction: setShowArchivedServices,
      filterParameter: "archived",
    },
    {
      btnText: "Promo Codes",
      classModification: showPromoCodes,
      showComponentFunction: setShowPromoCodes,
    },
    {
      btnText: "Forms",
      classModification: showForms,
      showComponentFunction: setShowForms,
    },
  ]

  interface CreateNewMenuProps {
    contClasses: string
    btnClasses: string
  }

  const CreateNewMenu: FC<CreateNewMenuProps> = ({
    contClasses,
    btnClasses,
  }) => {
    return (
      <div className={`relative ${contClasses}`}>
        <Button
          onClick={() => setShowCreateDropMenu(!showCreateDropMenu)}
          className={btnClasses}
        >
          <SvgPlus />
          Create New
        </Button>
        {showCreateDropMenu && (
          <div
            className="p-1 bg-white border rounded-[5px] w-[145px] 
                      absolute bottom-[-95px] right-[calc(100%/2-72px)] md:bottom-[-98px] md:right-0 text-base"
            onMouseLeave={() => setShowCreateDropMenu(false)}
          >
            <ul>
              {createBtnDropList.map((menuItem) => (
                <li
                  key={menuItem.title}
                  className="hover:bg-[#E9EFF5] rounded-[5px] p-1"
                >
                  <button
                    className="w-[100%]"
                    type="button"
                    onClick={menuItem.onClickFn}
                  >
                    {menuItem.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    )
  }

  interface MyServicesButtonProps {
    btnText: string
    classModification: boolean
    showComponentFunction: (statement: boolean) => void
    filterParameter?: string
  }

  const MyServicesButton: FC<MyServicesButtonProps> = ({
    btnText,
    classModification,
    showComponentFunction,
    filterParameter,
  }) => {
    return (
      <button
        className={`flex items-center gap-[4px] ${
          classModification
            ? "btn-primary btn-secondary-nav px-[12px] md:px-[16px]"
            : "text-graySlate font-bold px-[12px] md:px-[16px] h-[48px] text-base hover:bg-grayFlash duration-150 rounded-[10px]"
        }`}
        onClick={() => showComponent(showComponentFunction)}
      >
        {btnText}
        <Badge variant="outline" className="bg-white">
          {btnText === "Promo Codes"
            ? promoCodes.length
            : btnText === "Forms"
            ? formList.length
            : services.filter(
                (service) => service.service_details.status === filterParameter
              ).length}
        </Badge>
      </button>
    )
  }

  return (
    <>
      {showCalendarNotice && !showConnectCalendar && (
        <ConnectCalendarWarningCardModal
          setShowCalendarNotice={setShowCalendarNotice}
        />
      )}
      {groupVsIndividualSelector && (
        <GroupVsIndividualSelector
          setGroupVsIndividualSelector={setGroupVsIndividualSelector}
          setIsGroupService={setIsGroupService}
          setCreateService={setCreateService}
        />
      )}
      {createPromoCode && (
        <PromoCodeModalForm
          setCreatePromoCode={setCreatePromoCode}
          handleCreatePromoCode={handleCreatePromoCode}
        />
      )}
      {showDialogueModal && (
        <DeleteDialogueModal
          setShowDialogueModal={setShowDialogueModal}
          confirmCallbackFn={deleteServicePromoCode}
          confirmButtonText={"Delete Promo Code"}
          header={
            "Caution! You are about to permanently delete this promo code."
          }
          helpText={"Are you sure you want to deleted this promo code?"}
          isWarningVariant={true}
        />
      )}
      {syncPrompt.show && (
        <SyncPrompt
          setSyncPrompt={setSyncPrompt}
          connectAccountData={connectAccountData}
          service={syncPrompt.service}
        />
      )}
      {createService && (
        <CreateService
          setCreateService={setCreateService}
          setSyncPrompt={setSyncPrompt}
          getServices={getServices}
          showComponent={showComponent}
          setShowActiveServices={setShowActiveServices}
          setShowServiceDrafts={setShowServiceDrafts}
          isGroupService={isGroupService}
          services={services}
          availabilities={userAvailabilities}
          connectAccountData={connectAccountData}
          setLoadingServices={setLoadingServices}
          updateService={updateService}
          setShowNewMeeting={setShowNewMeeting}
          setShowCalendar={setShowCalendar}
          setCreateFormModal={setCreateFormModal}
          createFormModal={createFormModal}
        />
      )}
      {updateService.show && (
        <UpdateService
          updateService={updateService}
          setUpdateService={setUpdateService}
          setSyncPrompt={setSyncPrompt}
          getServices={getServices}
          showComponent={showComponent}
          setShowActiveServices={setShowActiveServices}
          setShowServiceDrafts={setShowServiceDrafts}
          isGroupService={isGroupService}
          services={services}
          availabilities={userAvailabilities}
          connectAccountData={connectAccountData}
          setLoadingServices={setLoadingServices}
          setCreateFormModal={setCreateFormModal}
          createFormModal={createFormModal}
        />
      )}
      {createFormModal && (
        <LinkToFormModalForm
          setCreateFormModal={setCreateFormModal}
          coachProfileId={user.coachProfile.profile_id}
          getFormList={getFormLinksList}
        />
      )}
      {!createService && !updateService.show && (
        <div className="md:w-auto mx-auto max-w-[1080px] flex flex-col pt-[12px] px-[20px] md:pt-0">
          {/* DESKTOP TOP TITLE AND NEW SERVICE BUTTON */}
          <div className=" md:flex items-center w-full justify-between md:mb-[24px]">
            <h1 className="font-bold text-[36px]">My Services</h1>

            <CreateNewMenu
              contClasses="hidden md:flex"
              btnClasses="flex gap-[10px]"
            />
          </div>

          {/* MOBILE NEW SERVICE BUTTON */}
          <CreateNewMenu
            contClasses="md:hidden flex"
            btnClasses="gap-[10px] mb-[2px] w-fit mx-auto"
          />

          {loadingServices ? (
            <div className="flex flex-col items-center gap-[12px] mt-[24px]">
              <Loader />
              <h3 className="font-bold text-[16px] mb-[4px] text-center">
                Looking for your services...
              </h3>
            </div>
          ) : (
            <>
              {/* SERVICE STATUS MENU */}
              <div className="flex flex-wrap mb-[24px] md:mb-[40px] mx-auto md:mx-0 items-center gap-0 md:gap-[8px] text-gray text-base md:text-[16px] overflow-x-auto">
                {myServicesBtnsDetils.map((btnDet) => (
                  <MyServicesButton key={btnDet.btnText} {...btnDet} />
                ))}
              </div>

              {showActiveServices && (
                <ActiveServices
                  services={services}
                  setUpdateService={setUpdateService}
                  getServices={getServices}
                  showComponent={showComponent}
                  setShowArchivedServices={setShowArchivedServices}
                  setIsGroupService={setIsGroupService}
                  showGroupVsIndividualSelector={showGroupVsIndividualSelector}
                />
              )}
              {showServiceDrafts && (
                <ServiceDrafts
                  services={services}
                  setUpdateService={setUpdateService}
                  getServices={getServices}
                  showComponent={showComponent}
                  setShowArchivedServices={setShowArchivedServices}
                  setIsGroupService={setIsGroupService}
                />
              )}
              {showArchivedServices && (
                <ArchivedServices
                  services={services}
                  setUpdateService={setUpdateService}
                  getServices={getServices}
                  showComponent={showComponent}
                  setShowArchivedServices={setShowArchivedServices}
                  setIsGroupService={setIsGroupService}
                />
              )}
              {showPromoCodes && (
                <PromoCodes
                  promoCodes={promoCodes}
                  setCreatePromoCode={setCreatePromoCode}
                  setDeletePromoCode={setShowDialogueModal}
                  setCurrentPromoCode={setCurrentPromoCode}
                />
              )}
              {showForms && (
                <LinkedForms
                  formList={formList}
                  deleteForm={deleteSelectedForm}
                  setCreateFormModal={setCreateFormModal}
                  getFormList={getFormLinksList}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default Services
