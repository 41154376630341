import React from "react";
import { UserCtx } from "../../context/userContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { ServiceRequests } from "../../api/app.service";
import CloseModal from "../CloseModal";
import useLoading from "../../hooks/useLoading";

type ComponentType = "Session" | "Program";

type Props = {
    setShowSelectModal: any;
    sessionOrProgram: ComponentType;
    contact: any;
    setSelectedProgram: any;
};

const SelectSessionOrProgramModal: React.FC<Props> = ({
    sessionOrProgram,
    setShowSelectModal,
    contact,
    setSelectedProgram
}) => {
    const { user } = React.useContext(UserCtx);
    const { renderError } = React.useContext(CommonFunctionCtx);
    const { startLoading, stopLoading } = useLoading();
    const [services, setServices] = React.useState<any[]>([]);

    const getProfileServiceList = () => {
        if (user) {
            startLoading();
            ServiceRequests.getProfileServiceList({
                coach_profile_id: parseInt(user.activeProfile === "coach" ? user.activeProfileId : contact.profile_id),
                member_profile_id: parseInt(user.activeProfile === "coach" ? contact.profile_id : user.activeProfileId),
            })
                .then((data) => {
                    const filteredServices = data.service_list.filter((service:any) => ["active", "past"].includes(service.service_status))
                    setServices(filteredServices);
                    stopLoading();
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                    stopLoading();
                });
        }
    };

    React.useEffect(() => {
        getProfileServiceList();
    }, [])
    
    return (
        <div className="z-[699] absolute top-0 left-0 backdrop-contrast-[.25] w-full h-full min-h-screen">
            <div className="z-[700] absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-[730px] h-[538px] box-shadow-2 bg-white rounded-[16px] p-[32px]">
                <CloseModal callback={() => setShowSelectModal(false)} styling="absolute top-[24px] right-[24px]" />
                <h4 className="mb-[40px] text-[22px] font-bold text-center">
                    Select {sessionOrProgram}
                </h4>
                <div className="relative h-[408px] overflow-y-auto">
                    {sessionOrProgram === "Session" &&
                        services.map((s) => {
                            return <SessionCard sessionName="Session Name" />;
                        })}

                    {sessionOrProgram === "Program" && 
                        services.map((s) => {
                            return(
                                <ProgramCard
                                    service={s}
                                    setSelectedProgram={setSelectedProgram}
                                    setShowSelectModal={setShowSelectModal}
                                />
                            ) 
                        })}
                </div>
            </div>
        </div>
    );
};

type SessionCardProps = {
    sessionName: string;
    date?: Date;
    startTime?: Date;
    endTime?: Date;
};

const SessionCard: React.FC<SessionCardProps> = ({
    sessionName,
    date,
    startTime,
    endTime,
}) => {
    const [showButton, setShowButton] = React.useState<boolean>(false);

    return (
        <div
            onFocus={() => setShowButton(true)}
            onMouseOver={() => setShowButton(true)}
            onBlur={() => setShowButton(false)}
            onMouseLeave={() => setShowButton(false)}
            className="flex justify-between items-center text-base mb-[24px] last:mb-0"
        >
            <div className="flex flex-col">
                <div className="flex mb-[4px]">
                    <h4 className="font-bold text-black">
                        {sessionName || "Session Name"}
                    </h4>
                    <p className="text-grayCharcoal">
                        &nbsp;ended at{" "}
                        <span className="font-bold text-black">
                            {startTime?.toString() || "10:00 AM"}
                        </span>{" "}
                        - lasted{" "}
                        <span className="font-bold text-black">
                            12 mins
                        </span>
                    </p>
                </div>
                <p className="text-base text-graySlate">
                    {date?.toString() || "May 7, 2022"}&nbsp;
                    {startTime?.toString() || "2:00PM"}
                </p>
            </div>
            {showButton && (
                <button className="px-[20px] py-[4px] rounded-[8px] bg-primaryBlue text-base text-white font-bold">
                    Choose Session
                </button>
            )}
        </div>
    );
};

type ProgramCardProps = {
    service: any;
    setSelectedProgram: any;
    setShowSelectModal: any;
};

const ProgramCard: React.FC<ProgramCardProps> = ({ service, setSelectedProgram, setShowSelectModal }) => {
    const [showButton, setShowButton] = React.useState<boolean>(false);

    const handleSelectProgram = (service:any) => {
        setSelectedProgram(service);
        setShowSelectModal(false);
    }

    return (
        <div
            onFocus={() => setShowButton(true)}
            onMouseOver={() => setShowButton(true)}
            onBlur={() => setShowButton(false)}
            onMouseLeave={() => setShowButton(false)}
            className="flex justify-between items-center text-base"
        >
            <div className="flex items-center p-[14px]">
                <div
                    className="w-[40px] h-[40px] mr-[16px] flex items-center justify-center bg-paleCyanBlue rounded-full"
                >
                    {service.service_details.title.charAt(0)}
                </div>
                <div className="flex flex-col">
                    <h4 className="font-bold text-black">
                        {service.service_details.title || "Program Name"}
                    </h4>
                    {/* <p className="text-grayCharcoal">
                        <span className="text-primaryBlue">
                            10/10
                        </span>
                        &nbsp;people enrolled
                    </p> */}
                </div>
            </div>
            {showButton && (
                <button
                    className="px-[20px] py-[4px] rounded-[8px] bg-primaryBlue text-base text-white font-bold"
                    onClick={() => handleSelectProgram(service)}
                >
                    Choose Program
                </button>
            )}
        </div>
    );
};

export default SelectSessionOrProgramModal;
