import React from "react";
import { useLocation } from "react-router-dom";
import { UserCtx } from "../../../context/userContext";
import { CoachPrivateProfileContext } from "./context/coachPrivateProfileContext";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import ProfileCreateFlow from "../../../components/coach-private-profile/initial-state/ProfileCreateFlow";
import ManageProfile from "../../../components/coach-private-profile/normal-state/ManageProfile";
import {
  ProfileRequests,
  ServiceRequests,
  ClientPaymentRequests,
} from "../../../api/app.service";
import useLoading from "../../../hooks/useLoading";
import { PublicProfileRequests } from "../../../api/public.service";

interface Props {}

const CoachPrivateProfile: React.FC<Props> = (props: any) => {
  const { user } = React.useContext(UserCtx);
  const { renderError, renderSuccess } =
    React.useContext(CommonFunctionCtx);
  const { startLoading, stopLoading } = useLoading();
  const [coachPublicProfileData, setCoachPublicProfileData] =
    React.useState<any>({});
  const [initialState, setInitialState] = React.useState<boolean>(false);
  const [normalState, setNormalState] = React.useState<boolean>(true);
  const [editBannerImage, setEditBannerImage] = React.useState<boolean>(false);
  const [editProfileInformation, setEditProfileInformation] =
    React.useState<boolean>(false);
  const [editprimaryProfileVideo, setEditPrimaryProfileVideo] =
    React.useState<boolean>(false);
  const [createBlogPost, setCreateBlogPost] = React.useState<boolean>(false);
  const [editPortraitVideo, setEditPortraitVideo] =
    React.useState<boolean>(false);
  const [editBlogPost, setEditBlogPost] = React.useState<any>({
    show: false,
    post: {},
  });
  const [editCredentials, setEditCredentials] = React.useState<boolean>(false);
  const [editSocialMedia, setEditSocialMedia] = React.useState<boolean>(false);
  const [profileColor, setProfileColor] = React.useState<string>("C6AB5B");
  const [previewImgUrl, setPreviewImgUrl] = React.useState<string>("");
  const [selectedTile, setSelectedTile] = React.useState<string>("");
  const [selectedTags, setSelectedTags] = React.useState<number[]>([]);
  const [hasProfileVideo, setHasProfileVideo] = React.useState<boolean>(false);
  const [loadingServices, setLoadingServices] = React.useState<boolean>(false);
  const [services, setServices] = React.useState<any[]>([]);
  const [nextStepsData, setNextStepsData] = React.useState<boolean>(false);
  const [connectAccountData, setConnectAccountData] = React.useState<any>(null);
  const [syncPrompt, setSyncPrompt] = React.useState<boolean>(false);
  const [loadingBento, setLoadingBento] = React.useState<boolean>(false);

  const location = useLocation();

  const getCoachPrivateProfile = async () => {
    if (user?.coachProfile) {
      await ProfileRequests.getCoachPrivateProfile({
        coach_profile_id: parseInt(user.coachProfile.profile_id),
      })
        .then((data) => {
          setCoachPublicProfileData(data.coach_public_profile);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
        })
        .finally(() => {});
    }
  };

  const getServices = async () => {
    if (user) {
      setLoadingServices(true);
      await ServiceRequests.getClientServiceList({
        profile_id: parseInt(user.activeProfileId),
        profile_type: user.activeProfile,
      })
        .then((data) => {
          const activeServices = data?.service_list?.filter((service: any) => {
            return ["active"].includes(service.service_details.status);
          });
          setServices(activeServices);
          setLoadingServices(false);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setLoadingServices(false);
        });
    }
  };

  const getConnectAccount = () => {
    if (user) {
      ClientPaymentRequests.getConnectAccount({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          setConnectAccountData(data.connect_account_data);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
        });
    }
  };

  const CoachPrivateProfileContextValues = {
    coachPublicProfileData,
    setCoachPublicProfileData,
    initialState,
    setInitialState,
    normalState,
    setNormalState,
    editBannerImage,
    setEditBannerImage,
    editProfileInformation,
    setEditProfileInformation,
    profileColor,
    setProfileColor,
    editBlogPost,
    setEditBlogPost,
    createBlogPost,
    setCreateBlogPost,
    editPortraitVideo,
    setEditPortraitVideo,
    previewImgUrl,
    setPreviewImgUrl,
    selectedTile,
    setSelectedTile,
    selectedTags,
    setSelectedTags,
    hasProfileVideo,
    setHasProfileVideo,
    editprimaryProfileVideo,
    setEditPrimaryProfileVideo,
    editCredentials,
    setEditCredentials,
    editSocialMedia,
    setEditSocialMedia,
    getCoachPrivateProfile,
    services,
    getServices,
    setServices,
    loadingServices,
    setLoadingServices,
    nextStepsData,
    setNextStepsData,
    connectAccountData,
    syncPrompt,
    setSyncPrompt,
    loadingBento,
    setLoadingBento,
  };

  React.useEffect(() => {
    if (coachPublicProfileData) {
      getServices();
    }
  }, [coachPublicProfileData]);

  React.useEffect(() => {
    getConnectAccount();
    // getCoachPrivateProfile();
    if (location?.state?.code_of_ethics_accepted) {
      renderSuccess("Welcome to Zoee Community!");
    }
  }, []);

  React.useEffect(() => {
    getCoachPrivateProfile();
  }, [loadingBento]);

  return (
    <>
      <CoachPrivateProfileContext.Provider
        value={CoachPrivateProfileContextValues}
      >
        {initialState && <ProfileCreateFlow />}
        {normalState && <ManageProfile />}
      </CoachPrivateProfileContext.Provider>
    </>
  );
};

export default CoachPrivateProfile;
