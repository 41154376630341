import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InfoModal from "../InfoModal";
import { UserCtx } from "../../context/userContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { StreamChat } from "stream-chat";
import ContactAvatar from "../cards/ContactAvatar";
import { SvgBusiness, SvgChevronRight, SvgHelp, SvgIdea, SvgLogout, SvgSettings, SvgStar } from "../icons";
import { isLocalStorageAvailable } from "../../utils/isLocalStorageAvailable";
import { useAuthModal } from "../auth/use-auth-modal";

type Props = {
  setShowMobileNav: any;
};

export default function MobileNav({ setShowMobileNav }: Props) {
  const { setPopupNotification } =
    React.useContext(CommonFunctionCtx);
  const { user, setUser } = React.useContext(UserCtx);
  const [showReferCoachComingSoon, setShowReferCoachComingSoon] =
    React.useState<boolean>(false);
  const userContactObj = {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    has_avatar: user.hasAvatar,
    profile_id: parseInt(user.activeProfileId),
    user_color: user.userColor,
    user_id: parseInt(user.user_id),
  };

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const { logout } = useAuthModal();

  const handleClientSwitch = () => {
    let activeProfile: "coach" | "member" | null = null;
    let activeProfileId;
    if (user.activeProfile === "member") {
      activeProfile = "coach";
      activeProfileId = user.coachProfile.profile_id;
    } else {
      activeProfile = "member";
      activeProfileId = user.memberProfile.profile_id;
    }
    const userObject = {
      ...user,
      activeProfile: activeProfile,
      activeProfileId: activeProfileId,
    };
    if (isLocalStorageAvailable()) {
      localStorage.setItem("user", JSON.stringify(userObject));
    }
    setUser(userObject);
    const streamClientId = process.env.REACT_APP_STREAM_CLIENT_ID;
    if (streamClientId) {
      const client: any = StreamChat.getInstance(streamClientId);
      client.disconnectUser();
    }
    if (pathname === "/nav-mobile") {
      if (user.activeProfile === "coach") {
        navigate("/coach");
      } else {
        navigate("/member");
      }
    }
    setPopupNotification({
      show: true,
      title: `Welcome to your ${
        user.activeProfile === "member" ? "Coach" : "Member"
      } profile!`,
      message: `${
        user.activeProfile === "member"
          ? "Here, you can interact with all of your Zoee clients. Feel free to switch back to your member profile at any time."
          : "Here, you can interact with all of your Zoee coaches. Feel free to switch back to your coach profile at any time."
      }`,
      callback: null,
    });
  };

  const handleSelect = () => {
    setShowMobileNav(false);
  };

  return (
    <>
      {showReferCoachComingSoon && (
        <InfoModal
          setShowModal={setShowReferCoachComingSoon}
          header={`Exciting News! 🎉`}
          helpText={`This feature is currently in the works and will be coming soon.`}
          confirmButtonText="OK"
          isWarningVariant={false}
        />
      )}
      <div className="z-40 top-0 left-0 bg-white min-w-full min-h-screen overflow-scroll">
        <div className="mt-[48px] px-[32px] flex items-center gap-[14px] mb-[32px]">
          <ContactAvatar
            width={48}
            height={48}
            bold={false}
            contact={userContactObj}
            fontSize="text-[18px]"
          />
          <div>
            <h3 className="font-bold">
              {user.firstName} {user.lastName}
            </h3>
            {user.activeProfile === "member" && user.isCoach && (
              <div
                className="text-primaryBlue font-bold cursor-pointer text-base"
                onClick={handleClientSwitch}
              >
                Switch to Coach
              </div>
            )}
            {user.activeProfile === "coach" && (
              <div
                className="text-primaryBlue font-bold cursor-pointer text-base"
                onClick={handleClientSwitch}
              >
                Switch to Member
              </div>
            )}
          </div>
        </div>

        <div className="px-[20px] pb-[120px]">
          {user.activeProfile === "coach" && (
            <div className="flex flex-col">
              <Link
                to="/coach"
                className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                onClick={handleSelect}
              >
                <div className="flex items-center">
                  <SvgBusiness className="mr-[16px]" />
                  <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                    Manage Business
                  </div>
                </div>
                <SvgChevronRight className="" />
              </Link>
              <Link
                to="/coach/subscription"
                className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                onClick={handleSelect}
              >
                <div className="flex items-center">
                  <SvgStar className="mr-[16px]" />
                  <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                    My Subscription
                  </div>
                </div>
                <SvgChevronRight className="" />
              </Link>
              <Link
                to="/affiliate"
                className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                onClick={(e) => {
                  e.preventDefault();
                  setShowReferCoachComingSoon(!showReferCoachComingSoon);
                }}
              >
                <div className="flex items-center">
                  <SvgStar className="mr-[16px]" />
                  <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                    Refer a Coach
                  </div>
                </div>
                <SvgChevronRight className="" />
              </Link>

              <Link
                to="/account-settings"
                className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                onClick={handleSelect}
              >
                <div className="flex items-center">
                  <SvgSettings fill="black" className="mr-[16px]" />
                  <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                    Account settings
                  </div>
                </div>
                <SvgChevronRight />
              </Link>
              <Link
                to="/feature-request"
                className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                onClick={handleSelect}
              >
                <div className="flex items-center">
                  <SvgIdea className="mr-[16px]" />
                  <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                    Feature request
                  </div>
                </div>
                <SvgChevronRight />
              </Link>
              <a
                href="https://intercom.help/zoee/en"
                className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center">
                  <SvgHelp className="mr-[16px] intercom-custom-launcher" />
                  <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                    Help
                  </div>
                </div>
                <SvgChevronRight />
              </a>
              <Link
                to="#"
                className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                onClick={handleSelect}
              >
                <div className="flex items-center">
                  <SvgLogout className="mr-[16px]" />
                  <div
                    className="text-black text-[18px] font-normal group-hover:underline underline-offset-1"
                    onClick={logout}
                  >
                    Log out
                  </div>
                </div>
                <SvgChevronRight className="" />
              </Link>
            </div>
          )}

          {/* MEMBER MOBILE NAV */}
          <div className="px-[20px]">
            {user.activeProfile === "member" && (
              <div className="pb-[24px] flex flex-col">
                <Link
                  to="/account-settings"
                  className="flex items-center group w-full justify-between py-[20px] border-t border-b border-grayMist"
                  onClick={handleSelect}
                >
                  <div className="flex items-center">
                    <SvgSettings fill="black" className="mr-[16px]" />
                    <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                      Account settings
                    </div>
                  </div>
                  <SvgChevronRight className="" />
                </Link>
                <Link
                  to="/feature-request"
                  className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                  onClick={handleSelect}
                >
                  <div className="flex items-center">
                    <SvgIdea className="mr-[16px]" />
                    <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                      Feature request
                    </div>
                  </div>
                  <SvgChevronRight />
                </Link>
                <a
                  href="https://intercom.help/zoee/en"
                  className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="flex items-center">
                    <SvgHelp className="mr-[16px] intercom-custom-launcher" />
                    <div className="text-black text-[18px] font-normal group-hover:underline underline-offset-1">
                      Help
                    </div>
                  </div>
                  <SvgChevronRight />
                </a>
                <Link
                  to="#"
                  className="flex items-center group w-full justify-between py-[20px] border-b border-grayMist"
                  onClick={handleSelect}
                >
                  <div className="flex items-center">
                    <SvgLogout
                      className="mr-[16px]"
                    />
                    <div
                      className="text-black text-[18px] font-normal group-hover:underline underline-offset-1"
                      onClick={logout}
                    >
                      Log out
                    </div>
                  </div>
                  <SvgChevronRight className="" />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
