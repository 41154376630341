import React from "react"
import celebrate from "../../assets/images/celebrate-bg.webp"
import { useNavigate, Link } from "react-router-dom"
import { UserCtx } from "../../context/userContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { useLocation } from "react-router-dom"
import { ServiceRequests } from "../../api/app.service"
import useLoading from "../../hooks/useLoading"
import { Button } from "../ui/button"
import CloseModal from "../CloseModal"
import ContactAvatar from "../contacts/ContactAvatar"
import {
  SvgUser3,
  SvgCalendar,
  SvgWebsite,
  SvgVideoOutline,
  SvgCheckCircleSolid,
  SvgInformationCircle,
  SvgUsersSolid,
  SvgWarning,
} from "../icons"
import useDate from "../../hooks/useDate"
import moment from "moment"

interface Props {
  checkoutSuccessPopup: any
  contact: any
  meetingDetails: {
    startTime: Date
    endTime: Date
  }
  initialSize?: string
}

const CheckoutSuccessPopup: React.FC<Props> = ({
  checkoutSuccessPopup,
  contact,
  meetingDetails,
  initialSize,
}) => {
  const { user } = React.useContext(UserCtx)
  const navigate = useNavigate()
  const { startLoading } = useLoading()
  const [service, setService] = React.useState<any>(null)
  const { renderError } = React.useContext(CommonFunctionCtx)
  const location = useLocation()

  const getUserTimezone = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const longTimezoneName = new Date().toLocaleTimeString("en", {
      timeZoneName: "long",
    })
    const extractedTimezone = longTimezoneName.split(" ")[2]
    const timezoneWithTime = `${extractedTimezone} Time`
    let timezoneDisplay = timezoneWithTime.replace("_", " ")
    timezoneDisplay = timezoneDisplay.replace("/", " & ")
    timezoneDisplay = `${timezoneDisplay} - ${timezone}`
    return timezoneDisplay
  }
  const userTimezone = getUserTimezone()
  const { mdDate } = useDate()

  const getProfileService = () => {
    if (user) {
      ServiceRequests.getProfileServiceList({
        coach_profile_id: parseInt(
          user.activeProfile === "coach"
            ? user.activeProfileId
            : contact.profile_id
        ),
        member_profile_id: parseInt(
          user.activeProfile === "coach"
            ? contact.profile_id
            : user.activeProfileId
        ),
      })
        .then((data) => {
          const serviceIdToMatch = parseInt(location.pathname.split("/")[5])
          const selectedService = data.service_list.find(
            (service: any) => service.service_id === serviceIdToMatch
          )
          setService(selectedService)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
    }
  }

  React.useEffect(() => {
    getProfileService()
  }, [])

  const handleScheduleSessionClick = () => {
    navigate(`/${user.activeProfile}/scheduling`, {
      state: { service, contact },
    })
  }

  const handleStartMessage = () => {
    startLoading()
    window.location.href = `/${user.activeProfile}/inbox?chat=${contact.profile_id}`
  }

  return (
    <div className="zoee-backdrop-filter credentials fixed left-0 top-0 z-[1000] flex h-screen w-screen items-center justify-center">
      <div className="main-shadow fixed left-1/2 flex h-auto max-h-[85vh] w-[90%] max-w-[504px] -translate-x-1/2 flex-col items-center gap-4 overflow-y-auto rounded-xl bg-white px-4 py-10 md:px-10">
        
        <CloseModal
          callback={() => navigate(`/${user.activeProfile}`)}
          styling="absolute top-6 right-8"
        />
        <ContactAvatar
          contact={{
            contact_name: contact.contact_name,
            has_avatar: contact.avatar_url,
            user_color: contact.user_color,
            user_id: contact.user_id,
          }}
          width={80}
          height={80}
          border="rounded-full"
        />
        
        {checkoutSuccessPopup?.state?.meetingScheduled ? (
          <div>
            <h5 className="flex items-center justify-center text-center text-2xl font-bold">
              <SvgCheckCircleSolid className="mr-2" />{" "}
              <span>You are scheduled!</span>
            </h5>
            <div className="text-center">
              <p className="text-muted-foreground mt-2 mb-2 text-[#45474a]">
                A calendar invitation has been sent to your email address.
              </p>
            </div>
            <div className="border-2 border-gray p-4 rounded-lg">
              <div className="text-muted-foreground grid grid-cols-[auto_1fr] gap-3 my-3 text-[#45474a]">
                <SvgUser3 />
                <p>{contact.contact_name}</p>
                
                
                {!checkoutSuccessPopup?.state?.service.is_group_service ?
                <>
                <SvgCalendar />
                <p>
                    {" "}
                    <p>
                      {moment(meetingDetails.startTime).format("h:mm A")} -{" "}
                      {moment(meetingDetails.endTime).format("h:mm  A, dddd")}
                      {", "}
                      <br className="md:hidden" />
                      {mdDate(meetingDetails.startTime)}
                    </p>
                  </p><SvgWebsite /><p>{userTimezone}</p><SvgVideoOutline /><p className="capitalize">
                      Web conferencing <br className="md:hidden" /> details to
                      follow.
                    </p></>
                    :
                    <> 
                     <SvgUsersSolid />
                    <p>{service?.service_details?.title}</p>
                    <SvgInformationCircle />
                    <p> Please save the attached .ics file from the confirmation email to add the event(s) to your calendar. </p>
                    <SvgWarning />
                    <p> Group meetings have been scheduled. To cancel or opt out, please contact your coach.</p>
                    </>
                  }
              </div>
            </div>
          </div>
        ) : (
          <h5 className="text-2xl font-bold">
            {service?.service_details?.type === "consultation"
              ? "Continue to Scheduling"
              : "Purchase Confirmed"}
          </h5>
        )}

        {checkoutSuccessPopup?.state?.meetingScheduled ? (
          <>
            <Button onClick={handleStartMessage} size="lg">
              Message {contact.contact_name}
            </Button>
          </>
        ) :
        <div className="flex flex-col items-center gap-6 text-center">
            <>
              <p>
                You{" "}
                {service?.service_details?.type === "consultation"
                  ? "are confirmed for"
                  : "purchased"}{" "}
                {service?.service_details?.title}. Get started by scheduling
                your{" "}
                {service?.service_details?.type === "consultation"
                  ? "consultation."
                  : "service."}
              </p>
              <Button size="lg" onClick={handleScheduleSessionClick}>
                {service?.service_details?.type === "consultation"
                  ? "Schedule Now"
                  : "Schedule Service"}
              </Button>
            </>
        </div>  
        }
        
      </div>
    </div>
  )
}

export default CheckoutSuccessPopup
