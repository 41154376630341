import { FC, useEffect, useRef, useContext, useState } from "react";
import MeetingPreviewCard from "./MeetingPreviewCard";
import CalendarSelectDropdown from "./CalendarSelectDropdown";
import { FilterType } from "../../../types/schedulingTypes";
import { Meeting } from "../../../types/meetingTypes";
import SessionDetail from "../session-detail/SessionDetail";
import { MeetingRequests } from "../../../api/app.service";
import { UserCtx } from "../../../context/userContext";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import moment from "moment";
import useOutsideClick from "../../../hooks/useOutsideClick";
import NewMeetingDropdown from "../../../components/scheduling/NewMeetingDropdown";
import CustomDayPicker from "../../date-time/CustomDayPicker";
import { Button } from "../../ui/button";
import NewMeeting from "../new-meeting/NewMeeting";
import classes from "../../../pages/dashboard/CoachDashboard/CoachDashboardBento.module.css";
import { SvgChevronDown, SvgPlus } from "../../icons/";

interface Props {
  setHideSecondaryNav: any;
  userHasCalendar: boolean;
  setNewMeetingType: any;
  setShowNewMeeting: any;
  showRescheduleMeeting: boolean;
  setShowRescheduleMeeting: any;
  clickReschedule: boolean;
  setClickReschedule: any;
  clickTodayReschedule: boolean;
  isTodayPage?: boolean;
  isTodayPageCalendar?: boolean;
  isSchedulePage?: boolean;
  setPickerOpen?: any;
  pickerOpen?: any;
  backToContacts: boolean;
  setKeepBackToButton: any;
  keepBackToButton: boolean;
  contact: any;
}

const Calendar: FC<Props> = ({
  setHideSecondaryNav,
  userHasCalendar,
  setNewMeetingType,
  setShowNewMeeting,
  showRescheduleMeeting,
  setShowRescheduleMeeting,
  clickReschedule,
  setClickReschedule,
  clickTodayReschedule,
  isTodayPage,
  isSchedulePage,
  setPickerOpen,
  pickerOpen,
  backToContacts,
  setKeepBackToButton,
  keepBackToButton,
  contact,
}) => {
  const { user } = useContext(UserCtx);
  const h3Ref = useRef<HTMLHeadingElement | null>(null);

  const { renderError } = useContext(CommonFunctionCtx);
  const [activeDate, setActiveDate] = useState<Date>(
    moment(new Date()).startOf("day").tz(user?.timezone?.value)?.toDate()
  );
  const [activeQueryMonth, setActiveQueryMonth] = useState<number | null>(null);
  const [showCalendarSelectDropdown, setShowCalendarSelectDropdown] =
    useState<boolean>(false);
  const [activeSessionDetailViewId, setActiveSessionDetailViewId] = useState<
    number | null
  >(null);
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([
    "zoee-active-profile",
    "zoee-inactive-profile",
    "other",
  ]);
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [meetingDates, setMeetingDates] = useState<moment.Moment[]>([]);
  const [loadingMeetings, setLoadingMeetings] = useState<boolean>(false);
  const ref = useRef(null);
  const [showNewMeetingDropdown, setShowNewMeetingDropdown] =
    useState<boolean>(false);
  const defaultColor = "#4750F5";

  useOutsideClick(ref, () => {
    setShowCalendarSelectDropdown(false);
  });

  const calcDuration = (endTime: any, startTime: any) => {
    const calculated = moment
      .utc(endTime)
      .diff(moment.utc(startTime), "minutes");
    let duration;
    if (calculated >= 1) {
      duration = calculated;
    } else {
      duration = 1;
    }
    return duration;
  };

  const getMeetings = () => {
    if (user) {
      setLoadingMeetings(true);
      const startOfActiveDate = moment(activeDate)
        ?.startOf("day")
        ?.utc()
        ?.tz(user?.timezone?.value)
        ?.toISOString();
      const endOfSixMonths = moment(activeDate)
        ?.add(6, "month")
        ?.endOf("day")
        ?.utc()
        ?.tz(user.timezone.value)
        ?.toISOString();

      MeetingRequests.getMeetings({
        user_id: parseInt(user.user_id),
        profile_id: parseInt(user.activeProfileId),
        from_date: startOfActiveDate,
        to_date: endOfSixMonths,
      })
        .then((data) => {
          const meetings: Array<Meeting> = [];
          data.meetings.forEach((m) => {
            // parse UTC string from db to a JS Date object with time adjusted for user timezone
            let parsedStartTime = moment
              .utc(m.start_time)
              .tz(user.timezone.value)
              .toDate();
            let parsedEndTime = null;
            if (m.end_time) {
              parsedEndTime = moment
                .utc(m.end_time)
                .tz(user.timezone.value)
                .toDate();
            }
            let parsedDate = moment
              .utc(m.start_time)
              .tz(user.timezone.value)
              .toDate();

            const obj: Meeting = {
              id: m.meeting_id,
              inProgress: m.in_progress,
              meetingType: m.meeting_type,
              type: m.type,
              contacts: m.participants,
              meetingName: m.name,
              status: m.status,
              medium: m.medium,
              isCreator: m.is_creator,
              date: parsedDate,
              startTime: parsedStartTime,
              endTime: parsedEndTime,
              duration: calcDuration(m.end_time, m.start_time),
              // TODO need to know what external calendar it is coming from (e.g. google, office365) in order to use different views
              // or decide to only have one color for all non_managed calendars
              // 9/23/2022: For 2.0 launch, will just use the is_managed property to distinguish between Zoee-managed and others. Return to this property in future versions.
              calendar: `${m.is_managed ? "zoee" : "google"}`,
              timezone: user.timezone,
              service_usage_id: m.service_usage_id,
              service_details: m.service_details,
              // TODO backend for notifications not ready yet
              notifications: [],
              belongs_to_profile: m.belongs_to_profile,
              is_managed: m.is_managed,
              stripe_product_id: m.stripe_product_id,
            };

            meetings.push(obj);
          });

          const sortedMeetings = meetings.sort((a: any, b: any) => {
            const bDate: any = new Date(b.startTime);
            const aDate: any = new Date(a.startTime);
            return aDate - bDate;
          });

          setMeetings(sortedMeetings);
          filterMeetings(meetings);
          setMeetingDates(createMeetingDates(sortedMeetings));
          setActiveQueryMonth(moment(activeDate).month());
          setLoadingMeetings(false);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setLoadingMeetings(false);
        });
    }
  };

  const createMeetingDates = (meetings: Meeting[]) => {
    return meetings.map((meeting) => {
      return moment(meeting.date);
    });
  };

  const activeDateIndex = meetings.findIndex(
    (meeting) =>
      meeting.date?.toLocaleDateString("en-US") ===
      activeDate?.toLocaleDateString("en-US")
  );

  const filterMeetings = (meetings: Meeting[]) => {
    if (!meetings) {
      return;
    }
    if (activeFilters.length === 0) {
      setMeetings([]);
    } else if (activeFilters.length === 1) {
      if (activeFilters[0] === "zoee-active-profile") {
        setMeetings(
          meetings.filter(
            (meeting) => meeting.is_managed && meeting.belongs_to_profile
          )
        );
      } else if (activeFilters[0] === "zoee-inactive-profile") {
        setMeetings(
          meetings.filter(
            (meeting) => meeting.is_managed && !meeting.belongs_to_profile
          )
        );
      } else {
        setMeetings(meetings.filter((meeting) => !meeting.is_managed));
      }
    } else if (activeFilters.length === 2) {
      if (
        activeFilters.includes("zoee-active-profile") &&
        activeFilters.includes("zoee-inactive-profile")
      ) {
        setMeetings(meetings.filter((meeting) => meeting.is_managed));
      } else if (
        activeFilters.includes("zoee-active-profile") &&
        activeFilters.includes("other")
      ) {
        setMeetings(
          meetings.filter(
            (meeting) =>
              (meeting.is_managed && meeting.belongs_to_profile) ||
              !meeting.is_managed
          )
        );
      } else if (
        activeFilters.includes("zoee-inactive-profile") &&
        activeFilters.includes("other")
      ) {
        setMeetings(
          meetings.filter(
            (meeting) =>
              (meeting.is_managed && !meeting.belongs_to_profile) ||
              !meeting.is_managed
          )
        );
      }
    } else {
      setMeetings(meetings);
    }
  };

  useEffect(() => {
    getMeetings();
  }, [activeFilters]);

  useEffect(() => {
    if (
      h3Ref.current &&
      activeDateIndex !== 0 &&
      activeDateIndex !== meetings.length - 1
    ) {
      h3Ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    } else if (h3Ref.current) {
      h3Ref.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [activeDateIndex]);

  useEffect(() => {
    if (activeSessionDetailViewId) {
      setHideSecondaryNav(true);
    } else {
      setHideSecondaryNav(false);
    }
  }, [activeSessionDetailViewId, setHideSecondaryNav]);

  return (
    <>
      {isTodayPage && (
        <>
          {!showRescheduleMeeting ? (
            !activeSessionDetailViewId ? (
              <div className="flex flex-col w-full gap-6">
                <div className="hidden xl:block relative -mt-[58px] mb-[11px] ml-[600px]">
                  <button
                    onClick={() =>
                      setShowCalendarSelectDropdown(
                        () => !showCalendarSelectDropdown
                      )
                    }
                    className="flex items-center text-base font-bold"
                  >
                    Calendars
                    <SvgChevronDown className="w-[24px] h-[24px] ml-[8px]" />
                  </button>

                  {showCalendarSelectDropdown && (
                    <div className="absolute top-[32px] right-0 z-[600]">
                      <CalendarSelectDropdown
                        activeFilters={activeFilters}
                        setActiveFilters={setActiveFilters}
                      />
                    </div>
                  )}
                </div>
                <div className="w-full h-full flex flex-col ">
                  <div className="flex w-full flex-col gap-[8px]">
                    <>
                      <div
                        className={`overflow-y-auto h-[380px] md:h-[390px] lg:h-[480px] px-4 ${classes.scrollBar}`}
                      >
                        {meetings.length > 0 ? (
                          meetings?.map((s, index) => {
                            const isUniqueDate =
                              index === 0 ||
                              s.date?.toLocaleDateString("en-US") !==
                                meetings[index - 1]?.date?.toLocaleDateString(
                                  "en-US"
                                );

                            return (
                              <div key={index} className="mt-10 first:mt-0">
                                {isUniqueDate ? (
                                  <h3
                                    ref={
                                      activeDateIndex === index ? h3Ref : null
                                    }
                                    className="flex text-[16px] text-[18px] font-bold mb-6 first:mt-0 mt-10"
                                  >
                                    <br
                                      className={`${
                                        isTodayPage ? "hidden" : "md:hidden"
                                      }`}
                                    />
                                    {s.date?.toLocaleDateString(undefined, {
                                      weekday: "short",
                                      month: "short",
                                      day: "numeric",
                                    })}{" "}
                                  </h3>
                                ) : (
                                  <></>
                                )}
                                <MeetingPreviewCard
                                  isTodayPage={isTodayPage}
                                  key={s.id}
                                  id={s.id}
                                  title={s.meetingName}
                                  // calendar={s.calendar}
                                  // TODO logic for setting active meeting invite
                                  isActiveInvite={false}
                                  status={s.status}
                                  isGuestOnline={true}
                                  startTime={s.startTime}
                                  endTime={s.endTime || null}
                                  setActiveSessionDetailViewId={
                                    setActiveSessionDetailViewId
                                  }
                                  activeSessionDetailViewId={
                                    activeSessionDetailViewId
                                  }
                                  participants={s.contacts}
                                  is_managed={s.is_managed}
                                  medium={s.medium || "other"}
                                  belongs_to_profile={s.belongs_to_profile}
                                  duration={s.duration}
                                  type={s.type}
                                  inProgress={s.inProgress}
                                  setShowRescheduleMeeting={
                                    setShowRescheduleMeeting
                                  }
                                  setClickReschedule={setClickReschedule}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <div className="h-full w-full flex justify-center items-center">
                            <h2 className="text-gray">No Meetings Scheduled</h2>
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                </div>
                {pickerOpen ? (
                  <div className="bg-white">
                    <CustomDayPicker
                      setActiveDate={setActiveDate}
                      meetingDates={meetingDates}
                      pickerOpen={pickerOpen}
                      setPickerOpen={setPickerOpen}
                      dayColor={defaultColor}
                    />
                  </div>
                ) : (
                  <div className="hidden xl:flex flex-col -mt-[530px] ml-[865px] gap-[24px] md:w-[360px] min-w-[330px] w-full lg:justify-end justify-center mb-[80px] sm:mb-0 z-20">
                    <div className="bg-blurple h-4 w-full rounded-t-[20px] min-w-[400px] -ml-[21px]"></div>
                    <CustomDayPicker
                      setActiveDate={setActiveDate}
                      meetingDates={meetingDates}
                      dayColor={defaultColor}
                    />
                  </div>
                )}
              </div>
            ) : (
              <SessionDetail
                meeting={meetings?.find(
                  (s) => s.id === activeSessionDetailViewId
                )}
                setActiveSessionDetailViewId={setActiveSessionDetailViewId}
                getMeetings={getMeetings}
                setNewMeetingType={setNewMeetingType}
                showRescheduleMeeting={showRescheduleMeeting}
                setShowRescheduleMeeting={setShowRescheduleMeeting}
                clickReschedule={clickReschedule}
                setClickReschedule={setClickReschedule}
                clickTodayReschedule={clickTodayReschedule}
                backToContacts={backToContacts}
                contact={contact}
                keepBackToButton={keepBackToButton}
              />
            )
          ) : (
            <>
              {meetings
                ?.filter((s) => s.id === activeSessionDetailViewId)
                .map((s) => (
                  <NewMeeting
                    key={s.id}
                    newMeetingType={s.meetingType}
                    setShowNewMeeting={setShowNewMeeting}
                    setActiveSessionDetailViewId={setActiveSessionDetailViewId}
                    existingMeetingData={s}
                    initialStep="availability"
                    isEditing={true}
                    setNewMeetingType={setNewMeetingType}
                    showRescheduleMeeting={showRescheduleMeeting}
                    setShowRescheduleMeeting={setShowRescheduleMeeting}
                    clickReschedule={clickReschedule}
                    setClickReschedule={setClickReschedule}
                    clickTodayReschedule={clickTodayReschedule}
                    backToContacts={backToContacts}
                    contact={contact}
                    keepBackToButton={keepBackToButton}
                  />
                ))}
            </>
          )}
        </>
      )}
      <>
        {isSchedulePage &&
          !isTodayPage &&
          (!showRescheduleMeeting ? (
            !activeSessionDetailViewId ? (
              <div className="mx-auto md:w-full w-[90vw] md:min-w-[400px] sm:max-w-[none] pb-[64px]">
                <div className="md:px-0 mx-auto max-w-[98%] mt-0 md:mt-[40px] md:max-w-[1112px] w-full flex flex-col items-center md:items-start lg:justify-between lg:flex-row lg:gap-[52px] md:gap-[4px]">
                  <div className="md:w-[620px] w-full">
                    <div className="flex justify-between items-center mb-[8px] md:mb-[24px]">
                      <h3 className="text-[18px] md:text-[22px] font-bold">
                        My Calendar
                      </h3>
                      <div className="md:hidden relative md:px-0">
                        <Button
                          onClick={() =>
                            setShowNewMeetingDropdown(
                              () => !showNewMeetingDropdown
                            )
                          }
                        >
                          New
                          <SvgPlus />
                        </Button>
                        {showNewMeetingDropdown && (
                          <div className="absolute top-[48px] right-0 z-[600]">
                            <NewMeetingDropdown
                              setNewMeetingType={setNewMeetingType}
                              setShowNewMeeting={setShowNewMeeting}
                              setKeepBackToButton={setKeepBackToButton}
                              setClickReschedule={setClickReschedule}
                            />
                          </div>
                        )}
                      </div>

                      {/* DESKTOP CALENDAR DROPDOWN */}
                      <div className="hidden md:block relative">
                        <button
                          onClick={() =>
                            setShowCalendarSelectDropdown(
                              () => !showCalendarSelectDropdown
                            )
                          }
                          className="flex items-center text-base font-bold"
                        >
                          Calendars
                          <SvgChevronDown className="w-[24px] h-[24px] ml-[8px]" />
                        </button>

                        {showCalendarSelectDropdown && (
                          <div className="absolute top-[32px] right-0 z-[600]">
                            <CalendarSelectDropdown
                              activeFilters={activeFilters}
                              setActiveFilters={setActiveFilters}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {/* MOBILE CALENDAR DROPDOWN */}
                    <div className="md:hidden relative w-full flex justify-end mb-[10px]">
                      <button
                        onClick={() =>
                          setShowCalendarSelectDropdown(
                            () => !showCalendarSelectDropdown
                          )
                        }
                        className="flex items-center text-base font-bold"
                      >
                        Calendars
                        <SvgChevronDown className="w-[24px] h-[24px] ml-[8px]" />
                      </button>

                      {showCalendarSelectDropdown && (
                        <div className="absolute top-[32px] right-0 z-[600]">
                          <CalendarSelectDropdown
                            activeFilters={activeFilters}
                            setActiveFilters={setActiveFilters}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className={`overflow-y-auto h-[360px] md:h-[300px] xl:h-[400px] px-4 ${classes.scrollBar}`}
                    >
                      {meetings.length > 0 ? (
                        meetings?.map((s, index) => {
                          const isUniqueDate =
                            index === 0 ||
                            s.date?.toLocaleDateString("en-US") !==
                              meetings[index - 1]?.date?.toLocaleDateString(
                                "en-US"
                              );

                          return (
                            <div key={index} className="mt-10 first:mt-0">
                              {isUniqueDate ? (
                                <h3
                                  ref={activeDateIndex === index ? h3Ref : null}
                                  className="flex text-[16px] text-[18px] font-bold mb-6 first:mt-0 mt-10"
                                >
                                  <br
                                    className={`${
                                      isTodayPage ? "hidden" : "md:hidden"
                                    }`}
                                  />
                                  {s.date?.toLocaleDateString(undefined, {
                                    weekday: "short",
                                    month: "short",
                                    day: "numeric",
                                  })}{" "}
                                </h3>
                              ) : (
                                <></>
                              )}
                              <MeetingPreviewCard
                                isTodayPage={isTodayPage}
                                key={s.id}
                                id={s.id}
                                title={s.meetingName}
                                // calendar={s.calendar}
                                // TODO logic for setting active meeting invite
                                isActiveInvite={false}
                                status={s.status}
                                isGuestOnline={true}
                                startTime={s.startTime}
                                endTime={s.endTime || null}
                                setActiveSessionDetailViewId={
                                  setActiveSessionDetailViewId
                                }
                                activeSessionDetailViewId={
                                  activeSessionDetailViewId
                                }
                                participants={s.contacts}
                                is_managed={s.is_managed}
                                medium={s.medium || "other"}
                                belongs_to_profile={s.belongs_to_profile}
                                duration={s.duration}
                                type={s.type}
                                inProgress={s.inProgress}
                                setShowRescheduleMeeting={
                                  setShowRescheduleMeeting
                                }
                                setClickReschedule={setClickReschedule}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <div className="h-full w-full flex justify-center items-center">
                          <h2 className="text-gray">No Meetings Scheduled</h2>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex-col gap-[24px] md:w-[360px] min-w-[330px] mx-auto w-full flex lg:justify-end justify-center mt-[64px] mb-[20px] sm:mb-0">
                    <CustomDayPicker
                      setActiveDate={setActiveDate}
                      meetingDates={meetingDates}
                      dayColor={defaultColor}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <SessionDetail
                meeting={meetings?.find(
                  (s) => s.id === activeSessionDetailViewId
                )}
                setActiveSessionDetailViewId={setActiveSessionDetailViewId}
                getMeetings={getMeetings}
                setNewMeetingType={setNewMeetingType}
                showRescheduleMeeting={showRescheduleMeeting}
                setShowRescheduleMeeting={setShowRescheduleMeeting}
                clickReschedule={clickReschedule}
                setClickReschedule={setClickReschedule}
                clickTodayReschedule={clickTodayReschedule}
                backToContacts={backToContacts}
                contact={contact}
                keepBackToButton={keepBackToButton}
              />
            )
          ) : (
            <>
              {meetings
                ?.filter((s) => s.id === activeSessionDetailViewId)
                .map((s) => (
                  <NewMeeting
                    key={s.id}
                    newMeetingType={s.meetingType}
                    setShowNewMeeting={setShowNewMeeting}
                    setActiveSessionDetailViewId={setActiveSessionDetailViewId}
                    existingMeetingData={s}
                    initialStep="availability"
                    isEditing={true}
                    setNewMeetingType={setNewMeetingType}
                    showRescheduleMeeting={showRescheduleMeeting}
                    setShowRescheduleMeeting={setShowRescheduleMeeting}
                    clickReschedule={clickReschedule}
                    setClickReschedule={setClickReschedule}
                    clickTodayReschedule={clickTodayReschedule}
                    backToContacts={backToContacts}
                    contact={contact}
                    keepBackToButton={keepBackToButton}
                  />
                ))}
            </>
          ))}
      </>
    </>
  );
};

export default Calendar;
