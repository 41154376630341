import React, { useState } from "react"
import CloseModal from "../../../CloseModal"
import { Button } from "../../../ui/button"
import { ProfileRequests } from "../../../../api/app.service"
import { CoachPrivateProfileContext } from "../../../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext"
import { SocialMediaLink } from "../../../../models/app.interface"
import { getSocialNameAndIcon } from "../../../../utils/getSocialNameandIcon"
import { SvgNewWindow, SvgPlus, SvgTrash } from "../../../icons/"
import { z } from "zod";
import { FieldError } from "../../../ui/field-error"

interface Props {
  links: SocialMediaLink[]
  getSocialMedia: () => void
}

export default function EditSocialMedia({ links, getSocialMedia }: Props) {
  const {
    setEditSocialMedia,
    setEditProfileInformation,
    coachPublicProfileData,
    getCoachPrivateProfile,
  } = React.useContext(CoachPrivateProfileContext)
  const { renderError, renderSuccess } =
    React.useContext(CommonFunctionCtx)

  type NewAndCurrentSocialMedias = SocialMediaLink | Omit<SocialMediaLink, "id">
  const [socialInputs, setSocialInputs] =
    useState<NewAndCurrentSocialMedias[]>(links)
  const [error, setError] = useState<string | undefined>(undefined);

  const deleteSocialLink = (id: number) => {
    ProfileRequests.deleteSocialMedia({
      social_media_id: id,
    })
      .then(() => {
        getCoachPrivateProfile()
        setEditSocialMedia(false)
        getSocialMedia()
      })
      .catch((ex) => {
        renderError(ex?.response?.data?.message)
        setEditSocialMedia(false)
      })
  }

  const socialInputsSchema = z.array(z.object({
    link: z.string()
      .url()
      .min(7, { message: "URL is too short" })
      .max(200, { message: "URL is too long" })
      .refine((value) => /^(https?):\/\//i.test(value), {
        message: 'Please enter a valid URL',
      })
  }))

  const onSave = () => {
    setError(undefined);
    
    const valid = socialInputsSchema.safeParse(socialInputs)

    if (!valid.success) {
      setError(valid.error.errors[0].message)
      return;
    }
    
    const linksToUpdate = socialInputs.filter((social) =>
      social.hasOwnProperty("id")
    ) as SocialMediaLink[]
    const linksToCreate = socialInputs
      .filter((social) => !social.hasOwnProperty("id"))
      .flatMap((item) => item.link) as string[]
    editSocialLinks(linksToUpdate)
    createSocialLinks(
      coachPublicProfileData.coach_public_profile_id,
      linksToCreate
    )
  }

  const createSocialLinks = async (
    coachPublicProfileId: number,
    links: string[]
  ) => {
    ProfileRequests.createSocialMedia({
      coach_public_profile_id: coachPublicProfileId,
      links,
    })
      .then(() => {
        getCoachPrivateProfile()
        setEditSocialMedia(false)
        renderSuccess("Social Media links added")
        getSocialMedia()
      })
      .catch((ex) => {
        renderError(ex.response.data.message)
        setEditSocialMedia(false)
      })
  }

  const editSocialLinks = async (links: SocialMediaLink[]) => {
    ProfileRequests.editSocialMedia(links)
      .then(() => {
        getCoachPrivateProfile()
        setEditSocialMedia(false)
        renderSuccess("Social Media links updated")
        getSocialMedia()
      })
      .catch((ex) => {
        renderError(ex.response.data.message)
        setEditSocialMedia(false)
      })
  }

  const addInput = () => {
    setSocialInputs([...socialInputs, { link: "" }])
  }

  const handleLinkChange = (index: number, newValue: string) => {
    const updatedLinks = [...socialInputs]
    updatedLinks[index] = { ...socialInputs[index], link: newValue }
    setSocialInputs(updatedLinks)
  }

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex flex-col items-center justify-center zoee-backdrop-filter md:z-[801] z-[1009]">
      <div
        className="w-[800px] max-w-[90%] h-[75%] main-shadow rounded-[16px] pt-[50px] px-[16px] md:px-[75px] max-h-[85vh] bg-white fixed flex flex-col items-center overflow-y-auto">
        <CloseModal
          callback={() => setEditSocialMedia(false)}
          styling="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
        />
        <div className="flex flex-col items-center justify-center font-bold mb-12">
          <div className="flex flex-col items-center justify-center font-bold text-center">
            <h1 className="font-normal">Add</h1>
          </div>
          <div className="flex items-center gap-2">
            <SvgNewWindow />
            <h1 className="text-[22px]">External Links</h1>
          </div>
        </div>
        <form className="flex flex-col">
          <div className="flex flex-col gap-[10px] w-full mb-4">
            <FieldError error={error} />
            
            {socialInputs.map((social, index) => (
              <div className="mb-[24px] flex gap-2" key={index}>
                <div className="default-input">
                  <input
                    type="text"
                    value={social.link}
                    onChange={(e) => handleLinkChange(index, e.target.value)}
                    autoComplete="off"
                    autoFocus={true}
                    placeholder="https://example.com"
                  />
                  <div className="overlay">
                    {getSocialNameAndIcon(social.link).name}
                  </div>
                </div>
                {"id" in social && (
                  <div className="flex justify-center items-center">
                    <Button
                      type="button"
                      size="icon"
                      variant="destructive"
                      onClick={() => deleteSocialLink(social.id)}
                    >
                      <SvgTrash />
                    </Button>
                  </div>
                )}
              </div>
            ))}
            {socialInputs.length < 4 && (
              <button
                type="button"
                onClick={addInput}
                className="btn-primary btn-secondary gap-[8px] mb-[20px]"
              >
                <SvgPlus />
                Add New Link
              </button>
            )}
          </div>
        </form>
        <div className="absolute bottom-[35px] flex justify-center items-center">
          <Button
            variant="link"
            size="lg"
            onClick={() => {
              setEditSocialMedia(false)
              setEditProfileInformation(true)
            }}
          >
            Back
          </Button>
          <Button type="submit" size="lg" onClick={onSave}>
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}
