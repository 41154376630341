import axios, { type AxiosResponse } from "axios"
import type {
  AcceptContactRequestType,
  AccessTokenType,
  ActiveSubscriptionResponseType,
  ArchiveClientServiceType,
  CalendarLinkTokenResponseType,
  CalendarListResponseType,
  CancelMeetingType,
  ChangePasswordType,
  CheckUrlAvailabilityResponseType,
  CheckUrlAvailabilityType,
  ClientServiceListResponseType,
  ClientServiceType,
  CoachAutoConnectType,
  CoachProfileIdType,
  CoachProfileVideosResponseType,
  CoachPublicProfileIdType,
  CoachPublicProfileResponseType,
  ConfirmPhoneResponseType,
  ConnectOnboardingLinkResponseType,
  ContactListResponseType,
  CreateContactConnectionType,
  CreateContactRequestType,
  CreateFormLinkType,
  CreateMeetingType,
  CreateNotificationsType,
  CreatePresignedS3UrlResponseType,
  CreatePresignedS3UrlType,
  CreateProspectType,
  CreateQuickMeetingResponseType,
  CreateQuickMeetingType,
  CreateReferralOnCreateAccountType,
  CreateReferralRequestType,
  CreateServiceInviteType,
  CreateServicePromoCodeType,
  CreateSessionTokenResponseType,
  CreateSessionTokenType,
  CreateSocialMedia,
  CreateStreamUserResponseType,
  CreateStreamUserType,
  CreateStripeUserResponseType,
  CreateStripeUserType,
  CreateSubscriptionResponseType,
  CreateSubscriptionType,
  CreateTestimonial,
  CreateUserPromoCodeResponseType,
  CreateUserPromoCodeType,
  CropUserAccountImageType,
  DeleteAvailabilityRule,
  DeleteCalendarType,
  DeleteContactConnectionType,
  DeleteFormLinkType,
  DeleteMemberNoteType,
  DeleteProfileVideoType,
  DeleteProspectType,
  DeleteServiceImageType,
  DeleteServicePromoCodeType,
  DeleteSocialMedia,
  DenyContactRequestType,
  EditAboutType,
  EditBentoBoxType,
  EditDisplayNameType,
  EditEmailResponseType,
  EditEmailType,
  EditFullNameType,
  EditPersonalizeYourPageDetailsType,
  EditPersonalizeYourPageImageType,
  EditPhoneNumberResponseType,
  EditPhoneNumberType,
  EditProfileCredentialsType,
  EditProfileInformationType,
  EditProfileVideoResponseType,
  EditProfileVideoType,
  EditRegionType,
  EditResponseTimeType,
  EditTimezoneType,
  EditUserAccountImageType,
  EditUserColorType,
  EndSessionType,
  FormLink,
  GetAvailability,
  GetAvailabilityResponseType,
  GetAvailabilityRulesResponseType,
  GetClientPaymentsResponseType,
  GetClientPaymentsType,
  GetClientServiceListType,
  GetCoachNotesType,
  GetConnectAccountResponseType,
  GetContactListType,
  GetFormLinksType,
  GetMeetingsResponseType,
  GetMeetingsType,
  GetMemberNotesRepsonseType,
  GetMemberNotesType,
  GetNotificationSettingsResponseType,
  GetNotificationSettingsType,
  GetNotificationsResponseType,
  GetNotificationsType,
  GetParticipantRoleResponseType,
  GetParticipantRoleType,
  GetPersonalizeYourPageDetailsResponseType,
  GetPersonalizeYourPageDetailsType,
  GetProfileClientPaymentsType,
  GetProfileDataResponseType,
  GetProfileServiceListType,
  GetReferralPromoCodeResponseType,
  GetReferralsResponseType,
  GetServicePromoCodesListResponseType,
  GetServicePromoCodesListType,
  GetSessionListResponseType,
  GetSessionListType,
  GetStripeVerificationSessionResponseType,
  GetStripeVerificationSessionType,
  MarkReadUnreadType,
  MarkSessionCompletedType,
  MessageResponseType,
  NextStepsValuesType,
  NoteResponseType,
  NoteType,
  PastSessionDetailResponseType,
  PaymentMethodType,
  PaymentMethodsResponseType,
  PersonalInfoResponseType,
  ProfileIdType,
  PublicProfilePrimaryMediaType,
  PurchaseClientServiceResponseType,
  PurchaseClientServiceType,
  RegisterCalendarType,
  ReorderBlogPostsType,
  ReorderClientServicesType,
  ReorderProfileVideosType,
  ResendContactRequestType,
  RetrieveStripeVerificationSessionResponseType,
  RetrieveStripeVerificationSessionType,
  RevokeContactRequestType,
  RoomIdType,
  SendReviewType,
  ServiceInviteIdType,
  ServiceUsageDetailsType,
  ServiceUsageResponseType,
  SessionUpdateServiceType,
  SetAvailabilityRules,
  SetAvailabilityRulesResponseType,
  SetCalendarNameType,
  SetCalendarSettingsType,
  SetCalendarType,
  SetProfileDataType,
  SetupIntentResponseType,
  SocialMedia,
  SocialMediaLink,
  SubscriptionIdType,
  SubscriptionPlansResponseType,
  SubscriptionReceiptsResponseType,
  TestimonialData,
  Testimonials,
  ToggleProfilePublishedType,
  UpdateClientServiceType,
  UpdateFormLinkType,
  UpdateMeetingType,
  UpdateNextStepsTrackingType,
  UpdateNotificationSettingsType,
  UpdateReferralOnSubscribeType,
  UpdateSubscriptionPaymentMethodType,
  UpdateSubscriptionResponseType,
  UpdateSubscriptionType,
  UserIdType,
  ValidatePromoCodeResponseType,
  ValidatePromoCodeType,
  ValidateServicePromoCodeResponseType,
  ValidateServicePromoCodeType,
  VerifyPhoneCodeType,
  CreateGroupMeetingType,
  UpdateGroupMeetingType,
  CreateClientServiceResponseType,
  CalculatePayouts,
  CalculatePayoutsResponseType
} from "../models/app.interface"
import { MessageResponse } from "stream-chat"
import { isLocalStorageAvailable } from "../utils/isLocalStorageAvailable"
import {
  CoachBlogPostsResponseType,
  CreateBlogPostType,
  DeleteBlogPostType,
  EditBlogPostType,
} from "../models/blog.interface"

const getIdToken = () => {
  let user = null
  if (isLocalStorageAvailable()) {
    user = JSON.parse(localStorage.getItem("user") || "{}")
  }
  return user["id_token"] || ""
}

const createAxiosInstance = ({
  baseURL,
  timeout = 100000,
}: {
  baseURL: string
  timeout?: number
}) => {
  const instance = axios.create({
    headers: {
      Authorization: "",
    },
    baseURL,
    timeout,
  })

  instance.interceptors.request.use((config) => {
    const token = getIdToken()
    if (config.headers) {
      config.headers.Authorization = token ? `${token}` : ""
    }
    return config
  })

  return {
    instance,
    requests: {
      post: async (url: string, body: { [key: string]: any }) =>
        await instance
          .post(url, body)
          .then((response: AxiosResponse) => response.data),
    },
  }
}

const instances = {
  app: createAxiosInstance({ baseURL: process.env.REACT_APP_API! }),
  auth: createAxiosInstance({ baseURL: process.env.REACT_APP_AUTH_API! }),
  meetings: createAxiosInstance({
    baseURL: process.env.REACT_APP_MEETINGS_API!,
  }),
  contacts: createAxiosInstance({
    baseURL: process.env.REACT_APP_CONTACTS_API!,
  }),
  subscription: createAxiosInstance({
    baseURL: process.env.REACT_APP_SUBSCRIPTIONS_API!,
  }),
  client_services: createAxiosInstance({
    baseURL: process.env.REACT_APP_CLIENT_SERVICES_API!,
  }),
}

const {
  app: { requests: app_requests },
  auth: { requests: auth_requests },
  meetings: { requests: meetings_requests },
  contacts: { requests: contact_requests },
  subscription: { requests: subscription_requests },
  client_services: { requests: client_services_requests },
} = instances

// API Calls

export const UserAccounts = {
  createStreamUser: (
    object: CreateStreamUserType
  ): Promise<CreateStreamUserResponseType> =>
    auth_requests.post("createStreamUser", object),
  createStripeUser: (
    object: CreateStripeUserType
  ): Promise<CreateStripeUserResponseType> =>
    auth_requests.post("createStripeUser", object),
}

export const UserInfo = {
  getUser: (object: UserIdType): Promise<PersonalInfoResponseType> =>
    app_requests.post("getUser", object),
  editFullName: (object: EditFullNameType): Promise<MessageResponseType> =>
    app_requests.post("editFullName", object),
  editAbout: (object: EditAboutType): Promise<MessageResponseType> =>
    app_requests.post("editAbout", object),
  editRegion: (object: EditRegionType): Promise<MessageResponseType> =>
    app_requests.post("editRegion", object),
  editDisplayName: (
    object: EditDisplayNameType
  ): Promise<MessageResponseType> =>
    app_requests.post("editDisplayName", object),
  editUserColor: (object: EditUserColorType): Promise<MessageResponseType> =>
    app_requests.post("editUserColor", object),
  editUserAccountImage: (
    object: EditUserAccountImageType
  ): Promise<MessageResponseType> =>
    app_requests.post("editAccountImage", object),
  deleteUserAccountImage: (object: UserIdType): Promise<MessageResponseType> =>
    app_requests.post("deleteAccountImage", object),
  cropUserAccountImage: (
    object: CropUserAccountImageType
  ): Promise<MessageResponseType> =>
    app_requests.post("cropAccountImage", object),
  editEmail: (object: EditEmailType): Promise<EditEmailResponseType> =>
    app_requests.post("editEmail", object),
  editPhoneNumber: (
    object: EditPhoneNumberType
  ): Promise<EditPhoneNumberResponseType> =>
    app_requests.post("editPhoneNumber", object),
  editTimezone: (object: EditTimezoneType): Promise<MessageResponseType> =>
    app_requests.post("editTimezone", object),
  changePassword: (object: ChangePasswordType): Promise<MessageResponseType> =>
    auth_requests.post("changePassword", object),
  getNextStepsValues: (object: UserIdType): Promise<NextStepsValuesType> =>
    app_requests.post("getNextStepsValues", object),
  updateNextStepsValues: (
    object: UpdateNextStepsTrackingType
  ): Promise<MessageResponseType> =>
    app_requests.post("updateNextStepsValues", object),
}

export const ProfileRequests = {
  getCoachPublicProfile: (
    object: CoachPublicProfileIdType
  ): Promise<CoachPublicProfileResponseType> =>
    app_requests.post("getCoachPublicProfile", object),
  getCoachPrivateProfile: (
    object: CoachProfileIdType
  ): Promise<CoachPublicProfileResponseType> =>
    app_requests.post("getCoachPrivateProfile", object),
  getCoachProfile: (
    object: ProfileIdType
  ): Promise<GetProfileDataResponseType> =>
    app_requests.post("getCoachProfile", object),
  setCoachProfile: (object: SetProfileDataType): Promise<MessageResponseType> =>
    app_requests.post("setCoachProfile", object),
  getCoachProfileVideos: (
    object: CoachPublicProfileIdType
  ): Promise<CoachProfileVideosResponseType> =>
    app_requests.post("getCoachProfileVideos", object),
  editProfileVideo: (
    object: EditProfileVideoType
  ): Promise<EditProfileVideoResponseType> =>
    app_requests.post("editProfileVideo", object),
  deleteProfileVideo: (
    object: DeleteProfileVideoType
  ): Promise<MessageResponseType> =>
    app_requests.post("deleteProfileVideo", object),
  reorderProfileVideos: (
    object: ReorderProfileVideosType
  ): Promise<MessageResponseType> =>
    app_requests.post("reorderProfileVideos", object),
  editBannerImage: (
    object: PublicProfilePrimaryMediaType
  ): Promise<MessageResponseType> =>
    app_requests.post("editBannerImage", object),
  deleteBannerImage: (
    object: CoachPublicProfileIdType
  ): Promise<MessageResponseType> =>
    app_requests.post("deleteBannerImage", object),
  editProfileInformation: (
    object: EditProfileInformationType
  ): Promise<MessageResponseType> =>
    app_requests.post("editProfileInformation", object),
  editCredentials: (
    object: EditProfileCredentialsType
  ): Promise<MessageResponseType> =>
    app_requests.post("editCredentials", object),
  editResponseTime: (
    object: EditResponseTimeType
  ): Promise<MessageResponseType> =>
    app_requests.post("editResponseTime", object),
  createBlogPost: (object: CreateBlogPostType): Promise<MessageResponseType> =>
    app_requests.post("createBlogPost", object),
  editBlogPost: (object: EditBlogPostType): Promise<MessageResponseType> =>
    app_requests.post("editBlogPost", object),
  deleteBlogPost: (object: DeleteBlogPostType): Promise<MessageResponseType> =>
    app_requests.post("deleteBlogPost", object),
  reorderBlogPosts: (
    object: ReorderBlogPostsType
  ): Promise<MessageResponseType> =>
    app_requests.post("reorderBlogPosts", object),
  getCoachBlogPosts: (
    object: CoachProfileIdType
  ): Promise<CoachBlogPostsResponseType> =>
    app_requests.post("getCoachBlogPosts", object),
  createSocialMedia: (
    object: CreateSocialMedia
  ): Promise<MessageResponseType> =>
    app_requests.post("createSocialMedia", object),
  getSocialMedia: (object: CoachPublicProfileIdType): Promise<SocialMedia> =>
    app_requests.post("getSocialMedia", object),
  editSocialMedia: (object: SocialMediaLink[]): Promise<SocialMedia> =>
    app_requests.post("editSocialMedia", object),
  deleteSocialMedia: (
    object: DeleteSocialMedia
  ): Promise<MessageResponseType> =>
    app_requests.post("deleteSocialMedia", object),
  createTestimonial: (
    object: CreateTestimonial
  ): Promise<MessageResponseType> =>
    app_requests.post("createTestimonial", object),
  getTestimonials: (object: CoachPublicProfileIdType): Promise<Testimonials> =>
    app_requests.post("getTestimonials", object),
  editTestimonials: (object: TestimonialData): Promise<MessageResponseType> =>
    app_requests.post("editTestimonials", object),
  checkUrlAvailability: (
    object: CheckUrlAvailabilityType
  ): Promise<CheckUrlAvailabilityResponseType> =>
    app_requests.post("checkUrlAvailability", object),
  toggleProfilePublished: (
    object: ToggleProfilePublishedType
  ): Promise<MessageResponseType> =>
    app_requests.post("toggleProfilePublished", object),
  getCoachTags: (): Promise<MessageResponseType> =>
    app_requests.post("getCoachTags", {}),
  editBentoBox: (object: EditBentoBoxType): Promise<MessageResponseType> =>
    app_requests.post("editBentoBox", object),
}

export const VerifyEmail = {
  sendEmailCode: (object: UserIdType): Promise<MessageResponseType> =>
    auth_requests.post("confirmEmailSendLink", object),
}

export const VerifyPhone = {
  sendPhoneCode: (object: AccessTokenType): Promise<ConfirmPhoneResponseType> =>
    auth_requests.post("confirmPhoneSendCode", object),
  verifyPhoneCode: (
    object: VerifyPhoneCodeType
  ): Promise<MessageResponseType> =>
    auth_requests.post("confirmPhoneVerifyCode", object),
}

export const CalendarRequests = {
  getCalendarList: (object: UserIdType): Promise<CalendarListResponseType> =>
    meetings_requests.post("getCalendarList", object),
  registerCalendar: (
    object: RegisterCalendarType
  ): Promise<MessageResponseType> =>
    meetings_requests.post("registerCalendar", object),
  getCronofyLinkToken: (
    object: UserIdType
  ): Promise<CalendarLinkTokenResponseType> =>
    meetings_requests.post("getCronofyLinkToken", object),
  deleteCalendar: (object: DeleteCalendarType): Promise<MessageResponseType> =>
    meetings_requests.post("deleteCalendar", object),
  setPrimaryCalendar: (object: SetCalendarType): Promise<MessageResponseType> =>
    meetings_requests.post("setPrimaryCalendar", object),
  setCalendarSettings: (
    object: SetCalendarSettingsType
  ): Promise<MessageResponseType> =>
    meetings_requests.post("setCalendarSettings", object),
  setCalendarName: (
    object: SetCalendarNameType
  ): Promise<MessageResponseType> =>
    meetings_requests.post("setCalendarName", object),
  getAvailabilityRules: (
    object: UserIdType
  ): Promise<GetAvailabilityRulesResponseType> =>
    meetings_requests.post("getAvailabilityRules", object),
  setAvailabilityRules: (
    object: SetAvailabilityRules
  ): Promise<SetAvailabilityRulesResponseType> =>
    meetings_requests.post("setAvailabilityRules", object),
  deleteAvailabilityRule: (
    object: DeleteAvailabilityRule
  ): Promise<MessageResponseType> =>
    meetings_requests.post("deleteAvailabilityRule", object),
  getAvailability: (
    object: GetAvailability
  ): Promise<GetAvailabilityResponseType> =>
    meetings_requests.post("getAvailability", object),
}

export const NoteRequests = {
  setNote: (object: NoteType): Promise<NoteResponseType> =>
    app_requests.post("setNote", object),
  getMemberNotes: (
    object: GetMemberNotesType
  ): Promise<GetMemberNotesRepsonseType> =>
    app_requests.post("getMemberNotes", object),
  getCoachNotes: (
    object: GetCoachNotesType
  ): Promise<GetMemberNotesRepsonseType> =>
    app_requests.post("getCoachNotes", object),
  deleteNote: (object: DeleteMemberNoteType): Promise<MessageResponse> =>
    app_requests.post("deleteNote", object),
}

export const SessionsRequests = {
  create100msQuickMeeting: (
    object: CreateQuickMeetingType
  ): Promise<CreateQuickMeetingResponseType> =>
    meetings_requests.post("create100msQuickMeeting", object),
  create100msSessionToken: (
    object: CreateSessionTokenType
  ): Promise<CreateSessionTokenResponseType> =>
    meetings_requests.post("create100msSessionToken", object),
  get100msSessionList: (
    object: GetSessionListType
  ): Promise<GetSessionListResponseType> =>
    meetings_requests.post("get100msSessionList", object),
  getPastSessions: (
    object: ProfileIdType
  ): Promise<GetSessionListResponseType> =>
    meetings_requests.post("getPastSessions", object),
  getPastSessionDetail: (
    object: RoomIdType
  ): Promise<PastSessionDetailResponseType> =>
    meetings_requests.post("getPastSessionDetail", object),
  sessionAddService: (
    object: SessionUpdateServiceType
  ): Promise<MessageResponseType> =>
    meetings_requests.post("sessionAddService", object),
  sessionUpdateService: (
    object: SessionUpdateServiceType
  ): Promise<MessageResponseType> =>
    meetings_requests.post("sessionUpdateService", object),
  sessionDeleteService: (
    object: SessionUpdateServiceType
  ): Promise<MessageResponseType> =>
    meetings_requests.post("sessionDeleteService", object),
  sessionRemoveParticipant: (
    object: RoomIdType
  ): Promise<MessageResponseType> =>
    meetings_requests.post("sessionRemoveParticipant", object),
  endSession: (object: EndSessionType): Promise<MessageResponseType> =>
    meetings_requests.post("endSession", object),
  getParticipantRole: (
    object: GetParticipantRoleType
  ): Promise<GetParticipantRoleResponseType> =>
    meetings_requests.post("getParticipantRole", object),
  updateSendReview: (object: SendReviewType): Promise<MessageResponseType> =>
    meetings_requests.post("updateSendReview", object),
}

export const ContactRequests = {
  getContactList: (
    object: GetContactListType
  ): Promise<ContactListResponseType> =>
    contact_requests.post("getContactList", object),
  createContactRequest: (
    object: CreateContactRequestType
  ): Promise<MessageResponseType> =>
    contact_requests.post("createContactRequest", object),
  acceptContactRequest: (
    object: AcceptContactRequestType
  ): Promise<MessageResponseType> =>
    contact_requests.post("acceptContactRequest", object),
  denyContactRequest: (
    object: DenyContactRequestType
  ): Promise<MessageResponseType> =>
    contact_requests.post("denyContactRequest", object),
  revokeContactRequest: (
    object: RevokeContactRequestType
  ): Promise<MessageResponseType> =>
    contact_requests.post("revokeContactRequest", object),
  resendContactRequest: (
    object: ResendContactRequestType
  ): Promise<MessageResponseType> =>
    contact_requests.post("resendContactRequest", object),
  coachAutoConnect: (
    object: CoachAutoConnectType
  ): Promise<MessageResponseType> =>
    contact_requests.post("coachAutoConnect", object),
  createContactConnection: (
    object: CreateContactConnectionType
  ): Promise<MessageResponseType> =>
    contact_requests.post("createContactConnection", object),
  deleteContactConnection: (
    object: DeleteContactConnectionType
  ): Promise<MessageResponseType> =>
    contact_requests.post("deleteConnection", object),
  createProspect: (object: CreateProspectType): Promise<MessageResponseType> =>
    contact_requests.post("createProspect", object),
  deleteProspect: (object: DeleteProspectType): Promise<MessageResponseType> =>
    contact_requests.post("deleteProspect", object),
}

export const PaymentRequests = {
  createSetupIntent: (object: UserIdType): Promise<SetupIntentResponseType> =>
    subscription_requests.post("createSetupIntent", object),
  getPaymentMethods: (
    object: UserIdType
  ): Promise<PaymentMethodsResponseType> =>
    subscription_requests.post("getPaymentMethods", object),
  deletePaymentMethod: (
    object: PaymentMethodType
  ): Promise<MessageResponseType> =>
    subscription_requests.post("deletePaymentMethod", object),
  setDefaultPaymentMethod: (
    object: PaymentMethodType
  ): Promise<MessageResponseType> =>
    subscription_requests.post("setDefaultPaymentMethod", object),
}

export const MeetingRequests = {
  getMeetings: (object: GetMeetingsType): Promise<GetMeetingsResponseType> =>
    meetings_requests.post("getMeetings", object),
  createMeeting: (object: CreateMeetingType): Promise<MessageResponseType> =>
    meetings_requests.post("createMeeting", object),
  updateMeeting: (object: UpdateMeetingType): Promise<MessageResponseType> =>
    meetings_requests.post("updateMeeting", object),
  cancelMeeting: (object: CancelMeetingType): Promise<MessageResponseType> =>
    meetings_requests.post("cancelMeeting", object),
  markSessionCompleted: (
    object: MarkSessionCompletedType
  ): Promise<MessageResponseType> =>
    meetings_requests.post("markSessionCompleted", object),
  createGroupMeeting: (
    object: CreateGroupMeetingType
  ): Promise<MessageResponseType> =>
    meetings_requests.post("createGroupMeeting", object),
  updateGroupMeeting: (
    object: UpdateGroupMeetingType
  ): Promise<MessageResponseType> =>
    meetings_requests.post("updateGroupMeeting", object),
}

export const SubscriptionRequests = {
  getSubscriptionPlans: (
    object: UserIdType
  ): Promise<SubscriptionPlansResponseType> =>
    subscription_requests.post("getSubscriptionPlans", object),
  getActiveSubscription: (
    object: UserIdType
  ): Promise<ActiveSubscriptionResponseType> =>
    subscription_requests.post("getActiveSubscription", object),
  validatePromoCode: (
    object: ValidatePromoCodeType
  ): Promise<ValidatePromoCodeResponseType> =>
    subscription_requests.post("validatePromoCode", object),
  createSubscription: (
    object: CreateSubscriptionType
  ): Promise<CreateSubscriptionResponseType> =>
    subscription_requests.post("createSubscription", object),
  updateSubscription: (
    object: UpdateSubscriptionType
  ): Promise<UpdateSubscriptionResponseType> =>
    subscription_requests.post("updateSubscription", object),
  updateSubscriptionPaymentMethod: (
    object: UpdateSubscriptionPaymentMethodType
  ): Promise<MessageResponseType> =>
    subscription_requests.post("updateSubscriptionPaymentMethod", object),
  getSubscriptionReceipts: (
    object: UserIdType
  ): Promise<SubscriptionReceiptsResponseType> =>
    subscription_requests.post("getSubscriptionReceipts", object),
  cancelSubscription: (
    object: SubscriptionIdType
  ): Promise<MessageResponseType> =>
    subscription_requests.post("cancelSubscription", object),
}

export const ReferralRequests = {
  createUserPromoCode: (
    object: CreateUserPromoCodeType
  ): Promise<CreateUserPromoCodeResponseType> =>
    subscription_requests.post("createUserPromoCode", object),
  createReferralRequest: (
    object: CreateReferralRequestType
  ): Promise<MessageResponseType> =>
    subscription_requests.post("createReferralRequest", object),
  getReferrals: (object: UserIdType): Promise<GetReferralsResponseType> =>
    subscription_requests.post("getReferrals", object),
  createReferralOnCreateAccount: (
    object: CreateReferralOnCreateAccountType
  ): Promise<MessageResponseType> =>
    subscription_requests.post("createReferralOnCreateAccount", object),
  getReferralPromoCode: (
    object: UserIdType
  ): Promise<GetReferralPromoCodeResponseType> =>
    subscription_requests.post("getReferralPromoCode", object),
  updateReferralOnSubscribe: (
    object: UpdateReferralOnSubscribeType
  ): Promise<MessageResponseType> =>
    subscription_requests.post("updateReferralOnSubscribe", object),
  getPersonalizeYourPageDetails: (
    object: GetPersonalizeYourPageDetailsType
  ): Promise<GetPersonalizeYourPageDetailsResponseType> =>
    subscription_requests.post("getPersonalizeYourPageDetails", object),
  editPersonalizeYourPageDetails: (
    object: EditPersonalizeYourPageDetailsType
  ): Promise<MessageResponseType> =>
    subscription_requests.post("editPersonalizeYourPageDetails", object),
  editPersonalizeYourPageImage: (
    object: EditPersonalizeYourPageImageType
  ): Promise<MessageResponseType> =>
    subscription_requests.post("editPersonalizeYourPageImage", object),
    calculatePayouts: (
     object: CalculatePayouts
  ): Promise<CalculatePayoutsResponseType> =>
    subscription_requests.post("calculatePayouts", object),
}

export const ServiceRequests = {
  createClientService: (
    object: ClientServiceType
  ): Promise<CreateClientServiceResponseType> =>
    client_services_requests.post("createClientService", object),
  updateClientService: (
    object: UpdateClientServiceType
  ): Promise<MessageResponseType> =>
    client_services_requests.post("updateClientService", object),
  archiveClientService: (
    object: ArchiveClientServiceType
  ): Promise<MessageResponseType> =>
    client_services_requests.post("archiveClientService", object),
  unarchiveClientService: (
    object: ArchiveClientServiceType
  ): Promise<MessageResponseType> =>
    client_services_requests.post("unarchiveClientService", object),
  getClientServiceList: (
    object: GetClientServiceListType
  ): Promise<ClientServiceListResponseType> =>
    client_services_requests.post("getClientServiceList", object),
  getServiceUsageDetails: (
    object: ServiceUsageDetailsType
  ): Promise<ServiceUsageResponseType> =>
    client_services_requests.post("getServiceUsageDetails", object),
  getProfileServiceList: (
    object: GetProfileServiceListType
  ): Promise<ClientServiceListResponseType> =>
    client_services_requests.post("getProfileServiceList", object),
  deleteServiceImage: (
    object: DeleteServiceImageType
  ): Promise<MessageResponseType> =>
    client_services_requests.post("deleteServiceImage", object),
  purchaseClientService: (
    object: PurchaseClientServiceType
  ): Promise<PurchaseClientServiceResponseType> =>
    client_services_requests.post("purchaseClientService", object),
  createServiceInvite: (
    object: CreateServiceInviteType
  ): Promise<MessageResponseType> =>
    client_services_requests.post("createServiceInvite", object),
  revokeServiceInvite: (
    object: ServiceInviteIdType
  ): Promise<MessageResponseType> =>
    client_services_requests.post("revokeServiceInvite", object),
  denyServiceInvite: (
    object: ServiceInviteIdType
  ): Promise<MessageResponseType> =>
    client_services_requests.post("denyServiceInvite", object),
  reorderClientServices: (
    object: ReorderClientServicesType
  ): Promise<MessageResponseType> =>
    client_services_requests.post("reorderClientServices", object),
  getServicePromoCodesList: (
    object: GetServicePromoCodesListType
  ): Promise<GetServicePromoCodesListResponseType> =>
    client_services_requests.post("getServicePromoCodesList", object),
  createServicePromoCode: (
    object: CreateServicePromoCodeType
  ): Promise<MessageResponseType> =>
    client_services_requests.post("createServicePromoCode", object),
  deleteServicePromoCode: (
    object: DeleteServicePromoCodeType
  ): Promise<MessageResponseType> =>
    client_services_requests.post("deleteServicePromoCode", object),
  validateServicePromoCode: (
    object: ValidateServicePromoCodeType
  ): Promise<ValidateServicePromoCodeResponseType> =>
    client_services_requests.post("validateServicePromoCode", object),
}

export const ClientPaymentRequests = {
  getStripeConnectOnboardingLink: (
    object: UserIdType
  ): Promise<ConnectOnboardingLinkResponseType> =>
    app_requests.post("getStripeConnectOnboardingLink", object),
  getConnectAccount: (
    object: UserIdType
  ): Promise<GetConnectAccountResponseType> =>
    app_requests.post("getConnectAccount", object),
  getClientPayments: (
    object: GetClientPaymentsType
  ): Promise<GetClientPaymentsResponseType> =>
    app_requests.post("getClientPayments", object),
  getProfileClientPayments: (
    object: GetProfileClientPaymentsType
  ): Promise<any> => app_requests.post("getProfileClientPayments", object),
  getStripeVerificationSession: (
    object: GetStripeVerificationSessionType
  ): Promise<GetStripeVerificationSessionResponseType> =>
    app_requests.post("getStripeVerificationSession", object),
  retrieveStripeVerificationSession: (
    object: RetrieveStripeVerificationSessionType
  ): Promise<RetrieveStripeVerificationSessionResponseType> =>
    app_requests.post("retrieveStripeVerificationSession", object),
}

export const NotificationRequests = {
  createNotificationSettings: (
    object: CreateNotificationsType
  ): Promise<MessageResponseType> =>
    app_requests.post("createNotificationSettings", object),
  getNotificationSettings: (
    object: GetNotificationSettingsType
  ): Promise<GetNotificationSettingsResponseType> =>
    app_requests.post("getNotificationSettings", object),
  updateNotificationSettings: (
    object: UpdateNotificationSettingsType
  ): Promise<MessageResponseType> =>
    app_requests.post("updateNotificationSettings", object),
  getNotifications: (
    object: GetNotificationsType
  ): Promise<GetNotificationsResponseType> =>
    app_requests.post("getNotifications", object),
  markRead: (object: MarkReadUnreadType): Promise<MessageResponseType> =>
    app_requests.post("markRead", object),
  markUnread: (object: MarkReadUnreadType): Promise<MessageResponseType> =>
    app_requests.post("markUnread", object),
}

export const UtilityRequests = {
  getPresignedS3Url: (
    object: CreatePresignedS3UrlType
  ): Promise<CreatePresignedS3UrlResponseType> =>
    app_requests.post("getPresignedS3Url", object),
}

export const FormRequests = {
  getFormLinks: (object: GetFormLinksType): Promise<FormLink[]> =>
    client_services_requests.post("getFormLinks", object),
  createFormLink: (object: CreateFormLinkType): Promise<MessageResponseType> =>
    client_services_requests.post("createFormLink", object),
  updateFormLink: (object: UpdateFormLinkType): Promise<MessageResponseType> =>
    client_services_requests.post("updateFormLink", object),
  deleteFormLink: (object: DeleteFormLinkType): Promise<MessageResponseType> =>
    client_services_requests.post("deleteFormLink", object),
}
