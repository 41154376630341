import React from "react"
import UserSelect from "../user-select/UserSelect"
import ActiveSessionPreview from "./ActiveSessionPreview"
import { Link } from "react-router-dom"
import { UserCtx } from "../../context/userContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { SessionContext } from "../../pages/sessions/context/sessionContext"
import { NotificationCtx } from "../../context/notificationContext"
import { SessionsRequests } from "../../api/app.service"
import { Button } from "../ui/button"
import useLoading from "../../hooks/useLoading"
import Loader from "../ui/loader"
import { CreateTestimonial } from "../coach-public-profile/CreateTestimonial"

interface SessionItem {
  room_id: string
  participant_list: {
    profile_id: number
  }[]
}

interface SessionData {
  session_data: {
    room_id: string
    session_type: number
    send_review: number
    end_time: string
    total_participant_count: number
  }
  details_list: any[]
  webhook_status: string
  message: string
}

const ActiveSessionLanding: React.FC = () => {
  const { user } = React.useContext(UserCtx)
  const [sessionList, setSessionList] = React.useState<any[]>([])
  const [participantsModal, setParticipantsModal] =
    React.useState<boolean>(false)
  const { renderError } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const {
    sessionListLoading,
    setRoomCode,
  } = React.useContext(SessionContext)
  const { lastWebsocketMessage } = React.useContext(NotificationCtx)
  const [hostJoined, setHostJoined] = React.useState(false)

  const [pastSessions, setPastSessions] = React.useState<SessionItem[]>([])
  const [lastSessionRoomId, setLastSessionRoomId] = React.useState<string>("")
  const [sessionData, setSessionData] = React.useState<SessionData | null>(null)
  const [coach, setCoach] = React.useState<any[]>([]);
  const [createTestimonialOpen, setCreateTestimonialOpen] =
    React.useState<boolean>(false)

  const createQuickMeeting = (selectedParticipants: any[]) => {
    if (user) {
      startLoading()
      const currentUser = {
        profile_id: user.activeProfileId,
        contact_name: user.firstName
          ? `${user.firstName} ${user.lastName}`
          : user.email,
        email: user.email,
        user_id: user.user_id,
      }
      const participants = [currentUser, ...selectedParticipants]
      SessionsRequests.create100msQuickMeeting({
        profile_id: user.activeProfileId,
        meeting_name: `${
          user?.firstName ? user.firstName : user.email
        }'s Quick Meeting`,
        participants: participants,
      })
        .then((data) => {
          setRoomCode(data.room_code)
          getSessionList()
        })
        .catch((ex) => {
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const getSessionList = () => {
    if (user) {
      // setSessionListLoading(true)
      SessionsRequests.get100msSessionList({
        profile_id: user.activeProfileId,
      })
        .then((data) => {
          setSessionList(data.session_list)
          setHostJoined(data?.session_list[0]?.host_joined)
          // setSessionListLoading(false)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          // setSessionListLoading(false)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }
  const checkUrlParams = () => {
    const params = new URLSearchParams(window.location.search)
    const redirect = params.get("redirect")
    if (redirect === "quickstart") {
      window.scrollTo(0, 0)
      setParticipantsModal(true)
    }
    window.history.replaceState(null, "", window.location.pathname)
  }

  const getPastSessions = () => {
    if (user) {
      SessionsRequests.getPastSessions({
        profile_id: parseInt(user.activeProfileId),
      })
        .then((data: any) => {
          setPastSessions(data)
          const sessions = data.session_list
          setPastSessions(sessions)
          if (sessions.length > 0) {
            setLastSessionRoomId(sessions[sessions.length - 1].room_id)
          }
        })
        .catch((ex) => {
          renderError(ex.response.data.message)
        })
    }
  }

  const getSessionDetails = () => {
    if (pastSessions && pastSessions.length > 0) {
      SessionsRequests.getPastSessionDetail({
        room_id: lastSessionRoomId,
      })
        .then((data: any) => {
          setSessionData(data)
          setCreateTestimonialOpen(true)
        })
        .catch((ex) => {
          renderError(ex.response?.data?.message || "An error occurred")
        })
        .finally(() => {
        })
    }
  }

  const checkParticipants = () => {
    const participants = pastSessions[pastSessions.length - 1].participant_list;
    const coachId = participants
    .filter((participant) => participant?.profile_id !== user.activeProfileId)
    .map((participant) => participant?.profile_id);
     setCoach(coachId)
  };

  React.useEffect(() => {
    if (
      lastWebsocketMessage?.type === "session" &&
      (lastWebsocketMessage?.data?.event_type === "quick_meeting_started" ||
        lastWebsocketMessage?.data?.event_type === "session_ended" ||
        lastWebsocketMessage?.data?.participant_count > 0)
    ) {
      getSessionList()
      getPastSessions()
    }
  }, [lastWebsocketMessage])

  React.useEffect(() => {
    getPastSessions()
    checkUrlParams()
    getSessionList()

  }, [])

  React.useEffect(() => {
    if (lastSessionRoomId) {
      getSessionDetails()
      checkParticipants()
    }
  }, [pastSessions])

  React.useEffect(() => {
    //Disables background scroll when testimonial modal is open
    if (createTestimonialOpen) {
        document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
        document.body.style.overflow = 'auto'
    }
}, [createTestimonialOpen] )

  return (
    <>
      <>
      {participantsModal && (
        <UserSelect
          setShowModal={setParticipantsModal}
          callback={createQuickMeeting}
          modalTitle="Quick Session"
          buttonText="Create Session"
        />
      )}
      <div className="mx-auto w-[376px] md:w-full flex flex-col md:flex-row-reverse justify-between">
        <div className="mb-[24px] md:m-0 w-full md:w-[396px]">
          {sessionListLoading ? (
            <div
              className="w-full h-[464px] flex items-center justify-center flex-col rounded-xl border border-grayMist bg-[#f8f8f8] gap-[10px]"
            >
              <span className="mb-2">Loading active sessions...</span>
              <Loader />
            </div>
          ) : (
            <>
              {sessionList.length > 0 ? (
                <ActiveSessionPreview
                  sessionList={sessionList}
                  getSessionList={getSessionList}
                  hostJoined={hostJoined}
                />
              ) : (
                <div
                  className="w-full h-[464px] flex items-center justify-center rounded-xl border bg-muted"
                >
                  No Active Sessions
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex flex-col gap-[24px]">
          <div className="flex flex-col w-full md:w-[620px] p-[40px] main-shadow rounded-[16px] bg-white">
            <h2 className="text-[28px] font-semibold mb-[8px]">
              Quick Session
            </h2>
            <p className="text-graySlate text-base mb-[24px]">
              Jump into a quick video chat. This is perfect for non-billable
              calls.
            </p>
            <div>
              <Button size="lg" onClick={() => setParticipantsModal(true)}>
                Create Session
              </Button>
            </div>
          </div>
          <div className="flex flex-col w-full md:w-[620px] p-[40px] main-shadow rounded-[16px] bg-white mb-[80px]">
            <h2 className="text-[28px] font-semibold mb-[8px]">
              {user.activeProfile === "coach"
                ? "Schedule a Session"
                : "Book a Session"}
            </h2>
            <p className="text-graySlate text-base mb-[24px]">
              {user.activeProfile === "coach"
                ? "Invite your client(s) to an upcoming Zoee session."
                : "Schedule a future session with a Zoee coach."}
            </p>
            <div>
              <Link to={`/${user.activeProfile}/scheduling`}>
                <Button size="lg">Schedule Session</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
            
      {Boolean(user.activeProfile === "member" &&
      sessionData?.session_data?.send_review === 1 &&
      sessionData?.session_data?.total_participant_count <= 2 &&
      createTestimonialOpen) && (
        <CreateTestimonial
          roomId={lastSessionRoomId}
          isOpen={setCreateTestimonialOpen}
          coachId={coach}
        />
      )}
            
      </>
    </>

  )
}

export default ActiveSessionLanding
