import * as React from "react";
import { VerifyEmail } from "../../api/auth.service";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { UserCtx } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../components/LoadingPage";
import useLoading from "../../hooks/useLoading";

export default function ConfirmEmail() {
  const { user } = React.useContext(UserCtx);
  const { renderError, getUser } = React.useContext(
    CommonFunctionCtx,
  );
  const navigate = useNavigate();
  const { stopLoading } = useLoading();

  const sendConfirmationCode = (code: any) => {
    VerifyEmail.verifyEmailCode({
      verification_code: code,
    })
      .then((data) => {
        getUser().then(() => {
          const message = data?.message === "complete"
            ? "success"
            : "wrong-code";
          navigate(`${user ? "/coach" : "/home"}`, {
            state: {
              message: message,
              // verifyPhone: true
            },
          });
        });
      })
      .catch((err) => {
        console.error(err);
        renderError(err.response.data.message);
      });
    stopLoading();
  };

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("t");
    if (code) {
      sendConfirmationCode(code);
    } else {
      window.location.href = "/home?message=no-code";
    }
  }, []);

  return <LoadingPage />;
}
