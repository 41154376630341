import React, { FC, useState, useEffect } from "react";
import { UserCtx } from "../../../../context/userContext";
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext";
import { Link } from "react-router-dom";
import { ServiceRequests } from "../../../../api/app.service";
import SelectServiceCardPreview from "../../../services/SelectServiceCardPreview";
import ServiceUsagePopup from "../../../contacts/contact-profile/ServiceUsagePopup";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import AttachServiceDetails from "../AttachServiceDetails";
import Loader from "../../../ui/loader";
import { Button } from "../../../ui/button";
import {
  SvgAlert,
  SvgInformation,
  SvgPaintBrush,
  SvgInformationCircle,
} from "../../../icons";

type Props = {
  setNewMeetingType: any;
  newMeetingData: any;
  setNewMeetingData: any;
  setStep: any;
  isEditing?: boolean;
};

const SelectService: FC<Props> = ({
  setNewMeetingType,
  newMeetingData,
  setNewMeetingData,
  setStep,
  isEditing,
}) => {
  const { user } = React.useContext(UserCtx);
  const { renderError } = React.useContext(CommonFunctionCtx);
  const [services, setServices] = React.useState<any[]>([]);
  const [serviceUsage, setServiceUsage] = React.useState<any>({
    show: false,
    service: {},
  });
  const [showDetails, setShowDetails] = React.useState<boolean>(false);
  const [loadingProfileServiceList, setLoadingProfileServiceList] =
    React.useState<boolean>(false);
  const updateNewMeetingData = (service: any) => {
    setNewMeetingData({
      ...newMeetingData,
      service_usage_id: service.service_usage_id,
      service_details: service.service_details,
      duration: service.service_details.session.duration / 60,
      // TODO ADD STRIPE_PROFILE_ID
      stripe_product_id: service.stripe_product_id,
    });
    setStep(3);
  };

  const handleSetCustomMeeting = () => {
    setNewMeetingType("custom");
    setStep(1);
    setNewMeetingData({
      ...newMeetingData,
      meetingType: "custom",
      service_usage_id: null,
      service_details: null,
    });
  };

  const getProfileServiceList = () => {
    if (user && newMeetingData) {
      setLoadingProfileServiceList(true);

      let coachProfileId;
      let memberProfileId;

      if (isEditing) {
        // Editing (both as coach and as member)
        const coach = newMeetingData.contacts.find((contact: any) => {
          return contact.profile_type === "coach";
        });
        coachProfileId = coach.profile_id;

        // TODO This won't work for meetings with > 2 participants but is ok for individual meetings
        const member = newMeetingData.contacts.find((contact: any) => {
          return contact.profile_type !== "coach";
        });
        memberProfileId = member.profile_id;
      } else {
        // New meeting
        if (user.activeProfile === "coach") {
          // As coach
          coachProfileId = user.activeProfileId;

          // TODO This won't work for meeting with > 2 participants
          const member = newMeetingData.contacts.find((contact: any) => {
            return contact.profile_type !== "coach";
          });
          memberProfileId = member.profile_id;
        } else {
          // As member
          memberProfileId = user.activeProfileId;

          const coach = newMeetingData.contacts.find((contact: any) => {
            return contact.profile_type === "coach";
          });
          coachProfileId = coach.profile_id;
        }
      }

      ServiceRequests.getProfileServiceList({
        coach_profile_id: coachProfileId,
        member_profile_id: memberProfileId,
      })
        .then((data) => {
          setServices(data.service_list);
          setLoadingProfileServiceList(false);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setLoadingProfileServiceList(false);
        });
    }
  };

  React.useEffect(() => {
    if (newMeetingData.contacts[0]) {
      getProfileServiceList();
    } else {
      renderError(
        "Please go back and select a contact in order to see the associated services."
      );
    }
  }, []);

  useScrollToTop();

  return (
    <>
      {showDetails && <AttachServiceDetails setShowDetails={setShowDetails} />}
      {serviceUsage.show && (
        <ServiceUsagePopup
          setServiceUsage={setServiceUsage}
          serviceUsage={serviceUsage}
        />
      )}
      <div className="pb-[180px] px-[20px] overflow-hidden">
        <div className="flex items-center justify-between mb-[24px]">
          <div className="flex items-center">
            <h3 className="text-[18px] md:text-[28px] font-bold md:font-semibold md:ml-0 ml-2">
              Select Service
            </h3>
            <button
              onClick={() => setShowDetails(true)}
              className="p-2 text-graySlate hover:bg-grayFlash duration-150 rounded-[10px] ml-2"
            >
              <SvgInformationCircle />
            </button>
          </div>
          <Button
            onClick={() => setStep((previousStep: number) => previousStep - 1)}
            className="text-md"
            size={"lg"}
          >
            Back
          </Button>
        </div>
        {loadingProfileServiceList ? (
          <div className="w-full mx-auto flex flex-col items-center gap-[12px] mt-[32px]">
            <Loader />
            <h3 className="font-bold text-[16px] mb-[4px] text-center">
              Looking for available services...
            </h3>
          </div>
        ) : (
          <div className="w-full grid lg:grid-cols-2 gap-[24px] px-[12px] md:px-0 mx-auto">
            {services && services.length > 0 ? (
              <>
                {services
                  .filter(
                    (service) =>
                      service.service_status === "active" &&
                      (service.available_session_count > 0 || isEditing) &&
                      !service.is_group_service
                  )
                  .map((service: any, idx: number) => (
                    <div key={idx} className="mx-auto">
                      <SelectServiceCardPreview
                        service={service}
                        buttonText="Select Service"
                        buttonCallback={() => updateNewMeetingData(service)}
                        isDisabled={
                          isEditing && user.activeProfile === "member"
                        }
                        setServiceUsage={setServiceUsage}
                      />
                    </div>
                  ))}
              </>
            ) : (
              <>
                {newMeetingData?.contacts?.length > 0 && (
                  <>
                    {user.activeProfile === "coach" ? (
                      <div className="mx-auto md:w-[372px] w-full h-[320px] flex flex-col items-center justify-between text-center p-[24px] bg-white promotion-shadow rounded-[16px]">
                        <div className="flex flex-col items-center">
                          <SvgAlert className="mb-[24px] mt-[24px]" />
                          <h4 className="mb-[8px] text-[18px] font-bold">
                            The selected member isn't subscribed to any of your
                            services
                          </h4>
                          <p className="mb-[24px] text-base text-graySlate">
                            Let's start by inviting the selected member to a
                            service!
                          </p>
                        </div>
                        <Button
                          className="w-full h-[48px]
                                                    flex items-center gap-[10px] rounded-[10px] text-md"
                          disabled={newMeetingData?.contacts.length === 0}
                        >
                          <Link
                            to={`/${user.activeProfile}/contacts/${newMeetingData?.contacts[0]?.profile_id}/services`}
                          >
                            Invite to Service
                          </Link>
                        </Button>
                      </div>
                    ) : (
                      <div className="mx-auto md:w-[372px] w-full h-[320px] flex flex-col items-center justify-between text-center p-[24px] bg-white promotion-shadow rounded-[16px]">
                        <div className="flex flex-col items-center">
                          <SvgAlert className="mb-[24px] mt-[24px]" />
                          <h4 className="mb-[8px] text-[18px] font-bold">
                            You aren't subscribed to any of the selected coach's
                            services
                          </h4>
                          <p className="mb-[24px] text-base text-graySlate">
                            Let's start by enrolling in a service!
                          </p>
                        </div>
                        <Button
                          asChild
                          className="w-full h-[48px]
                                                    flex items-center gap-[10px] rounded-[10px] text-md"
                          disabled={newMeetingData?.contacts.length === 0}
                        >
                          <Link
                            to={`/${user.activeProfile}/contacts/${newMeetingData?.contacts[0]?.profile_id}/services`}
                          >
                            See Services
                          </Link>
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {!isEditing && user.activeProfile === "coach" && (
              <div className="relative mx-auto md:w-[372px] w-full h-[320px] flex flex-col items-center justify-between text-center p-[24px] bg-white promotion-shadow rounded-[16px]">
                <div className="flex flex-col items-center">
                  <div className="flex items-center justify-center mb-[24px] mt-[24px] bg-white h-[48px] w-[48px] rounded-full card-icon-shadow">
                    <SvgPaintBrush className="" />
                  </div>
                  <h4 className="mb-[8px] text-[18px] font-bold">
                    Create Non-billable Meeting
                  </h4>
                  <p className="mb-[24px] text-base text-graySlate">
                    Select for a one-off meeting not
                    <br /> billed to a service
                  </p>
                </div>
                <Button
                  onClick={handleSetCustomMeeting}
                  className="h-[48px] w-full text-md"
                >
                  Create
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SelectService;
