import React, { FC } from "react"
import BackToButton from "../BackToButton"
import CardSelector from "./checkout-process/CardSelector"
import Loader from "../ui/loader"
import SubscriptionCheckoutCard from "./SubscriptionCheckoutCard"
import useDate from "../../hooks/useDate"
import useLoading from "../../hooks/useLoading"
import useScrollToTop from "../../hooks/useScrollToTop"
import { Button } from "../ui/button"
import {
  SvgCheckbox,
  SvgCheckboxOff,
  SvgLock2,
  SvgPiechartOneQuarter,
  SvgPlus,
  SvgPromoCode,
  SvgRecurring,
} from "../icons/"
import { SubscriptionRequests, ReferralRequests } from "../../api/app.service"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { SubscriptionContext } from "../../pages/subscription/context/subscriptionContext"
import { UserCtx } from "../../context/userContext"
import { ValidatePromoCodeResponseType } from "../../models/app.interface"

type Props = {}

const SubscriptionCheckout: FC<Props> = () => {
  const { user, setUser } = React.useContext(UserCtx)
  const [loadingReferralCode, setLoadingReferralCode] =
    React.useState<boolean>(false)
  const [isTermsAgreed, setIsTermsAgreed] = React.useState<boolean>(false)
  const [isPromocode, setIsPromocode] = React.useState<boolean>(false)
  const [promoCode, setPromoCode] = React.useState<string>("")
  const [isAutopopulatedPromoCode, setIsAutopopulatedPromoCode] =
    React.useState<boolean>(false)
  const [appliedDiscount, setAppliedDiscount] = React.useState<any>({
    amount: 0,
    promoCode: "",
  })
  const { renderError, renderSuccess, hideAlert, getUser } =
    React.useContext(CommonFunctionCtx)

  const { startLoading, stopLoading } = useLoading()
  const {
    setHideSecondaryNav,
    showSubscriptionComponents,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    setShowSubscriptionPlans,
    createSetupIntent,
    paymentMethods,
    subscription,
    selectedSubscription,
    setShowSubscriptionCheckout,
    isSubscriptionUpdate,
    setPaymentMethodModal,
    stripeRedirectParams,
    setStripeRedirectParams,
    getSubscription,
  } = React.useContext(SubscriptionContext)
  const { mdDate } = useDate()

  const goBack = () => {
    setHideSecondaryNav(false)
    showSubscriptionComponents(
      isSubscriptionUpdate ? null : setShowSubscriptionPlans
    )
  }

  const createSubscription = () => {
    SubscriptionRequests.createSubscription({
      user_id: parseInt(user.user_id),
      stripe_payment_method_id: selectedPaymentMethod.stripe_payment_method_id,
      stripe_price_id: selectedSubscription?.stripe_price_id,
      promotion_code:
        appliedDiscount.promoCode === "" ? null : appliedDiscount.promoCode,
    })
      .then((data) => {
        if (data.subscribed_with_referral) {
          ReferralRequests.updateReferralOnSubscribe({
            user_id: parseInt(user?.user_id),
            promo_code: promoCode,
            email: user?.email,
          }).catch((ex) => {
            console.error("updateReferralOnSubscribe err", ex)
          })
        }
        return getUser()
      })
      .then((updatedUserData) => {
        setUser(updatedUserData)

        setShowSubscriptionCheckout(false)
        renderSuccess(
          "Congratulations! You have purchased a coach subscription."
        )

        setTimeout(() => {
          window.location.href = "/coach/subscription?created=true"
        }, 1000)

        hideAlert()
      })
      .catch((ex) => {
        console.log(ex)
        const errorMessage =
          ex.response?.data?.error_detail ||
          ex.response?.data?.message ||
          "An unexpected error occurred."
        renderError(errorMessage)
        stopLoading()
      })
  }

  const updateSubscription = () => {
    SubscriptionRequests.updateSubscription({
      user_id: parseInt(user.user_id),
      stripe_payment_method_id: selectedPaymentMethod.stripe_payment_method_id,
      current_stripe_price_id: subscription.stripe_price_id,
      new_stripe_price_id: selectedSubscription?.stripe_price_id,
      promotion_code:
        appliedDiscount.promoCode === "" ? null : appliedDiscount.promoCode,
    })
      .then(() => {
        if (appliedDiscount.promoCode) {
          ReferralRequests.updateReferralOnSubscribe({
            user_id: parseInt(user.user_id),
            promo_code: promoCode,
            email: user.email,
          }).catch((ex) => {
            console.error("updateReferralOnSubscribe err", ex)
          })
        }
      })
      .then(() => {
        renderSuccess("Subscription updated!")
        setShowSubscriptionCheckout(false)
        setTimeout(() => {
          getUser().then(() => {
            window.location.href = "/coach/subscription?updated=true"
          })
        }, 2500)
      })
      .catch((ex) => {
        console.log(ex)
        const errorMessage =
          ex.response?.data?.error_detail ||
          ex.response?.data?.message ||
          "An unexpected error occurred."
        renderError(errorMessage)
        stopLoading()
      })
  }

  const submitSubscriptionPurchase = () => {
    if (isTermsAgreed && selectedPaymentMethod) {
      startLoading()
      if (isSubscriptionUpdate) {
        updateSubscription()
      } else {
        createSubscription()
      }
    } else {
      if (!isTermsAgreed) {
        renderError("You must agree to the terms to complete your purchase")
      } else if (!selectedPaymentMethod) {
        renderError("You must add a payment method to complete your purchase")
      } else {
        renderError("Please agree to terms and add a payment method")
      }
    }
  }

  const handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value)
  }

  const handlePromoResponse = (data: ValidatePromoCodeResponseType) => {
    if (!data.promo_valid) {
      renderError(data.promo_invalid_reason)
      setAppliedDiscount({ amount: 0, promoCode: "" })
      setIsPromocode(false)
      setPromoCode("")
    } else {
      const details = data.promo_code
      const discountType = details.promo_value_type
      const discountUnits = details.promo_value
      const promoCode = details.promo_code
      if (discountType === "percent") {
        const discountDollars =
          (discountUnits / 100) * selectedSubscription?.amount
        setAppliedDiscount({
          amount: discountDollars,
          promoCode: promoCode,
        })
        renderSuccess(
          `Congrats! Your promotion code is saving you $${discountDollars.toFixed(
            2
          )} on this payment`
        )
      } else if (discountType === "amount") {
        setAppliedDiscount({
          amount: discountUnits,
          promoCode: promoCode,
        })
        renderSuccess(
          `Congrats! Your ${
            isAutopopulatedPromoCode ? "Zoee Referral Program" : ""
          } promotion code ${
            isAutopopulatedPromoCode ? `${promoCode}` : ""
          } is saving you $${discountUnits.toFixed(2)} on this payment`
        )
      }
      setIsPromocode(false)
    }
  }

  React.useEffect(() => {
    const referrerPromoCode = localStorage.getItem("referrerPromoCode")
    if (referrerPromoCode) {
      setPromoCode(referrerPromoCode)
      setIsAutopopulatedPromoCode(true)
    }
  }, [])

  const validatePromoCode = () => {
    if (promoCode !== "") {
      setLoadingReferralCode(true)
      SubscriptionRequests.validatePromoCode({
        promo_code: promoCode,
        promo_duration_interval: selectedSubscription?.recurring_interval,
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          handlePromoResponse(data)
          setLoadingReferralCode(false)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          setLoadingReferralCode(false)
        })
    }
  }

  const getReferralPromoCode = () => {
    setLoadingReferralCode(true)
    ReferralRequests.getReferralPromoCode({
      user_id: parseInt(user.user_id),
    })
      .then((data) => {
        if (data.promo_code) {
          setIsPromocode(true)
          setPromoCode(data.promo_code)
          setIsAutopopulatedPromoCode(true)
        }
        setLoadingReferralCode(false)
      })
      .catch((ex) => {
        console.log(ex)
        console.error(ex.response.data.message)
        setLoadingReferralCode(false)
      })
  }

  React.useEffect(() => {
    if (stripeRedirectParams) {
      setPaymentMethodModal(true)
    }
  }, [stripeRedirectParams])

  React.useEffect(() => {
    if (isAutopopulatedPromoCode) {
      validatePromoCode()
      setIsAutopopulatedPromoCode(false)
    }
  }, [isAutopopulatedPromoCode])

  React.useEffect(() => {
    if (
      selectedSubscription?.amount === 500 &&
      selectedSubscription?.recurring_interval_count === 2
    ) {
      setAppliedDiscount({
        amount: 0,
        promoCode: "500TWOYEAR",
      })
    }
    getReferralPromoCode()
  }, [])

  useScrollToTop()

  return (
    <div className="-mt-0 mb-[32px] md:-mt-[43px] md:mb-0">
      <BackToButton
        onClick={goBack}
        current={isSubscriptionUpdate ? "My Subscription" : "Plans & Pricing"}
        prev={isSubscriptionUpdate ? "My Subscription" : "Plans & Pricing"}
      />
      <h1 className="mb-[32px] mt-[24px] hidden text-[36px] font-bold md:block">
        Manage Subscription
      </h1>
      <div className="mt-[24px] flex w-full flex-col px-[20px] md:mt-0 md:flex-row md:justify-between md:px-0">
        <div>
          <div className="mb-[32px] flex flex-col items-center gap-[32px] md:w-[784px] md:min-w-[700px] md:flex-row md:gap-[24px]">
            <div>
              <h3 className="mb-[12px] text-[22px] font-bold md:mb-[24px]">
                Current Plan
              </h3>
              <SubscriptionCheckoutCard
                isNewPlan={false}
                subscription={subscription}
              />
            </div>
            <div>
              <h3 className="mb-[12px] text-[22px] font-bold md:mb-[24px]">
                New Plan
              </h3>
              <SubscriptionCheckoutCard
                isNewPlan={true}
                subscription={selectedSubscription}
              />
            </div>
          </div>
        </div>

        <div className="border-grayCloud mt-[60px] flex flex-col border-0 md:mt-0 md:min-h-[856px] md:min-w-[416px] md:border-l md:pl-[80px]">
          <h3 className="mb-[32px] text-[22px] font-bold">Payment Details</h3>
          <h5 className="text-graySlate mb-[16px] text-base">
            PAYMENT SCHEDULE
          </h5>
          {isSubscriptionUpdate && (
            <p className="mb-[16px] text-base font-bold">
              The new subscription will start when the current period ends.
            </p>
          )}
          <div className="mb-[14px] flex items-center">
            <SvgPiechartOneQuarter className="mr-[16px] h-6 w-6" />
            {isSubscriptionUpdate ? (
              <p className="min-w-fit text-base">
                1st payment on{" "}
                {mdDate(subscription?.subscription_next_payment_date)}
              </p>
            ) : (
              <p className="min-w-fit text-base">1st payment today</p>
            )}
            <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
            <p className="min-w-fit text-base">
              ${selectedSubscription?.amount?.toFixed(2)}
            </p>
          </div>
          <div className="mb-[12px] flex items-center">
            <SvgRecurring className="mr-[16px] h-6 w-6 md:h-8 md:w-8" />
            <p className="min-w-fit text-base">Recurring payments</p>
            <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
            <p className="min-w-fit text-base">
              $
              {selectedSubscription?.is_promotional
                ? 0
                : selectedSubscription?.amount?.toFixed(2)}
            </p>
          </div>

          {selectedSubscription.amount !== 500 && (
            <p className="text-graySlate mb-[24px] text-base">
              Payments will be charged
              {selectedSubscription?.recurring_interval === "annual" ? (
                <span className="text-mainBlack font-semibold text-black">
                  {" "}
                  annually{" "}
                </span>
              ) : (
                <span className="text-mainBlack font-semibold text-black">
                  {" "}
                  monthly{" "}
                </span>
              )}
              to your {selectedPaymentMethod?.card_brand.toUpperCase()} ...{" "}
              {selectedPaymentMethod?.card_last4}
            </p>
          )}
          <div className="bg-grayMist mb-[24px] h-[1px] w-full" />

          <div className="relative">
            <div className="mb-[16px] flex w-full items-center justify-between">
              <h5 className={`text-graySlate text-base`}>SUMMARY</h5>
              {loadingReferralCode ? (
                <Loader />
              ) : (
                <>
                  {isPromocode ? (
                    <button
                      onClick={() => setIsPromocode(() => !isPromocode)}
                      className="text-primaryBlue text-base font-bold"
                    >
                      Cancel
                    </button>
                  ) : selectedSubscription?.amount === 500 ? (
                    ``
                  ) : (
                    <button
                      onClick={() => setIsPromocode(() => !isPromocode)}
                      className="text-blurple flex items-center gap-[8px] text-base font-bold"
                    >
                      <SvgPromoCode fill="#3395FF" />
                      Add Promocode
                    </button>
                  )}
                </>
              )}
            </div>
            {isPromocode && (
              <div className="mb-[16px] flex items-center gap-[16px]">
                <div className="default-input w-[222px]">
                  <input
                    type="text"
                    placeholder="Enter Promocode"
                    onChange={handlePromoCodeChange}
                    value={promoCode}
                  />
                  <div className="overlay">Promocode</div>
                </div>
                <button
                  className="btn-primary btn-blue h-[56px] w-[98px]"
                  onClick={validatePromoCode}
                >
                  Save
                </button>
              </div>
            )}
            <div className="mb-[8px] flex items-center">
              <p className="min-w-fit text-base">Interest or fees</p>
              <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
              <p className="min-w-fit text-base">$0.00</p>
            </div>
            <div className="mb-[8px] flex items-center">
              <p className="min-w-fit text-base">
                {`Promotions ${
                  appliedDiscount?.promoCode && `(${appliedDiscount.promoCode})`
                }`}
              </p>
              <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
              <p className="min-w-fit text-base">
                (${appliedDiscount.amount?.toFixed(2)})
              </p>
            </div>
          </div>

          <div className="mb-[24px] flex items-center">
            <p className="min-w-fit text-[18px] font-bold">Order total</p>
            <div className="border-grayCloud mx-[24px] h-[2px] w-full border-b border-dashed" />
            <p className="min-w-fit text-[18px] font-bold">
              $
              {(
                selectedSubscription?.amount?.toFixed(2) -
                appliedDiscount.amount?.toFixed(2)
              ).toFixed(2)}
            </p>
          </div>
          <div className="bg-grayMist mb-[24px] h-[1px] w-full" />
          <div className="mb-[16px] flex items-center justify-between">
            <h5 className="text-graySlate text-base">PAYMENT METHOD</h5>
          </div>
          {paymentMethods?.length > 0 ? (
            <div className="mb-[24px]">
              <CardSelector
                paymentMethods={paymentMethods}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                callback={() => {
                  createSetupIntent()
                    .then(() => {
                      console.log("Payment method setup intent created")
                    })
                    .catch((error: any) => {
                      console.error("Error creating setup intent", error)
                    })
                }}
              />
            </div>
          ) : (
            <button
              className="text-primaryBlue mb-[24px] flex w-full cursor-pointer items-center justify-start gap-[8px]"
              onClick={() => {
                createSetupIntent()
                  .then(() => {
                    console.log("Payment method setup intent created")
                  })
                  .catch((error: any) => {
                    console.error("Error creating setup intent", error)
                  })
              }}
            >
              <SvgPlus fill="#4750F5" />
              Add New Payment Method
            </button>
          )}

          <div className="mb-[24px] flex gap-[16px]">
            <div>
              {isTermsAgreed ? (
                <button onClick={() => setIsTermsAgreed(() => !isTermsAgreed)}>
                  <SvgCheckbox />
                </button>
              ) : (
                <button onClick={() => setIsTermsAgreed(() => !isTermsAgreed)}>
                  <SvgCheckboxOff stroke="#4750F5" strokeWidth="1" />
                </button>
              )}
            </div>
            <div className="text-graySlate text-base">
              I have read and agree to the{" "}
              <a
                href="https://zoee.com/terms-and-conditions"
                className="text-blurple"
                target="_blank"
                rel="noreferrer"
              >
                Agreement Disclosures
              </a>{" "}
              and {/* TODO add href */}
              <a href="#" className="text-blurple">
                Payment Authorization
              </a>
              , and the
              {/* TODO add href */}
              <a href="#" className="text-blurple">
                {" "}
                refund policy{" "}
              </a>
              provided by Zoee
            </div>
          </div>
          <Button
            disabled={
              selectedSubscription?.stripe_price_id ===
              subscription?.stripe_price_id
            }
            className="text-md mx-auto mb-[12px] w-[336px] max-w-[336px]"
            size={"lg"}
            onClick={submitSubscriptionPurchase}
          >
            Purchase
          </Button>
          <div className="mx-auto flex items-center gap-[8px]">
            <SvgLock2 />
            <p className="text-gray text-base">
              Guaranteed safe and secure payments
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionCheckout
