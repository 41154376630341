import React, { useState } from "react";
import { UserCtx } from "../../../context/userContext";
import useLoading from "../../../hooks/useLoading";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { MeetingRequests } from "../../../api/app.service";
import AttendeesView from "../../scheduling/session-detail/AttendeesView";
import NewMeeting from "../../scheduling/new-meeting/NewMeeting";
import BackToButton from "../../BackToButton";
import MeetingStatusIcon from "../../scheduling/MeetingStatusIcon";
import { Button } from "../../ui/button";
import moment from "moment";
import ContactAvatar from "../../cards/ContactAvatar";
import SelectServiceCardPreviewWide from "../../services/ServiceCardPreviewWide";
import { SvgNotifications, SvgVideoSolid, SvgZoeeIcon } from "../../icons";

interface Props {
  meeting: any;
  setOpenUpcomingDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setUpcomingSessionDetailViewId: any;
  getMeetings: any;
  setBackToContacts: any;
  backToContacts: boolean;
  contact: any;
  keepBackToButton: boolean;
}

const MeetingPreviewDetails: React.FC<Props> = ({
  meeting,
  setUpcomingSessionDetailViewId,
  setOpenUpcomingDetails,
  getMeetings,
  setBackToContacts,
  backToContacts,
  contact,
  keepBackToButton,
}) => {
  const { user } = React.useContext(UserCtx);
  const { startLoading, stopLoading } = useLoading();
  const { renderError, renderSuccess } =
    React.useContext(CommonFunctionCtx);
  const [initialStep, setInitialStep] = React.useState<string>("");
  const [showNewMeeting, setShowNewMeeting] = React.useState<boolean>(false);
  const [showRescheduleMeeting, setShowRescheduleMeeting] =
    React.useState<boolean>(false);
  const [showAttendeesView, setShowAttendeesView] =
    React.useState<boolean>(false);
  const [clickReschedule, setClickReschedule] = React.useState<boolean>(false);
  const [clickTodayReschedule, setClickTodayReschedule] =
    React.useState<boolean>(false);
  const [newMeetingType, setNewMeetingType] = React.useState<
    "individual" | "group" | "custom"
  >("custom");
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    meeting?.meetingType.toLowerCase() === 'group' &&
    setIsHovered(true);

  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useScrollToTop();

  if (!meeting) {
    return <p>Session not found</p>;
  }

  const formatMeetingType = () => {
    if (meeting?.meetingType === "individual") {
      return "INDIVIDUAL - BILLABLE";
    } else if (meeting?.meetingType === "group") {
      return "GROUP - BILLABLE";
    } else if (meeting?.meetingType === "custom") {
      return "NON-BILLABLE";
    }
  };

  const getGuestName = (guest: any) => {
    if (guest?.first_name) {
      return `${guest.first_name} ${guest.last_name}`;
    } else {
      return guest.email;
    }
  };

  const cancelMeeting = () => {
    const coach = meeting?.contacts?.filter(
      (contact: any) => contact.profile_type === "coach"
    )[0];
    startLoading();
    MeetingRequests.cancelMeeting({
      coach_user_id: parseInt(coach.user_id),
      profile_id: parseInt(user.activeProfileId),
      first_name: user.firstName,
      last_name: user.lastName,
      meeting_id: meeting?.id,
    })
      .then(() => {
        setTimeout(() => {
          setUpcomingSessionDetailViewId(null);
          setOpenUpcomingDetails(false);
          renderSuccess("Your meeting was successfully canceled.");
          getMeetings();
          stopLoading();
        }, 2000);
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
        stopLoading();
      });
  };

  if (showAttendeesView) {
    return (
      <AttendeesView
        sessionName={meeting.meetingName || "Meeting View"}
        contacts={meeting.contacts}
        setShowAttendeesView={setShowAttendeesView}
        // backToContacts={backToContacts}
      />
    );
  } else if (showNewMeeting) {
    return (
      <NewMeeting
        newMeetingType={meeting.meetingType}
        setShowNewMeeting={setShowNewMeeting}
        setActiveSessionDetailViewId={setUpcomingSessionDetailViewId}
        existingMeetingData={meeting}
        initialStep={initialStep}
        isEditing={true}
        setNewMeetingType={setNewMeetingType}
        showRescheduleMeeting={showRescheduleMeeting}
        setShowRescheduleMeeting={setShowRescheduleMeeting}
        clickReschedule={clickReschedule}
        setClickReschedule={setClickReschedule}
        clickTodayReschedule={clickTodayReschedule}
        backToContacts={backToContacts}
        contact={contact}
        keepBackToButton={keepBackToButton}
      />
    );
  } else {
    return (
      <div className="mt-[12px] w-full flex items-center flex-col">
        {meeting.is_managed && meeting.belongs_to_profile ? (
          <div className="pb-[100px] md:pb-0">
            <div className="mx-auto md:w-[744px] mb-[16px] px-[18px] md:px-0 w-full flex flex-col -mt-0 md:-mt-[55px]">
              <BackToButton
                onClick={() => setOpenUpcomingDetails(false)}
                current="Contact Profile"
                prev="Contact Profile"
              />
              <div className="mb-[12px] bg-white promotion-shadow rounded-[16px]">
                <div className="h-[100px] px-[24px] md:px-[32px] zoee-session-detail-gradient flex items-center justify-between rounded-t-[16px]">
                  <div className="flex items-center mr-[8px]">
                    <SvgZoeeIcon className="w-[38px] h-[38px] mr-[12px] md:mr-[22px]" />
                    <h3 className="text-[16px] font-bold text-white">
                      Zoee Managed Calendar
                    </h3>
                  </div>
                </div>
                <div className="relative flex flex-col px-[24px] pb-[24px] pt-[28px] md:px-[32px] md:pt-[40px] md:pb-[32px]">
                  <h4 className="hidden md:block text-base font-bold text-graySlate mb-[24px]">
                    EVENT DETAILS ({formatMeetingType()})
                  </h4>
                  <h4 className="md:hidden text-base font-bold text-graySlate mb-[24px]">
                    EVENT DETAILS
                  </h4>
                  <h2 className="text-[18px] md:text-[32px] font-bold mb-[16px]">
                    {meeting.meetingName}
                  </h2>
                  <div className="flex items-center mb-[32px]">
                    <div className="pr-[16px] border-r border-grayMist">
                      {meeting.status && (
                        <MeetingStatusIcon status={meeting.status} />
                      )}
                    </div>
                    <button className="ml-[16px] flex items-center justify-center py-[4px] px-[8px] rounded-[8px] text-primaryBlue font-bold text-base bg-paleCyanBlue bg-opacity-[.24]">
                      <SvgVideoSolid
                        fill="blue"
                        className="mr-[12px]"
                      />
                      Video
                    </button>
                  </div>
                  <div className="flex flex-col">
                    <h4 className="text-base font-bold text-graySlate mb-[8px]">
                      Notifications
                    </h4>
                    <div className="flex gap-[8px]">
                      <SvgNotifications className="w-[24px] h-[24px]" />
                      {meeting.notifications.length > 0 ? (
                        // TODO Make dynamic
                        <p className="text-base font-bold">Email, 30 minutes</p>
                      ) : (
                        <p className="text-base font-bold">None</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-[12px] relative promotion-shadow bg-white px-[24px] pb-[24px] pt-[28px] md:px-[32px] md:pt-[40px] md:pb-[32px] rounded-[16px]">
                <h5 className="mb-[24px] text-base font-bold text-graySlate">
                  WHEN
                </h5>
                <div>
                  {meeting.date && meeting.startTime && meeting.endTime && (
                    <>
                      <p className="font-bold text-[16px] mb-[8px]">
                        {moment(meeting.startTime).format("MMMM DD, yyyy")}
                      </p>
                      <div className="flex items-center text-graySlate text-base">
                        <p>Starts at</p>
                        <p className="text-black font-bold">
                          &nbsp;
                          {moment(meeting.startTime).format("h:mm A")}
                        </p>
                        <p className="mx-[12px]">-</p>
                        <p>Ends at</p>
                        <p className="text-black font-bold">
                          &nbsp;
                          {moment(meeting.endTime).format("h:mm A")}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-2 flex-col md:justify-between" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <Button
                    variant="outline"
                    onClick={() => {
                      setInitialStep("availability");
                      setShowNewMeeting(true);
                      setClickTodayReschedule(true);
                      setBackToContacts(true);
                    }}
                    className="relative md:absolute top-[24px] right-[24px] md:top-[50px] md:right-[40px] md:w-[120px] md:h-[40px] md:px-4 md:py-2"
    
                    disabled = {meeting?.meetingType.toLowerCase() === 'group' ? true : false}
                  >
                    Reschedule
                  </Button>
                  <div className="mb-4"></div>
                  <Button className="relative md:absolute right-[24px] md:top-[115px] md:right-[40px] md:w-[120px] md:h-[40px]   md:px-4 md:py-2" variant="destructive" onClick={cancelMeeting}  
                  disabled = {meeting?.meetingType.toLowerCase() === 'group'? true : false}>
                  Cancel Meeting
                  </Button>

                  {isHovered && user.activeProfile === 'member' && (
                      <a
                      href="mailto:support@zoee.com"
                      className="absolute right-0 mt-1 p-2 bg-lightPurple text-gray-800 text-sm rounded-md shadow-md cursor-pointer opacity-90 w-[250px] h-[70px]"
                       >
                      To cancel or reschedule this meeting please contact your coach.
                      </a>                  
                  )}
                  {isHovered && user.activeProfile === 'coach' && (                      
                    <a
                      href="mailto:support@zoee.com"
                      className="absolute right-0 -mt-20 p-2 bg-lightPurple text-gray-800 text-sm rounded-md shadow-md cursor-pointer opacity-90 w-[250px] h-[100px]"
                    >
                    <span className="ml-0">
                      Under Construction 
                    </span> 
                    <span role="img" aria-label="Under Construction" className="text-xl"> {String.fromCodePoint(0x1F6A7)}</span> <br />
                    To cancel or reschedule this meeting, please contact <b>support@zoee.com</b> 
                    </a>                  
                  )}
                </div>
              </div>
              <div className="relative mb-[12px] bg-white promotion-shadow rounded-[16px] px-[24px] pb-[24px] pt-[28px] md:px-[32px] md:pt-[40px] md:pb-[32px]">
                {meeting.meetingType === "custom" && (
                  <button
                    onClick={() => {
                      setInitialStep("attendees");
                      setShowNewMeeting(true);
                      setBackToContacts(true);
                    }}
                    className="absolute right-[24px] md:top-[32px] md:right-[40px] btn-primary btn-secondary h-[32px]"
                  >
                    Edit Attendees
                  </button>
                )}
                <div className="flex flex-col">
                  <div className="flex items-center gap-[12px] mb-[24px]">
                    <h4 className="text-base font-bold text-graySlate">
                      ATTENDEES
                    </h4>
                    {/* Hidden for 2.0 launch. */}
                    {/* <p className="text-base text-graySlate">
                                    <span className="font-bold text-mainBlack">
                                        {meeting.contacts.length - 1}
                                    </span>
                                    &nbsp;of {meeting.contacts.length} responded
                                </p> */}
                  </div>
                  <div className="flex flex-col gap-[12px]">
                    {meeting.contacts.length < 10 ? (
                      meeting.contacts.map((guest: any, idx: number) => {
                        return (
                          <div
                            key={idx}
                            className="flex items-center"
                          >
                            <ContactAvatar
                              contact={guest}
                              width={32}
                              height={32}
                              bold={false}
                              fontSize={"text-base"}
                            />
                            <p className="ml-[16px] mr-[12px] text-base font-bold">
                              {getGuestName(guest)}
                            </p>
                            <div className="text-base font-bold">
                              {/* TODO Hidden for 2.0 launch. Add back in later. */}
                              {/* <AttendeeStatusTag status="Accepted" /> */}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        {meeting.participantas
                          .slice(0, 9)
                          .map((guest: any, idx: number) => {
                            return (
                              <div
                                key={idx}
                                className="flex items-center"
                              >
                                {/* TODO Replace with ContactAvatar.tsx component */}
                                <ContactAvatar
                                  contact={guest}
                                  width={32}
                                  height={32}
                                  bold={false}
                                  fontSize={"text-base"}
                                />
                                <p className="mr-[12px] text-base font-bold">
                                  {getGuestName(guest)}
                                </p>
                                {/* TODO Hidden for 2.0 launch. Add back in later. */}
                                {/* <AttendeeStatusTag status="Accepted" /> */}
                              </div>
                            );
                          })}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {!(meeting.meetingType === "custom") && (
                <div className="relative mx-auto md:w-[744px] px-[24px] pb-[24px] pt-[28px] md:px-[32px] md:pt-[40px] md:pb-[32px] w-full flex flex-col rounded-[16px] bg-white promotion-shadow">
                  {user.activeProfile === "coach" ? (
                    <button
                      onClick={() => {
                        setInitialStep("services");
                        setShowNewMeeting(true);
                      }}
                      className="absolute right-[24px] md:top-[32px] md:right-[40px] btn-primary btn-secondary h-[32px]"
                    >
                      Edit Services
                    </button>
                  ) : (
                    <></>
                  )}
                  <h4 className="text-base font-bold text-graySlate mb-[24px]">
                    SERVICES
                  </h4>
                  {meeting?.service_details ? (
                    <SelectServiceCardPreviewWide service={meeting} />
                  ) : (
                    <>No service attached</>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="mx-auto md:w-[744px] px-[18px] md:px-0 w-full flex flex-col -mt-0 md:-mt-[55px] mb-0 md:mb-[40px]">
            <BackToButton
              onClick={() => setOpenUpcomingDetails(false)}
              current="Contact Profile"
              prev="Contact Profile"
            />
            <div className="promotion-shadow rounded-[16px] min-w-[340px]">
              <div
                className={`
                ${
                  meeting.is_managed &&
                  "non-active-profile-session-detail-gradient"
                }
                ${
                  !meeting.is_managed &&
                  "non-zoee-managed-session-detail-gradient"
                }
                h-[100px] px-[24px] md:px-[32px] flex items-center justify-between rounded-t-[16px]`}
              >
                <div className="flex items-center">
                  <h3 className="text-[16px] font-bold text-white">
                    Calendar Event
                  </h3>
                </div>
                {meeting.endTime && meeting.endTime < new Date() ? (
                  <>
                    <div className="hidden md:block text-base font-bold text-white bg-black bg-opacity-[.32] zoee-backdrop-filter-[24px] rounded-[100px] px-[12px] py-[4px]">
                      This meeting occured in the past
                    </div>
                    <div className="w-fit md:hidden text-base font-bold text-white bg-black bg-opacity-[.32] zoee-backdrop-filter-[24px] rounded-[100px] px-[12px] py-[4px]">
                      Past meeting
                    </div>
                  </>
                ) : (
                  <></>
                  // TODO add conver to Zoee Managed feature
                  // <button className="btn-primary h-[32px] bg-white text-mainBlack">
                  //     Convert to Zoee Managed
                  // </button>
                )}
              </div>
              <div className="flex flex-col px-[24px] pb-[24px] pt-[28px] md:px-[32px] md:pt-[40px] md:pb-[32px]">
                <h4 className="text-base font-bold text-graySlate mb-[8px]">
                  EVENT DETAILS
                </h4>
                <h2 className="text-[18px] md:text-[32px] font-bold mb-[12px]">
                  {meeting.meetingName}
                </h2>

                {/* <div className="flex items-center mb-[32px]">
                    <div className="pr-[16px] border-r border-grayMist">
                        {meeting.status && <StatusIcon status={meeting.status} />}
                    </div> */}
                {/* TODO make dynamic */}
                {/* <p className="font-bold text-black text-base pl-[16px]">
                        Conference Room 123 in the Zoee Building
                    </p> */}
                {/* </div> */}
                {/* <div className="flex flex-col"> */}
                {/* TODO: Make dynamic */}
                {/* <h4 className="text-base font-bold text-graySlate mb-[8px]">
                        Notifications
                    </h4> */}
                {/* <div className="flex gap-[8px]">
                        <img
                            src={notificationBell}
                            alt="notification bell icon"
                            className="w-[24px] h-[24px]"
                        />
                        <p className="text-base font-bold">
                            Email, 30 minutes
                        </p>
                    </div> */}
                {/* </div> */}
              </div>
            </div>

            <div className="mt-[12px] promotion-shadow bg-white px-[24px] pb-[24px] pt-[28px] md:px-[32px] md:pt-[40px] md:pb-[32px] rounded-[16px]">
              <h5 className="mb-[16px] text-base font-bold text-graySlate">
                WHEN
              </h5>
              <div>
                {meeting.date && meeting.startTime && meeting.endTime && (
                  <>
                    <p className="font-bold text-[16px] mb-[8px]">
                      {moment(meeting.startTime).format("MMMM DD, yyyy")}
                    </p>
                    <div className="flex items-center text-graySlate text-base">
                      <p>Starts at</p>
                      <p className="text-black font-bold">
                        &nbsp;
                        {moment(meeting.startTime).format("h:mm A")}
                      </p>
                      <p className="mx-[12px]">-</p>
                      <p>Ends at</p>
                      <p className="text-black font-bold">
                        &nbsp;{moment(meeting.endTime).format("h:mm A")}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="mt-[12px] promotion-shadow bg-white px-[24px] pb-[24px] pt-[28px] md:px-[32px] md:pt-[40px] md:pb-[32px] rounded-[16px] flex flex-col">
              <div className="flex items-center gap-[12px] mb-[16px]">
                <h4 className="text-base font-bold text-graySlate">
                  ATTENDEES
                </h4>
              </div>
              <div className="flex flex-col gap-[12px]">
                {meeting.contacts.map((contact: any, idx: number) => {
                  if (meeting.is_managed) {
                    return (
                      <div className="flex flex-col gap-[12px]">
                        <div
                          key={idx}
                          className="flex items-center"
                        >
                          <ContactAvatar
                            contact={contact}
                            width={32}
                            height={32}
                            bold={false}
                            fontSize={"text-base"}
                          />
                          <p className="ml-[16px] mr-[12px] text-base font-bold">
                            {contact.first_name} {contact.last_name}
                          </p>
                          <div className="text-base font-bold">
                            {/* TODO Hidden for 2.0 launch. Add back in later. */}
                            {/* <AttendeeStatusTag status="Accepted" /> */}
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={idx}
                        className="flex items-center"
                      >
                        {/* TODO Add contact avatar */}
                        {/* <img
                                    src={avatar}
                                    alt="avatar"
                                    className="w-[32px] mr-[16px]"
                                /> */}
                        <p className="mr-[12px] text-base font-bold">
                          {contact.email}
                        </p>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default MeetingPreviewDetails;
